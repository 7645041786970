import { useContext, createContext } from 'react';
import {
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
  HttpLink
} from '@apollo/client';
import { useSelector } from '../store';

const authContext = createContext({});

export function GraphQLAuthProvider({ children }) {
  const auth = useProvideAuth();

  return (
    <authContext.Provider value={auth}>
      <ApolloProvider client={auth.createApolloClient()}>
        {children}
      </ApolloProvider>
    </authContext.Provider>
  );
}

export const useAuth = () => {
  return useContext(authContext);
};
export const apolloClient = (headers) => {
  const link = new HttpLink({
    uri: process.env.REACT_APP_GRAPHQL_URI,
    headers: headers
  });
  return new ApolloClient({
    link,
    cache: new InMemoryCache()
  });
};

function useProvideAuth() {
  const user = useSelector((state) => state.user.user);
  const company = useSelector((state) => state.company.company);
  // const [authToken, setAuthToken] = useState(null);

  const getAuthHeaders = () => {
    if (!user || !user.token || !company) return null;

    return {
      authorization: `Bearer ${user.token}`,
      database: company.domain
    };
  };

  function createApolloClient() {
    return apolloClient(getAuthHeaders());
  }

  const isSignedIn = () => {
    if (user && user.token) {
      return true;
    } else {
      return false;
    }
  };

  return {
    createApolloClient,
    isSignedIn
  };
}
