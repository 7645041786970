import { useState } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Link, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

const CreateMenu = () => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Typography onClick={handleClick} variant="h5" color="inherit">
        Tạo mới
      </Typography>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <Link
          variant="subtitle2"
          color="textPrimary"
          component={RouterLink}
          underline="none"
          to="/management/customers/create"
        >
          <MenuItem onClick={handleClose}>Khách hàng</MenuItem>
        </Link>
        <Link
          variant="subtitle2"
          color="textPrimary"
          component={RouterLink}
          underline="none"
          to="/management/quotes/create"
        >
          <MenuItem onClick={handleClose}>Báo giá</MenuItem>
        </Link>
        <Link
          variant="subtitle2"
          color="textPrimary"
          component={RouterLink}
          underline="none"
          to="/management/orders/create"
        >
          <MenuItem onClick={handleClose}>Đơn hàng</MenuItem>
        </Link>
        <Link
          variant="subtitle2"
          color="textPrimary"
          component={RouterLink}
          underline="none"
          to="/management/products/create"
        >
          <MenuItem onClick={handleClose}>Sản phẩm</MenuItem>
        </Link>
        <Link
          variant="subtitle2"
          color="textPrimary"
          component={RouterLink}
          underline="none"
          to="/management/inventory-adjustment/create"
        >
          <MenuItem onClick={handleClose}>Điều chỉnh tồn kho</MenuItem>
        </Link>
      </Menu>
    </>
  );
};

export default CreateMenu;
