import { createSlice } from '@reduxjs/toolkit'
import { transformDate, transformToFirebaseTimestamp } from 'src/utils/formater'
import queries, { db, categoryRef, serverTime, storageRef } from 'src/rolesbased-rules/rolesBasedQuery'
import _ from 'lodash'
import store from '../index'
import { apolloClient } from '../../contexts/graphqlContext'
import { gql } from '@apollo/client'
import { getComparator } from '../../utils/queryUtils'
import { fetchAllCategories, fetchCateSubObjQL } from '../../rolesbased-rules/GQL/queries'

const initialState = {
  totalCategory: {
    CEO: -1,
    ADMIN: -1,
    WAREHOUSE_MANAGER: -1,
    SALES_REP: -1,
    SALES_MANAGER: -1,
    DOCTOR_MANAGER: -1,
    DOCTOR: -1
  },
  categories: {},
  localCategory: [],
  lastDoc: [],
  localCategoryQL: {
  },
}

export const slice = createSlice({
  name: 'categories',
  initialState,
  reducers: {
    fetchCategory(state, action) {
      state.categories = {
        ...state.categories,
        [action.payload.id]: action.payload
      }
    },
    fetchCategorySubObj(state, action) {
      state.categories = {
        ...state.categories,
        [action.payload.id]: {
          ...state.categories[action.payload.id],
          products: action.payload.products,
          subCategories: action.payload.subCategories,
          parentCategory: action.payload.parentCategory
        }
      }
    },
    fetchCategories(state, action) {
      state.categories = {
        ...state.categories,
        ..._.mapKeys(action.payload, 'id')
      }
    },
    createCategory(state, action) {
      state.categories = {
        ...state.categories,
        [action.payload.id]: action.payload
      }
    },
    editCategory(state, action) {
      state.categories = {
        ...state.categories,
        [action.payload.id]: action.payload
      }
    },
    addCategoryProducts(state, action) {
      state.categories = {
        ...state.categories,
        [action.payload.id]: {
          ...state.categories[action.payload.id],
          products: [
            ...state.categories[action.payload.id].products
              .filter(product => product.id !== action.payload.product_id),
            { id: action.payload.product_id }
          ]
        }
      }
    },
    addCateProductMulti(state, action) {
      state.categories = {
        ...state.categories,
        [action.payload.id]: {
          ...state.categories[action.payload.id],
          products: [
            ...state.categories[action.payload.id].products ?
              state.categories[action.payload.id].products
                .filter(product =>
                  !action.payload.products.map(pro => pro.id).includes(product.id)
                )
              : [],
            ...action.payload.products
          ]
        }
      }
    },
    removeCategoryProducts(state, action) {
      state.categories = {
        ...state.categories,
        [action.payload.id]: {
          ...state.categories[action.payload.id],
          products: state.categories[action.payload.id].products ?
            state.categories[action.payload.id].products
              .filter(x => !action.payload.product_ids.includes(x.id))
            : []
        }
      }
    },
    removeParentCategory(state, action) {
      const next = { ...state.categories[action.payload.id] }
      delete next['parentCategory']

      state.categories = {
        ...state.categories,
        [action.payload.id]: {
          ...next
        }
      }
    },
    deleteProduct(state, action) {
      state.products = _.omit(state.products, action.payload)
    },
    updateLocalCategory(state, action) {
      const { localCategoryQL, role } = action.payload
      state.localCategoryQL = {
        ...state.localCategoryQL,
        [role]: localCategoryQL
      }
    },
    updateTotalCategory(state, action) {
      const { totalCategory, role } = action.payload
      state.totalCategory = {
        ...state.totalCategory,
        [role]: totalCategory
      }
    }
  }
})

export const reducer = slice.reducer

export const fetchCategory = (id) => async (dispatch, getState) => {
  const { currentAccessLevel } = getState().user
  if (currentAccessLevel) {
    const category = await categoryRef.doc(id).get()
    const response = await categoryRef.doc(id).collection('images').get()

    const category_data = category.data()
    if (category_data && category_data.products) {
      category_data.products = category_data.products.map(p => { return { id: p } })
    }
    category_data.images = []

    response.docs.map(snapshot => {
      const imgDoc = snapshot.data()
      data.images.push({
        id: imgDoc.id,
        name: imgDoc.name,
        storageUrl: imgDoc.storageUrl,
        isNew: false
      })
    })

    dispatch(slice.actions.fetchCategory(transformDate(category_data)))
  }
}

export const fetchCategorySubObj = (id) => async (dispatch, getState) => {
  const user = getState().user.user
  const company = getState().company.company
  const category = await fetchCateSubObjQL(user.token, company.domain, id)

  dispatch(slice.actions.fetchCategorySubObj(category))
}

export const fetchCategories = () => async (dispatch, getState) => {
  const user = getState().user.user
  const company = getState().company.company

  const categories = await fetchAllCategories(user.token, company.domain)
  dispatch(
    slice.actions.fetchCategories(categories)
  )
}

export const addCateProductMulti = (products, cate_id) => async (dispatch) => {
  const cat = categoryRef.doc(cate_id)

  await cat.update({
    products: db.FieldValue.arrayUnion(...products.map(pro => pro.id))
  })

  dispatch(slice.actions.addCateProductMulti({ id: cate_id, products }))
}

export const removeCategoryProducts = (product_ids, cate_id) => async (dispatch) => {
  const cat = categoryRef.doc(cate_id)

  await cat.update({
    products: db.FieldValue.arrayRemove(...product_ids)
  })

  dispatch(slice.actions.removeCategoryProducts({ id: cate_id, product_ids }))
}

export const removeSubCategory = (cates, parent_id) => async (dispatch) => {
  const batch = db().batch()

  cates.map(cate => {
    batch.update(categoryRef.doc(cate.id), { parentCategory: db.FieldValue.delete() })
    dispatch(slice.actions.removeParentCategory({ id: cate.id, parent_id }))
  })

  await batch.commit()
}

export const addSubCategory = (cates, parent) => async (dispatch) => {
  const batch = db().batch()

  cates.map(cate => {
    batch.update(categoryRef.doc(cate.id), { parentCategory: cate.id })
    dispatch(slice.actions.fetchCategorySubObj({ parentCategory: parent, id: cate.id }))
  })

  dispatch(slice.actions.fetchCategorySubObj({ subCategories: cates, id: parent.id }))

  await batch.commit()
}

const pushPopImage = async (image, mainDocRef) => {
  let imageToStore = {}

  // push or pop delete image from storage
  const storageImgRef = storageRef(
    `public/company/${window.location.hostname}/categories/${id}/${image.name}`
  )
  if (!image.isNew && image.isDeleted) {
    await storageImgRef.delete()
  }
  if (!image.isNew && !image.isDeleted)
    imageToStore = {
      image: image.image,
      storageUrl: image.storageUrl,
      name: image.name
    }
  if (image.isNew && !image.isDeleted) {
    const response = await storageImgRef.put(image.file)

    // push image to Firestore
    const imgDocRef = mainDocRef.collection('images').doc()
    await imgDocRef.set({
      id: imgDocRef.id,
      dateCreated: category_data.dateCreated,
      productId: id,
      storageUrl: response.metadata.fullPath,
      name: response.metadata.fullPath.substr(
        response.metadata.fullPath.indexOf(`${id}/`) + 1 + id.length
      )
    })

    imageToStore = {
      id: imgDocRef.id,
      storageUrl: response.metadata.fullPath,
      name: response.metadata.fullPath.substr(
        response.metadata.fullPath.indexOf(`${id}/`) + 1 + id.length
      )
    }
  }

  return imageToStore
}

export const createCategory = (data) => async (dispatch, getState) => {
  const catRef = categoryRef.doc()
  const id = catRef.id
  const timestamp = serverTime()

  const transformData = transformToFirebaseTimestamp({ ...data, id })
  const category_data = {
    ...transformData,
    dateCreated: timestamp,
    dateModified: timestamp,
    stock: 0
  }
  const images = category_data.images

  const imageToSaveLst = await Promise.all(images.map((image) => pushPopImage(image)))
  transformData.images = imageToSaveLst
  delete category_data.images

  await catRef.set(category_data)
  dispatch(slice.actions.createCategory(transformDate(transformData)))
}

export const editCategory = (data, id) => async (dispatch, getState) => {
  const timestamp = serverTime()

  const transformData = transformToFirebaseTimestamp(data)
  const category_data = {
    ...transformData,
    dateModified: timestamp
  }
  const images = category_data.images

  const imageToSaveLst = await Promise.all(images.map((image) => pushPopImage(image)))
  transformData.images = imageToSaveLst
  delete category_data.images

  transformData.images = imageToSaveLst
  transformData.id = id
  await categoryRef.doc(id).update(category_data)

  dispatch(slice.actions.editCategory(transformDate(transformData)))
}

export const deleteProduct = (id) => async (dispatch, getState) => {
  await productRef.doc(id).delete()

  dispatch(slice.actions.deleteProduct(id))
}

////// FOR GRAPH DB //////////
const fetchTotalCategoryFromDB = async (employeeId, accessLevel, searchField) => {
  const user = store.getState().user.user
  const company = store.getState().company.company
  const client = apolloClient({
    authorization: `Bearer ${user.token}`,
    database: company.domain
  })
  const response = await client.query({
    fetchPolicy: 'network-only',
    query: gql`
        query fetchTotalCategoryByRole($employeeId: String, $accessLevel: String, $searchField: String) {
            fetchTotalCategoryByRole(
                employeeId: $employeeId, accessLevel: $accessLevel,  searchField: $searchField
            ){
                status,
                cnt
            }
        }
    `,
    variables: {
      employeeId: employeeId,
      accessLevel,
      searchField
    }
  })
  return response.data.fetchTotalCategoryByRole
}

export const fetchTotalCategoryGraph = (
  employeeId, accessLevel, status, searchField, setLoading
) => async (dispatch, getState) => {
  setLoading(true)
  let data = await fetchTotalCategoryFromDB(employeeId, accessLevel, searchField)

  let totalCategoryByCurrentRole = -1
  if (status === null) {
    totalCategoryByCurrentRole = data.reduce((prev, cur) => prev + cur.cnt, 0)
  } else {
    const totalCategoryObjAr = data.filter(ele => ele.status === status)
    if (totalCategoryObjAr.length > 0) totalCategoryByCurrentRole = totalCategoryObjAr[0].cnt
  }

  dispatch(slice.actions.updateTotalCategory({ totalCategory: totalCategoryByCurrentRole, role: accessLevel }))
  setLoading(false)
  return data
}

const fetchCategoryPagingFromDB = async (
  employeeId, accessLevel, sort, status, searchField, limit, skip, getState
) => {
  let [field, order] = sort.split('|')
  const user = getState().user.user
  const company = getState().company.company
  const client = apolloClient({
    authorization: `Bearer ${user.token}`,
    database: company.domain
  })
  return await client.query({
    fetchPolicy: 'network-only',
    query: gql`
        query fetchCategory(
            $employeeId: String, $accessLevel: String, $propName: String,
            $limit: Int!, $searchField: String, $skip: Int!, $status: String
        ){
            ${order === 'desc' ? 'fetchCategoriesByRoleDesc' : 'fetchCategoriesByRoleAsc'}
            (employeeId: $employeeId, accessLevel: $accessLevel, propName: $propName,
            status: $status, searchField: $searchField, limit: $limit, skip: $skip
            ){
            id
            name
            status
            dateCreated
            products{id}
            subCategories{id}
            parentCategory{id}
        }
        }
    `,
    variables: {
      employeeId: employeeId,
      accessLevel,
      skip: skip,
      limit: limit,
      propName: field,
      quoteStatus: status,
      searchField: searchField
    }
  })
}

export const fetchCategoryGraph = (
  employeeId, accessLevel, sort, status, searchField,
  limit, skip, listField, setLoading, fromSort = false
) => async (dispatch, getState) => {
  setLoading(true)
  let [field, order] = sort.split('|')

  const accessLevel = getState().user.currentAccessLevel

  const response = await fetchCategoryPagingFromDB(
    employeeId, accessLevel, sort, status, searchField, limit, skip, getState)

  let data = order === 'desc' ? response.data.fetchCategoriesByRoleDesc : response.data.fetchCategoriesByRoleAsc

  if (fromSort) {
    const arr = data.slice().sort(getComparator(order, field, listField))
    await dispatch(slice.actions.updateLocalCategory({ localCategoryQL: arr, role: accessLevel }))
  } else {
    let arr = getState().categories.localCategoryQL[accessLevel] ?? []
    arr = arr.slice()
    data.forEach((ele) => {
      let have = arr.find((x) => x.id === ele.id)
      if (!have) {
        arr.push(ele)
      }
    })
    arr.sort(getComparator(order, field, listField))
    await dispatch(slice.actions.updateLocalCategory({ localCategoryQL: arr, role: accessLevel }))
  }

  setLoading(false)
}

export const updateCategoryGraph = (
  employeeId, accessLevel, sort, status, searchField, limit, page, listField
) => async (dispatch, getState) => {
  const [field, order] = sort.split('|')
  const skip = page * limit

  const role = getState().user.currentAccessLevel
  const response = await fetchCategoryPagingFromDB(
    employeeId, accessLevel, sort, status, searchField, limit, skip, getState
  )

  let data = order === 'desc' ? response.data.fetchCategoriesByRoleDesc : response.data.fetchCategoriesByRoleAsc
  let arr = getState().categories.localCategoryQL[role] ?? []
  arr = arr.slice()

  data.forEach((ele) => {
    let idx = arr.findIndex((x) => x.id === ele.id)
    if (idx > -1) {
      arr[idx] = ele
    } else {
      arr.push(ele)
    }
  })

  arr.sort(getComparator(order, field, listField))
  dispatch(slice.actions.updateLocalCategory({ localCategoryQL: arr, role: role }))
}

export default slice
