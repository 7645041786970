import axios from 'axios';

console.log("process.env.API_URI")
console.log(process.env.REACT_APP_BE_URI)

console.log(process.env.REACT_APP_GRAPHQL_URI);

const axiosInstance = axios.create(
  {
    baseURL: process.env.REACT_APP_BE_URI,
    timeout: 60000
  }
);

// axiosInstance.interceptors.response.use(
//   (response) => response,
//   (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
// );

export default axiosInstance;
