import { createSlice } from '@reduxjs/toolkit'
import queries, {
  teamsRef,
  serverTime
} from '../../rolesbased-rules/rolesBasedQuery'
import _ from 'lodash'
import {
  transformDate,
  transformToFirebaseTimestamp
} from '../../utils/formater'
import store from '../index'
import { apolloClient } from '../../contexts/graphqlContext'
import { gql } from '@apollo/client'
import { getComparator } from '../../utils/queryUtils'
import { fetchAllAvailablePromotionGraphQL } from '../../rolesbased-rules/GQL/queries'

const initialState = {
  totalPromotion: {
    CEO: -1,
    ADMIN: -1,
    WAREHOUSE_MANAGER: -1,
    SALES_REP: -1,
    SALES_MANAGER: -1,
    DOCTOR_MANAGER: -1,
    DOCTOR: -1
  },
  promotions: {},
  localPromotionQL: {
    CEO: [],
    ADMIN: [],
    WAREHOUSE_MANAGER: [],
    SALES_REP: [],
    SALES_MANAGER: [],
    DOCTOR_MANAGER: [],
    DOCTOR: []
  },
  availablePromotions: {}
}

const slice = createSlice({
  name: 'promotions',
  initialState,
  reducers: {
    getTeams(state, action) {
      state.promotions = {
        ...state.promotions,
        ..._.mapKeys(action.payload, 'id')
      }
    },
    createPromotionAction(state, action) {
      state.promotions = {
        ...state.promotions,
        [action.payload.id]: action.payload
      }
    },
    deleteTeamAction(state, action) {
      delete state.promotions[action.payload.id]
    },
    updatePromotionAction(state, action) {
      state.promotions = {
        ...state.promotions,
        [action.payload.id]: action.payload
      }
    },
    updateLocalPromotion(state, action) {
      const { localPromotionQL, role } = action.payload;
      state.localPromotionQL = {
        ...state.localPromotionQL,
        [role]: localPromotionQL
      };
    },
    insertLocalPromotion(state, action) {
      const { localPromotionQL, role } = action.payload;
      state.localPromotionQL[role] = [localPromotionQL, ...state.localPromotionQL[role]];
    },
    updateTotalPromotion(state, action) {
      const { totalPromotion, role } = action.payload;
      state.totalPromotion = {
        ...state.totalPromotion,
        [role]: totalPromotion
      };
    },
    updateAvailablePromotions(state, action) {
      const { promotions } = action.payload;
      state.availablePromotions = {
        ...state.availablePromotions,
        ...promotions
      };
    }
  }
})

export const reducer = slice.reducer

export const getTeams = () => async dispatch => {
  const snap = await teamsRef.orderBy('name').get()
  const emp = []
  snap.forEach(doc => {
    emp.push(transformDate(doc.data()))
  })
  dispatch(slice.actions.getTeams(emp))
}

export const createPromotionAction = data => async (dispatch, getState) => {
  const { currentAccessLevel } = getState().user
  const { promotionsCreateRef } = queries[currentAccessLevel]

  const newPromotionRef = promotionsCreateRef().doc()
  const id = newPromotionRef.id
  const timestamp = serverTime()

  const transformData = transformToFirebaseTimestamp(data.promotion)
  const promo_data = {
    id,
    ...transformData,
    dateCreated: timestamp,
    dataModified: timestamp
  }
  await newPromotionRef.set(promo_data)
  const store_data = {
    ...promo_data,
    customers: Object.values(promo_data.customers),
    products: Object.values(promo_data.products),
    dateCreated: Date.now(),
    dataModified: Date.now()
  }
  dispatch(slice.actions.createPromotionAction(store_data))
  dispatch(slice.actions.insertLocalPromotion({ localPromotionQL: store_data, role: currentAccessLevel }))
}

export const deleteTeamAction = team => async (dispatch, getState) => {
  const { currentAccessLevel } = getState().user
  const { teamRef } = queries[currentAccessLevel]
  await teamRef(team.id).delete()
  dispatch(slice.actions.deleteTeamAction(team))
}

export const updatePromotionAction = data => async (dispatch, getState) => {
  const { currentAccessLevel } = getState().user
  const { promotionRef } = queries[currentAccessLevel]
  const transformData = transformToFirebaseTimestamp(data.promotion)
  const promo_data = {
    ...transformData,
    dataModified: serverTime()
  }
  await promotionRef(data.team.id).update(promo_data)
  const store_data = {
    ...promo_data,
    customers: Object.values(promo_data.customers),
    products: Object.values(promo_data.products),
    dataModified: Date.now()
  }
  dispatch(slice.actions.updatePromotionAction(store_data))
  dispatch(slice.actions.insertLocalPromotion({ localPromotionQL: store_data, role: currentAccessLevel }))
}

////// FOR GRAPH DB //////////
const fetchTotalPromotionFromDB = async (employeeId, accessLevel, searchField) => {
  const user = store.getState().user.user;
  const company = store.getState().company.company;
  const client = apolloClient({
    authorization: `Bearer ${user.token}`,
    database: company.domain
  });
  const response = await client.query({
    fetchPolicy: 'network-only',
    query: gql`
        query fetchTotalPromotionByRole($employeeId: String, $accessLevel: String, $searchField: String) {
            fetchTotalPromotionByRole(
                employeeId: $employeeId, accessLevel: $accessLevel,  searchField: $searchField
            ){
                status,
                cnt
            }
        }
    `,
    variables: {
      employeeId: employeeId,
      accessLevel,
      searchField
    }
  });
  return response.data.fetchTotalPromotionByRole;
}

export const fetchTotalPromotionGraph = (
  employeeId, accessLevel, status, searchField, setLoading
) => async (dispatch, getState) => {
  setLoading(true);
  let data = await fetchTotalPromotionFromDB(employeeId, accessLevel, searchField)

  let totalPromotionByCurrentRole = -1
  if (status === null) {
    totalPromotionByCurrentRole = data.reduce((prev, cur) => prev + cur.cnt, 0)
  } else {
    const totalPromotionObjAr = data.filter(ele => ele.status === status)
    if (totalPromotionObjAr.length > 0) totalPromotionByCurrentRole = totalPromotionObjAr[0].cnt
  }

  dispatch(slice.actions.updateTotalPromotion({ totalPromotion: totalPromotionByCurrentRole, role: accessLevel }))
  setLoading(false)
  return data
};

const fetchPromotionFromDB = async (
  employeeId, accessLevel, sort, status, searchField, limit, skip, getState
) => {
  let [field, order] = sort.split('|');
  const user = getState().user.user;
  const company = getState().company.company;
  const client = apolloClient({
    authorization: `Bearer ${user.token}`,
    database: company.domain
  });
  return await client.query({
    fetchPolicy: 'network-only',
    query: gql`
        query fetchPromotion(
            $employeeId: String, $accessLevel: String, $propName: String,
            $limit: Int!, $searchField: String, $skip: Int!, $status: String
        ){
            ${order === 'desc' ? 'fetchPromotionsByRoleDesc' : 'fetchPromotionsByRoleAsc'}
            (employeeId: $employeeId, accessLevel: $accessLevel, propName: $propName,
            status: $status, searchField: $searchField, limit: $limit, skip: $skip)
        {
            id
            promoCode
            status
            dateCreated
            usingTimes
            description
            isLimited
            specifyCustomer
            specifyProduct
            value
            customers{id, name}
            products{id}
            expiredDate
        }
        }
    `,
    variables: {
      employeeId: employeeId,
      accessLevel,
      skip: skip,
      limit: limit,
      propName: field,
      quoteStatus: status,
      searchField: searchField
    }
  });
};

export const fetchPromotionGraph = (
  employeeId, accessLevel, sort, status, searchField,
  limit, skip, listField, setLoading, fromSort = false
) => async (dispatch, getState) => {
  setLoading(true);
  let [field, order] = sort.split('|');

  const accessLevel = getState().user.currentAccessLevel;

  const response = await fetchPromotionFromDB(
    employeeId, accessLevel, sort, status, searchField, limit, skip, getState);

  let data = order === 'desc' ? response.data.fetchPromotionsByRoleDesc : response.data.fetchPromotionsByRoleAsc;

  if (fromSort) {
    const arr = data.slice().sort(getComparator(order, field, listField));
    await dispatch(slice.actions.updateLocalPromotion({ localPromotionQL: arr, role: accessLevel }));
  } else {
    let arr = getState().promotions.localPromotionQL[accessLevel] ?? []
    arr = arr.slice();

    console.log("<<<<<< OBJ COUNT")
    console.log(arr.length)

    data.forEach((ele) => {
      let have = arr.find((x) => x.id === ele.id);
      if (!have) {
        arr.push(ele);
      }
    });
    arr.sort(getComparator(order, field, listField));
    await dispatch(slice.actions.updateLocalPromotion({ localPromotionQL: arr, role: accessLevel }));
  }

  setLoading(false);
};

export const insertLocalPromotion = (obj) => (dispatch, getState) => {
  const role = getState().user.currentAccessLevel;
  dispatch(slice.actions.insertLocalPromotion({ localPromotionQL: obj, role }))
}

export const updatePromotionGraph = (
  employeeId, accessLevel, sort, status, searchField, limit, page, listField
) => async (dispatch, getState) => {
  const [field, order] = sort.split('|');
  const skip = page * limit;

  const role = getState().user.currentAccessLevel;
  const response = await fetchPromotionFromDB(
    employeeId, accessLevel, sort, status, searchField, limit, skip, getState
  );

  let data = order === 'desc' ? response.data.fetchPromotionsByRoleDesc : response.data.fetchPromotionsByRoleAsc;
  let arr = getState().promotions.localPromotionQL[role] ?? []
  arr = arr.slice();

  console.log("<<<<<< OBJ COUNT")
  console.log(arr.length)

  data.forEach((ele) => {
    let idx = arr.findIndex((x) => x.id === ele.id);
    if (idx > -1) {
      arr[idx] = ele;
    } else {
      arr.push(ele);
    }
  });

  arr.sort(getComparator(order, field, listField));
  dispatch(slice.actions.updateLocalPromotion({ localPromotionQL: arr, role: role }));
};

export const fetchAllAvailablePromotion = (searchField = null) => async (dispatch, getState) => {
  const token = getState().user.user.token;
  const domain = getState().company.company.domain;

  const data = await fetchAllAvailablePromotionGraphQL(token, domain, searchField);

  const availablePromotions = {};

  for (let promotion of data) {
    availablePromotions[promotion.id] = { ...promotion };
  }

  dispatch(slice.actions.updateAvailablePromotions({ promotions: availablePromotions }));
};

export default slice
