import { createSlice } from '@reduxjs/toolkit';
import queries, {
  serverTime,
  storageRef,
  companyRef
} from '../../rolesbased-rules/rolesBasedQuery';
import {
  transformDate,
  transformToFirebaseTimestamp
} from '../../utils/formater';

const initialState = {
  company: null
};

const slice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    setCompany(state, action) {
      const { company } = action.payload;

      state.company = company;
    },
    editCompany(state, action) {
      state.company = { ...state.company, ...action.payload };
    },
    editCompanyImage(state, action) {
      state.company = { ...state.company, ...action.payload };
    }
  }
});

export const reducer = slice.reducer;

export const setCompany = company => dispatch => {
  dispatch(
    slice.actions.setCompany({
      company: transformDate(company)
    })
  );
};

export const editCompany = (data, callback) => async (dispatch, getState) => {
  const timestamp = serverTime();
  const transformData = transformToFirebaseTimestamp(data);

  const company_data = {
    ...transformData,
    dateModified: timestamp
  };

  await companyRef.update(company_data);
  dispatch(slice.actions.editCompany(transformDate(transformData)));
  callback();
  // history.push(`/customers/${id}/`);
};

export const editCompanyImage = (data, callback) => async (
  dispatch,
  getState
) => {
  const timestamp = serverTime();
  const transformData = transformToFirebaseTimestamp(data);
  const company_data = {
    ...transformData,
    dateModified: timestamp
  };

  const companyImgRef = companyRef.collection('images');

  const images = company_data.images;
  const promisesImgToDel = [];
  const promisesImgToAdd = [];

  const imageToSaveLst = [];
  const dataToHold = [];

  for (const image of images) {
    const storageUrl = `public/company/${window.location.hostname}/images/${image.id}`;
    const storageImgRef = storageRef(storageUrl);

    if (image.isDeleted && !image.isSubmitDeleted) {
      promisesImgToDel.push(storageImgRef.delete());
      companyImgRef.doc(image.id).delete();
    }
    if (!image.isNew && !image.isDeleted)
      imageToSaveLst.push({
        id: image.id,
        image: image.image,
        storageUrl: storageUrl,
        name: image.name
      });
    if (image.isNew && !image.isDeleted) {
      promisesImgToAdd.push(storageImgRef.put(image.file));

      dataToHold.push({
        id: image.id,
        image: image.image,
        storageUrl: storageUrl,
        name: image.name
      });
    }
  }
  await Promise.all(promisesImgToDel).catch(e => console.log('error' + e));
  const responses = await Promise.all(promisesImgToAdd);

  const currentDate = new Date();

  responses.forEach((response, index) => {
    currentDate.setSeconds(currentDate.getSeconds() + 5);
    // console.log('<<<<<<<<<< Image Date');
    // console.log(currentDate);
    companyImgRef.doc(dataToHold[index].id).set({
      dateCreated: dataToHold[index].dateCreated
        ? dataToHold[index].dateCreated
        : currentDate,
      id: dataToHold[index].id,
      storageUrl: response.metadata.fullPath,
      name: dataToHold[index].name
    });
    imageToSaveLst.push({
      dateCreated: dataToHold[index].dateCreated
        ? dataToHold[index].dateCreated
        : currentDate.getTime(),
      id: dataToHold[index].id,
      storageUrl: response.metadata.fullPath,
      name: dataToHold[index].name
    });
  });

  transformData.images = imageToSaveLst;
  company_data.images = imageToSaveLst;

  dispatch(slice.actions.editCompanyImage(transformDate(transformData)));
  callback();
};

export default slice;
