import { createSlice } from '@reduxjs/toolkit';
import { paymentMethodsRef } from '../../rolesbased-rules/rolesBasedQuery';
import _ from 'lodash';
import { transformDate } from '../../utils/formater';

const initialState = {
  paymentMethods: {}
};

const slice = createSlice({
  name: 'paymentMethods',
  initialState,
  reducers: {
    getPaymentMethods(state, action) {
      state.paymentMethods = {
        ...state.paymentMethods,
        ..._.mapKeys(action.payload, 'id')
      };
    },
    createPaymentMethod(state, action) {
      state.paymentMethods = {
        ...state.paymentMethods,
        [action.payload.id]: action.payload
      };
    },
    deletePaymentMethod(state, action) {
      state.paymentMethods = {
        ...state.paymentMethods,
        [action.payload.id]: action.payload
      };
    },
    updatePaymentMethod(state, action) {
      const { event } = action.payload;

      state.events = _.map(state.events, (_event) => {
        if (_event.id === event.id) {
          return event;
        }

        return _event;
      });
    }
  }
});

export const reducer = slice.reducer;

export const getPaymentMethods = () => async (dispatch) => {
  const snap = await paymentMethodsRef.get();
  const pays = [];
  snap.forEach((doc) => {
    pays.push(transformDate(doc.data()));
  });
  console.log(pays);
  dispatch(slice.actions.getPaymentMethods(pays));
};

export const createPaymentMethod = (data, callback) => async (dispatch) => {
  const paymentRef = paymentMethodsRef.doc();
  const id = paymentRef.id;
  const payment_data = { ...data, id };
  await paymentRef.set(payment_data);
  callback();
  dispatch(slice.actions.createPaymentMethod(payment_data));
};

export const updatePaymentMethod = (eventId, update) => async (dispatch) => {
  const response = {};

  dispatch(slice.actions.updatePaymentMethod(response.data));
};

export const deletePaymentMethod = (paymentId) => async (dispatch) => {
  const response = {};

  dispatch(slice.actions.deletePaymentMethod(response.data));
};

export default slice;
