import { createSlice } from '@reduxjs/toolkit';
import { customersCacheRef, rolesRef } from '../../rolesbased-rules/rolesBasedQuery';
import { transformDate } from '../../utils/formater';
import {slice as rolesSlice } from './role'
import _ from 'lodash';

const initialState = {
  user: null,
  roles: [],
  currentRole: null,
  currentAccessLevel: null
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser(state, action) {
      const { user } = action.payload;

      state.user = user;
    },
    setToken(state, action) {
      const { token } = action.payload;

      state.user = { ...state.user, token };
    },
    setRoles(state, action) {
      const { currentRole, roles, currentAccessLevel } = action.payload;

      state.currentRole = currentRole;
      state.roles = roles;
      state.currentAccessLevel = currentAccessLevel;
    },
    setListRefetchFlag(state, action) {
      const { refetchFlag } = action.payload;

      state.refetchFlag = refetchFlag;
    },
    setCurrentRole(state, action) {
      const { currentRole, currentAccessLevel } = action.payload;

      state.currentRole = currentRole;
      state.currentAccessLevel = currentAccessLevel;
    }
  }
});

export const reducer = slice.reducer;

export const setUser = (user) => (dispatch) => {
  dispatch(
    slice.actions.setUser({
      user
    })
  );
};

export const setToken = (firebaseUser) => (dispatch, getState) => {
  return setInterval(async () => {
    const token = await firebaseUser.getIdToken(true);
    dispatch(slice.actions.setToken({ token }));
  }, 15 * 60 * 1000);
};

export const setListRefetchFlag = (refetchFlag) => (dispatch, getState) => {
  return setTimeout( () => {
    dispatch(
      slice.actions.setListRefetchFlag({ refetchFlag })
    );
  }, 5 * 1000);
};


export const userRoles = {
  CEO: { displayRole: 'CEO', arrangement: 1 },
  ADMIN: { displayRole: 'Admin', arrangement: 2 },
  SALES_MANAGER: { displayRole: 'Quản lý bán hàng', arrangement: 3 },
  BRANCH_MANAGER: { displayRole: 'Quản lý chi nhánh', arrangement: 4 },
  DOCTOR_MANAGER: { displayRole: 'Bác sĩ quản lý', arrangement: 5 },
  SALES_REP: { displayRole: 'Nhân viên bán hàng', arrangement: 6 },
  DOCTOR: { displayRole: 'Bác sĩ', arrangement: 7 },
  WAREHOUSE_MANAGER: { displayRole: 'Quản lý kho', arrangement: 8 }
};

export const setRoles = (roles) => async (dispatch) => {
  const snap = await rolesRef.get();
  const rolesList = [];
  snap.forEach((doc) => {
    rolesList.push(transformDate(doc.data()));
  });
  const rolesMap = _.mapKeys(rolesList, 'id');
  console.log("<<<<<<<<<<< ROLES MAP")
  console.log(rolesMap)
  // const rolesCopy = [...roles].sort(
  //   (a, b) => rolesMap[a].dateCreated - rolesMap[b].dateCreated
  // );

  let rolesCopy = [...roles]
  rolesCopy = rolesCopy.filter(value => value in rolesMap)

  dispatch(rolesSlice.actions.fetchRoles(rolesList));

  dispatch(
    slice.actions.setRoles({
      currentAccessLevel: rolesMap[rolesCopy[0]]['accessLevel'],
      currentRole: rolesCopy[0],
      roles: rolesCopy
    })
  );
};
export const setCurrentRole = (role) => (dispatch, getState) => {
  const { roles } = getState().roles;
  dispatch(
    slice.actions.setCurrentRole({
      currentAccessLevel: roles[role]['accessLevel'],
      currentRole: role
    })
  );
};

export default slice;
