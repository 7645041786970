/* eslint-disable no-use-before-define */
import { useEffect, useRef, useState } from 'react';
import { useLocation, matchPath, useHistory } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  Link,
  List,
  ListSubheader,
  Typography,
  MenuItem,
  Menu,
  Button,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useSelector, useDispatch } from 'src/store';
import { setCurrentRole, userRoles } from '../../../store/slices/user';
import {
  Archive as ArchiveIcon,
  Calendar as CalendarIcon,
  File as FileIcon,
  FileText as ReceiptIcon,
  Gift as GiftIcon,
  Lock as LockIcon,
  Package as PackageIcon,
  PieChart as PieChartIcon,
  Settings as SettingIcon,
  ShoppingBag as ProductIcon,
  ShoppingCart as ShoppingCartIcon,
  Truck as TruckIcon,
  TrendingUp as AdjustIcon,
  Users as UsersIcon,
  FileText as FileTextIcon
} from 'react-feather';
import Logo from 'src/components/Logo';
import useAuth from 'src/hooks/useAuth';
import NavItem from './NavItem';
import CanAccess from '../../../rolesbased-rules/CanAcess';

const sections = [
  {
    subheader: 'Trang chủ',
    items: [
      {
        title: 'Bảng điều khiển',
        icon: PieChartIcon,
        href: '/reports/dashboard'
      }
    ]
  },
  {
    subheader: 'Quản lý',
    items: [
      {
        title: 'Khách hàng',
        icon: UsersIcon,
        href: '/management/customers'
      },
      {
        title: 'Đặt hẹn',
        icon: CalendarIcon,
        href: '/management/calendar'
      },
      {
        title: 'Sản phẩm',
        icon: ProductIcon,
        href: '/management/products',
        items: [
          {
            title: 'Sản phẩm & dịch vụ',
            href: '/management/products'
          },
          {
            title: 'Danh mục sản phẩm',
            href: '/management/categories'
          }
        ]
      },
      {
        title: 'Báo giá',
        icon: FileIcon,
        href: '/management/quotes'
      },
      {
        title: 'Đơn hàng',
        icon: ReceiptIcon,
        href: '/management/orders'
      },
      {
        title: 'Khuyến mãi',
        icon: GiftIcon,
        href: '/management/promotions'
      }
    ]
  },
  {
    subheader: 'Quản lý trang web',
    items: [
      {
        title: 'Bài viết',
        icon: FileTextIcon,
        href: '/management/blogs'
      },
      {
        title: 'Trang web',
        icon: ArchiveIcon,
        href: '/management/webpages'
      }
    ]
  },
  {
    subheader: 'Quản lý tồn kho',
    items: [
      {
        title: 'Kho',
        icon: ArchiveIcon,
        href: '/management/warehouses'
      },
      {
        title: 'Nhà cung cấp',
        icon: PackageIcon,
        href: '/management/suppliers'
      },
      {
        title: 'Phiếu nhập',
        icon: ShoppingCartIcon,
        href: '/management/receive-notes'
      },
      {
        title: 'Phiếu xuất',
        icon: TruckIcon,
        href: '/management/delivery-notes'
      },
      {
        title: 'Điểu chỉnh tồn kho',
        icon: AdjustIcon,
        href: '/management/inventory-adjustment'
      }
    ]
  },
  // {
  // 	subheader: 'Công cụ',
  // 	items: [
  // 		{
  // 			title: 'Lịch',
  // 			href: '/calendar',
  // 			icon: CalendarIcon,
  // 			info: () => (
  // 				<Chip
  // 					color="secondary"
  // 					size="small"
  // 					label="Updated"
  // 				/>
  // 			)
  // 		}
  // 	]
  // },
  {
    subheader: 'Cài đặt',
    items: [
      {
        title: 'Cài đặt công ty',
        href: '/settings/company',
        icon: SettingIcon,
        items: [
          {
            title: 'Thông tin công ty',
            href: '/settings/company'
          },
          {
            title: 'Nhân viên',
            href: '/settings/employees'
          },
          {
            title: 'Chức vụ',
            href: '/settings/roles'
          }
        ]
      },
      {
        title: 'Cài đặt cá nhân',
        href: '/settings/user',
        icon: LockIcon
      }
    ]
  }
];

function renderNavItems({ items, pathname, depth = 0, currentRole, companyRoles }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) =>
          reduceChildRoutes({ acc, item, pathname, depth, currentRole, companyRoles }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth, currentRole, companyRoles }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    acc.push(
      <CanAccess
        role={currentRole}
        companyRoles={companyRoles}
        perform={item.href}
        key={key}
        yes={() => (
          <NavItem
            depth={depth}
            icon={item.icon}
            info={item.info}
            key={key}
            open={Boolean(open)}
            title={item.title}
          >
            {renderNavItems({
              depth: depth + 1,
              pathname,
              items: item.items,
              currentRole,
              companyRoles
            })}
          </NavItem>
        )}
      />
    );
  } else {
    acc.push(
      <CanAccess
        role={currentRole}
        companyRoles={companyRoles}
        perform={item.href}
        key={key}
        yes={() => (
          <NavItem
            depth={depth}
            href={item.href}
            icon={item.icon}
            info={item.info}
            key={key}
            title={item.title}
          />
        )}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  }
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const history = useHistory();
  const classes = useStyles();
  const location = useLocation();
  const { user } = useAuth();
  const [isMenuOpen, setMenuOpen] = useState(false);
  const actionRef = useRef(null);
  const dispatch = useDispatch();
  const { roles, currentRole } = useSelector((state) => state.user);
  const companyRoles = useSelector((state) => state.roles.roles);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box p={2} display="flex" justifyContent="center">
            <RouterLink to="/">
              <Logo />
            </RouterLink>
          </Box>
        </Hidden>
        <Box p={2}>
          <Box display="flex" justifyContent="center">
            <RouterLink to="/account">
              <Avatar alt="User" className={classes.avatar} src={user.avatar} />
            </RouterLink>
          </Box>
          <Box mt={2} textAlign="center">
            <Link
              component={RouterLink}
              to="/account"
              variant="h5"
              color="textPrimary"
              underline="none"
            >
              {user.name}
            </Link>
            <Typography variant="body2" color="textSecondary">
              Xem với vai trò
              <Button ref={actionRef} onClick={() => setMenuOpen(true)}>
                {/*{currentRole ? userRoles[currentRole].displayRole : ''}*/}
                {currentRole ? companyRoles[currentRole].displayName : ''}
              </Button>
              {roles && (
                <Menu
                  anchorEl={actionRef.current}
                  onClose={() => setMenuOpen(false)}
                  open={isMenuOpen}
                  getcontentanchorel={null}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                  }}
                >
                  {roles.map((_role) => (
                    <MenuItem
                      key={_role}
                      onClick={() => {
                        dispatch(setCurrentRole(_role));
                        setMenuOpen(false);
                        history.push('/');
                      }}
                    >
                      {companyRoles[_role].displayName}
                    </MenuItem>
                  ))}
                </Menu>
              )}
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box p={2}>
          {sections.map((section) => (
            <List
              key={section.subheader}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {section.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: section.items,
                pathname: location.pathname,
                currentRole,
                companyRoles
              })}
            </List>
          ))}
        </Box>
        {/* <Divider/>
				<Box p={2}>
					<Box
						p={2}
						borderRadius="borderRadius"
						bgcolor="background.dark"
					>
						<Typography
							variant="h6"
							color="textPrimary"
						>
							Need Help?
						</Typography>
						<Link
							variant="subtitle1"
							color="secondary"
							component={RouterLink}
							to="/docs"
						>
							Check our docs
						</Link>
					</Box>
				</Box> */}
      </PerfectScrollbar>
    </Box>
  );

  return <>
    <Hidden lgUp>
      <Drawer
        anchor="left"
        classes={{ paper: classes.mobileDrawer }}
        onClose={onMobileClose}
        open={openMobile}
        variant="temporary"
      >
        {content}
      </Drawer>
    </Hidden>
    <Hidden lgDown>
      <Drawer
        anchor="left"
        classes={{ paper: classes.desktopDrawer }}
        open
        variant="persistent"
      >
        {content}
      </Drawer>
    </Hidden>
  </>;
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;