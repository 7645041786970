const check = (rules, role, action, data) => {
  if (rules) {
    const roleDetail = rules[role];
    if (!roleDetail) {
      // role is not present in the rules
      return false;
    }

    const staticPermissions = roleDetail.permissions;

    if (staticPermissions && staticPermissions.includes(action)) {
      // static rule not provided for action
      return true;
    }
  }

  // const dynamicPermissions = permissions.dynamic;
  //
  // if (dynamicPermissions) {
  //   const permissionCondition = dynamicPermissions[action];
  //   if (!permissionCondition) {
  //     // dynamic rule not provided for action
  //     return false;
  //   }
  //
  //   return permissionCondition(data);
  // }
  return false;
};

const CanAccess = (props) =>
  check(props.companyRoles, props.role, props.perform, props.data)
    ? props.yes()
    : props.no();

CanAccess.defaultProps = {
  yes: () => null,
  no: () => null
};

export default CanAccess;
