import { createSlice } from '@reduxjs/toolkit';
import { defaultTextRef } from '../../rolesbased-rules/rolesBasedQuery';

const initialState = {
  defaultText: { productDescription: '', salesOrderNote: '' }
};

const slice = createSlice({
  name: 'defaultText',
  initialState,
  reducers: {
    getDefaultText(state, action) {
      state.defaultText = action.payload;
    },
    setDefaultText(state, action) {
      state.defaultText = { ...state.defaultText, ...action.payload };
    }
  }
});

export const reducer = slice.reducer;

export const getDefaultText = () => async (dispatch) => {
  const snap = await defaultTextRef.get();
  const texts = snap.data() || { productDescription: '', salesOrderNote: '' };
  dispatch(slice.actions.getDefaultText(texts));
};

export const setDefaultText = (data, callback) => async (dispatch) => {
  await defaultTextRef.set(data, { merge: true });
  callback();
  dispatch(slice.actions.setDefaultText(data));
};

export default slice;
