import { gql } from '@apollo/client';
import { apolloClient } from '../../contexts/graphqlContext';
import store from '../../store';

export const fetchCustServices = async id => {
  const user = store.getState().user.user;
  const company = store.getState().company.company;
  const client = apolloClient({
    authorization: `Bearer ${user.token}`,
    database: company.domain
  });
  const response = await client.query({
    fetchPolicy: 'network-only',
    query: gql`
      query fetchCustomerSerives($customerId: String) {
        customers(where: { AND: [{ id: $customerId }] }) {
          id
          name
          dateCreated
          boughtServices {
            id
            name
            quantity
            salesDate
          }
          bookedServices {
            id
            name
            quantity
            bookedDate
          }
        }
      }
    `,
    variables: {
      customerId: id
    }
  });

  console.log('<<<<<< CUSTOMERS');
  console.log(response.data.customers);

  const customer = response.data.customers[0];
  const boughtServices = customer.boughtServices;
  const bookedServices = customer.bookedServices;
  if (customer) {
    const services = {};
    for (const service of boughtServices) {
      if (service.id in services) {
        services[service.id].boughtQty += service.quantity;
      } else {
        services[service.id] = {
          id: service.id,
          name: service.name,
          boughtQty: service.quantity,
          bookedQty: 0
        };
      }
    }

    for (const service of bookedServices) {
      if (service.id in services) {
        services[service.id].bookedQty += service.quantity;
      } else {
        services[service.id] = {
          id: service.id,
          name: service.name,
          bookedQty: service.quantity,
          boughtQty: 0
        };
      }
    }

    return { ...customer, services };
  }
  return null;
};

export const fetchBookings = async (
  startTimeRange = 0,
  endTimeRange = 9999999999999
) => {
  const user = store.getState().user.user;
  const accessLevel = store.getState().user.currentAccessLevel;
  const company = store.getState().company.company;
  const client = apolloClient({
    authorization: `Bearer ${user.token}`,
    database: company.domain
  });
  const response = await client.query({
    fetchPolicy: 'network-only',
    query: gql`
      query fetchBookingsByRole(
        $employeeId: String
        $accessLevel: String
        $startTimeRange: Float
        $endTimeRange: Float
      ) {
        fetchBookingsByRole(
          employeeId: $employeeId
          accessLevel: $accessLevel
          startTimeRange: $startTimeRange
          endTimeRange: $endTimeRange
        ) {
          id
          title
          note
          status
          dateCreated
          type
          start
          end
          visitDate
          relatedOrder {
            id
            formNumber
          }
          eServicesConnection {
            edges {
              duration
              start
              employeeId
              uikey
              node {
                name
                id
              }
            }
          }
          customer {
            id
            name
          }
        }
      }
    `,
    variables: {
      employeeId: user.uid,
      accessLevel,
      startTimeRange,
      endTimeRange
    }
  });

  console.log('<<<< GQL DATA');
  console.log(response);
  return response.data.fetchBookingsByRole.map(booking => {
    const services = booking.eServicesConnection.edges.map(edge => {
      const newEdge = { ...edge, ...edge.node };
      delete newEdge.node;
      return newEdge;
    });

    return { ...booking, services };
  });
};

export const fetchAllCategories = async (token, db) => {
  const client = apolloClient({
    authorization: `Bearer ${token}`,
    database: db
  });
  const response = await client.query({
    fetchPolicy: 'network-only',
    query: gql`
      query {
        categories(where: { status_NOT_IN: ["DELETED"] }) {
          id
          name
          status
          dateCreated
          type
          products {
            id
            name
          }
          subCategories {
            id
            name
          }
          parentCategory {
            id
            name
          }
        }
      }
    `
  });

  return response.data.categories;
};

export const fetchAllServices = async (token, db) => {
  const client = apolloClient({
    authorization: `Bearer ${token}`,
    database: db
  });
  const response = await client.query({
    fetchPolicy: 'network-only',
    query: gql`
      query {
        products(
          where: { status_NOT_IN: ["DELETED"], productType_IN: ["SERVICE"] }
        ) {
          id
          name
          status
          dateCreated
          productType
          price
        }
      }
    `
  });

  return response.data.products;
};

export const fetchCateSubObjQL = async (token, db, catId) => {
  const client = apolloClient({
    authorization: `Bearer ${token}`,
    database: db
  });
  const response = await client.query({
    fetchPolicy: 'network-only',
    query: gql`
      query fetchCatById($catId: String) {
        categories(
          where: { AND: [{ id: $catId }, { status_NOT_IN: ["DELETED"] }] }
        ) {
          id
          name
          status
          dateCreated
          products {
            id
            name
          }
          subCategories {
            id
            name
          }
          parentCategory {
            id
            name
          }
        }
      }
    `,
    variables: {
      catId
    }
  });

  return response.data.categories[0];
};

export const fetchEmployeeSubObjQL = async (token, db, empId) => {
  const client = apolloClient({
    authorization: `Bearer ${token}`,
    database: db
  });
  try {
    const response = await client.query({
      fetchPolicy: 'network-only',
      query: gql`
        query fetchEmpById($empId: String) {
          employees(where: { AND: [{ id: $empId }] }) {
            id
            name
            dateCreated
            products {
              id
              name
            }
          }
        }
      `,
      variables: {
        empId: empId
      }
    });

    return response.data.employees[0];
  } catch (e) {
    return [];
  }
};

export const fetchTotalBlogGraphQL = async (
  employeeId,
  accessLevel,
  token,
  domain
) => {
  const client = apolloClient({
    authorization: `Bearer ${token}`,
    database: domain
  });
  const response = await client.query({
    fetchPolicy: 'network-only',
    query: gql`
      query fetchTotalBlogByRole($employeeId: String, $accessLevel: String) {
        fetchTotalBlogByRole(
          employeeId: $employeeId
          accessLevel: $accessLevel
        ) {
          status
          cnt
        }
      }
    `,
    variables: {
      employeeId: employeeId,
      accessLevel
    }
  });
  return response.data.fetchTotalBlogByRole;
};

export const fetchBlogGraphQL = async (
  employeeId,
  accessLevel,
  sort,
  status,
  searchField,
  limit,
  skip,
  token,
  domain
) => {
  let [field, order] = sort.split('|');
  const client = apolloClient({
    authorization: `Bearer ${token}`,
    database: domain
  });
  const response = await client.query({
    fetchPolicy: 'network-only',
    query: gql`
      query fetchBlog($employeeId: String, $accessLevel: String, $propName: String, $status: String, $searchField: String,$limit: Int!,  $skip: Int!){
      ${order === 'desc' ? 'fetchBlogByRoleDesc' : 'fetchBlogByRoleAsc'}
      (
        employeeId: $employeeId,
        accessLevel: $accessLevel, 
        propName: $propName, 
        status: $status, 
        searchField: $searchField, 
        skip: $skip, 
        limit: $limit
      ){
          id
          status
          dateCreated
          createdBy{name}
          title
          content
        }
      }
      `,
    variables: {
      employeeId: employeeId,
      accessLevel,
      propName: field,
      status: status === 'ALL' ? null : status,
      searchField: searchField,
      skip,
      limit
    }
  });
  return order === 'desc'
    ? response.data.fetchBlogByRoleDesc
    : response.data.fetchBlogByRoleAsc;
};

export const fetchTotalWebpageGraphQL = async (
  employeeId,
  accessLevel,
  token,
  domain
) => {
  const client = apolloClient({
    authorization: `Bearer ${token}`,
    database: domain
  });
  const response = await client.query({
    fetchPolicy: 'network-only',
    query: gql`
      query ($employeeId:String,$accessLevel:String) {
        fetchTotalPageByRole(
            employeeId:$employeeId,
            accessLevel: $accessLevel,
          ) {
          status
          cnt
        }
      }
    `,
    variables: {
      employeeId: employeeId,
      accessLevel
    }
  });
  return response.data.fetchTotalPageByRole;
};

export const fetchWebpageGraphQL = async (
  employeeId,
  accessLevel,
  sort,
  status,
  searchField,
  limit,
  skip,
  token,
  domain
) => {
  let [field, order] = sort.split('|');
  const client = apolloClient({
    authorization: `Bearer ${token}`,
    database: domain
  });
  const response = await client.query({
    fetchPolicy: 'network-only',
    query: gql`
      query ($employeeId:String,$accessLevel:String,$skip:Int!, $limit:Int!, $propName:String){
      ${order === 'desc' ? 'fetchPageByRoleDesc' : 'fetchPageByRoleAsc'}
      (
        employeeId:$employeeId,
        accessLevel: $accessLevel,
        skip: $skip,
        limit: $limit,
        propName: $propName
      ){
          id
          hostName
          introduction 
          mainCarousel
          dealOfDay
        }
      }
      `,
    variables: {
      employeeId: employeeId,
      accessLevel,
      propName: field,
      status: status === 'ALL' ? null : status,
      searchField: searchField,
      skip,
      limit
    }
  });
  return order === 'desc'
    ? response.data.fetchPageByRoleDesc
    : response.data.fetchPageByRoleAsc;
};

////// FOR GRAPH DB //////////
export const fetchTotalWHProductFromDB = async (
  employeeId,
  accessLevel,
  searchField,
  warehouse
) => {
  const user = store.getState().user.user;
  const company = store.getState().company.company;
  const client = apolloClient({
    authorization: `Bearer ${user.token}`,
    database: company.domain
  });
  const response = await client.query({
    fetchPolicy: 'network-only',
    query: gql`
      query fetchTotalProductByRole(
        $employeeId: String
        $accessLevel: String
        $searchField: String
        $warehouse: String
      ) {
        fetchTotalProductByRole(
          employeeId: $employeeId
          accessLevel: $accessLevel
          searchField: $searchField
          warehouse: $warehouse
        ) {
          productType
          cnt
        }
      }
    `,
    variables: {
      employeeId: employeeId,
      accessLevel,
      searchField: searchField,
      warehouse
    }
  });
  return response.data.fetchTotalProductByRole;
};

export const fetchWHProductFromDB = async (
  employeeId,
  accessLevel,
  sort,
  status,
  searchField,
  limit,
  skip,
  getState,
  warehouse
) => {
  let [field, order] = sort.split('|');
  const user = getState().user.user;
  const company = getState().company.company;
  const client = apolloClient({
    authorization: `Bearer ${user.token}`,
    database: company.domain
  });
  return await client.query({
    fetchPolicy: 'network-only',
    query: gql`
      query fetchProduct(
        $employeeId: String, $accessLevel: String, $propName: String,
        $limit: Int!, $searchField: String, $skip: Int!, $status: String,
        $warehouse: String
      ){
        ${order === 'desc' ? 'fetchProductByRoleDesc' : 'fetchProductByRoleAsc'}
        (employeeId: $employeeId, accessLevel: $accessLevel, propName: $propName,
        status: $status, searchField: $searchField, limit: $limit, skip: $skip,
        warehouse: $warehouse
        ){
        id
        name
        status
        dateCreated
        productCode
        price
        stock
        whQty(warehouse:$warehouse)
      }
      }
    `,
    variables: {
      employeeId: employeeId,
      accessLevel,
      skip: skip,
      limit: limit,
      propName: field,
      quoteStatus: status,
      searchField: searchField,
      warehouse: warehouse
    }
  });
};

export const fetchAllAvailablePromotionGraphQL = async (
  token,
  domain,
  searchField = null
) => {
  const client = apolloClient({
    authorization: `Bearer ${token}`,
    database: domain
  });
  const response = await client.query({
    fetchPolicy: 'network-only',
    query: gql`
      query fetchAllAvailablePromotion($searchField: String) {
        fetchAllAvailablePromotion(searchField: $searchField) {
          id
          promoCode
          status
          dateCreated
          usingTimes
          usedTimes
          description
          isLimited
          specifyCustomer
          specifyProduct
          value
          customers {
            id
            name
          }
          products {
            id
          }
          expiredDate
          appliedOnTotal
          isPercent
          valuePercent
        }
      }
    `,
    variables: {
      searchField: searchField
    }
  });
  return response.data.fetchAllAvailablePromotion;
};

export const fetchCustomerGraphQL = async customerId => {
  const user = store.getState().user.user;
  const company = store.getState().company.company;
  const client = apolloClient({
    authorization: `Bearer ${user.token}`,
    database: company.domain
  });
  const response = await client.query({
    fetchPolicy: 'network-only',
    query: gql`
      query getCustomerByIds($customerIds: [String]) {
        getCustomerByIds(customerIds: $customerIds) {
          address
          facebook
          zalo
          dateOfBirth
          gender
          notes
          customerCode
          phoneNumber
          dateCreated
        }
      }
    `,
    variables: {
      customerIds: [customerId]
    }
  });
  return response.data.getCustomerByIds;
};

export const fetchSOGraphQL = async (
  employeeId,
  accessLevel,
  skip,
  limit,
  propName,
  soStatus,
  searchField,
  employeeIdLst,
  customerIdLst,
  startTimeRange,
  endTimeRange
) => {
  const user = store.getState().user.user;
  const company = store.getState().company.company;
  const client = apolloClient({
    authorization: `Bearer ${user.token}`,
    database: company.domain
  });
  const response = await client.query({
    fetchPolicy: 'network-only',
    query: gql`
      query fetchSO(
        $employeeId: String
        $accessLevel: String
        $propName: String
        $limit: Int!
        $searchField: String
        $skip: Int!
        $soStatus: String
        $employeeIdLst: [String]
        $customerIdLst: [String]
        $startTimeRange: Float
        $endTimeRange: Float
      ) {
        fetchSaleOrdersByRoleDesc(
          employeeId: $employeeId
          accessLevel: $accessLevel
          propName: $propName
          soStatus: $soStatus
          searchField: $searchField
          limit: $limit
          skip: $skip
          employeeIdLst: $employeeIdLst
          customerIdLst: $customerIdLst
          startTimeRange: $startTimeRange
          endTimeRange: $endTimeRange
        ) {
          id
          formNumber
          status
          dateCreated
          createdBy {
            name
          }
          customer {
            id
            name
          }
          employees {
            name
          }
          total
          totalPromo
          lineItems {
            name
          }
        }
      }
    `,
    variables: {
      employeeId: employeeId,
      accessLevel: accessLevel,
      skip: skip,
      limit: limit,
      propName: propName,
      soStatus: soStatus === 'ALL' ? null : soStatus,
      searchField: searchField,
      employeeIdLst: employeeIdLst,
      customerIdLst: customerIdLst,
      startTimeRange: startTimeRange,
      endTimeRange: endTimeRange
    }
  });
  return response.data.fetchSaleOrdersByRoleDesc;
};

export const fetchQuoteGraphQL = async (
  employeeId,
  accessLevel,
  skip,
  limit,
  propName,
  quoteStatus,
  searchField,
  employeeIdLst,
  customerIdLst,
  startTimeRange,
  endTimeRange
) => {
  const user = store.getState().user.user;
  const company = store.getState().company.company;
  const client = apolloClient({
    authorization: `Bearer ${user.token}`,
    database: company.domain
  });
  const response = await client.query({
    fetchPolicy: 'network-only',
    query: gql`
      query fetchQuote(
        $employeeId: String
        $accessLevel: String
        $propName: String
        $limit: Int!
        $searchField: String
        $skip: Int!
        $quoteStatus: String
        $employeeIdLst: [String]
        $customerIdLst: [String]
        $startTimeRange: Float
        $endTimeRange: Float
      ) {
        fetchQuotesByRoleDesc(
          employeeId: $employeeId
          accessLevel: $accessLevel
          propName: $propName
          quoteStatus: $quoteStatus
          searchField: $searchField
          limit: $limit
          skip: $skip
          employeeIdLst: $employeeIdLst
          customerIdLst: $customerIdLst
          startTimeRange: $startTimeRange
          endTimeRange: $endTimeRange
        ) {
          id
          formNumber
          status
          dateCreated
          createdBy {
            name
          }
          customer {
            id
            name
          }
          employees {
            id
            name
          }
          total
          totalPromo
        }
      }
    `,
    variables: {
      employeeId: employeeId,
      accessLevel: accessLevel,
      skip: skip,
      limit: limit,
      propName: propName,
      quoteStatus: quoteStatus,
      searchField: searchField,
      employeeIdLst: employeeIdLst,
      customerIdLst: customerIdLst,
      startTimeRange: startTimeRange,
      endTimeRange: endTimeRange
    }
  });
  return response.data.fetchQuotesByRoleDesc;
};

export const fetchSaleOrderTotalRevenueByCustomer = async customerId => {
  const user = store.getState().user.user;
  const company = store.getState().company.company;
  const client = apolloClient({
    authorization: `Bearer ${user.token}`,
    database: company.domain
  });
  const response = await client.query({
    fetchPolicy: 'network-only',
    query: gql`
      query fetchSaleOrderTotalRevenueByCustomer($customerId: String) {
        fetchSaleOrderTotalRevenueByCustomer(customerId: $customerId)
      }
    `,
    variables: {
      customerId: customerId
    }
  });

  return response.data.fetchSaleOrderTotalRevenueByCustomer;
};

export const fetchBoughtProductsGraphQL = async (
  employeeId,
  accessLevel,
  soStatusLst,
  customerIdLst,
  productTypeLst,
  skip,
  limit
) => {
  const user = store.getState().user.user;
  const company = store.getState().company.company;
  const client = apolloClient({
    authorization: `Bearer ${user.token}`,
    database: company.domain
  });

  const response = await client.query({
    fetchPolicy: 'network-only',
    query: gql`
      query fetchBoughtProducts(
        $employeeId: String
        $accessLevel: String
        $soStatusLst: [String]
        $customerIdLst: [String]
        $productTypeLst: [String]
        $skip: Int!
        $limit: Int!
      ) {
        fetchBoughtProducts(
          employeeId: $employeeId
          accessLevel: $accessLevel
          soStatusLst: $soStatusLst
          customerIdLst: $customerIdLst
          productTypeLst: $productTypeLst
          skip: $skip
          limit: $limit
        ) {
          transId
          name
          boughtDate
          quantity
          price
          total
          formNumber
        }
      }
    `,
    variables: {
      employeeId: employeeId,
      accessLevel: accessLevel,
      soStatusLst: soStatusLst,
      customerIdLst: customerIdLst,
      productTypeLst: productTypeLst,
      skip: skip,
      limit: limit
    }
  });
  return response.data.fetchBoughtProducts;
};

export const fetchPromotionByCustomer = async (
  customerId,
  skip,
  limit
) => {
  const user = store.getState().user.user;
  const company = store.getState().company.company;
  const client = apolloClient({
    authorization: `Bearer ${user.token}`,
    database: company.domain
  });

  const response = await client.query({
    fetchPolicy: 'network-only',
    query: gql`
      query fetchAvailablePromotionOfCustomer(
        $customerId: String
        $skip: Int!
        $limit: Int!
      ) {
        fetchAvailablePromotionOfCustomer(
          customerId: $customerId
          skip: $skip
          limit: $limit
        ) {
          id
          promoCode
          value
          valuePercent
          description
          expiredDate
        }
      }
    `,
    variables: {
      customerId: customerId,
      skip: skip,
      limit: limit
    }
  });
  return response.data.fetchAvailablePromotionOfCustomer;
};
