import { createSlice } from '@reduxjs/toolkit';
import {
  transformDate,
  transformToFirebaseTimestamp
} from '../../utils/formater';
import queries, {
  customerIDRef,
  customersRef,
  serverTime
} from '../../rolesbased-rules/rolesBasedQuery';
import _ from 'lodash';
import history from '../../history';
import { apolloClient } from '../../contexts/graphqlContext';
import { gql } from '@apollo/client';
import store from '../index'
import { fetchBookings, fetchCustServices } from '../../rolesbased-rules/GQL/queries';

const initialState = {
  customers: {},
  localCus: {
    // CEO: [],
    // SALES_REP: [],
    // SALES_MANAGER: [],
    // DOCTOR: []
  },
  lastDoc: [],
  totalCus: {
    // CEO: -1,
    // SALES_REP: -1,
    // SALES_MANAGER: -1,
    // DOCTOR: -1
  }
};

const slice = createSlice({
  name: 'customers',
  initialState,
  reducers: {
    fetchCustomer(state, action) {
      state.customers = {
        ...state.customers,
        [action.payload.id]: action.payload
      };
    },
    fetchCustomers(state, action) {
      state.customers = {
        ...state.customers,
        ..._.mapKeys(action.payload, 'id')
      };
    },
    fetchCustomerServices(state, action) {
      const id = action.payload.id
      const prev_cust = state.customers[id] ?? {}
      state.customers = {
        ...state.customers,
        [id]: { ...prev_cust, ...action.payload }
      };
    },
    createCustomer(state, action) {
      state.customers = {
        ...state.customers,
        [action.payload.id]: action.payload
      };
    },
    editCustomer(state, action) {
      state.customers = {
        ...state.customers,
        [action.payload.id]: action.payload
      };
    },
    deleteCustomer(state, action) {
      state.customers = _.omit(state.customers, action.payload);
    },
    deleteLocalCustomer(state, action) {
      const { id, role } = action.payload;
      state.localCus[role] = state.localCus[role]
        .filter(cust => cust.id !== id)
    },
    updateCusCacheById(state, action) {
      const { cusCache } = action.payload;
      state.customers = {
        ...state.customers,
        [cusCache.id]: cusCache.detail
      };
    },
    setCusCacheById(state, action) {
      const { cusCache } = action.payload;
      state.customers = {
        ...state.customers,
        [cusCache.id]: cusCache.detail
      };
    },
    refreshLastDocument(state, action) {
      //Xoá hết lastDoc
      state.lastDoc = [];
    },
    setLastDocument(state, action) {
      //Thêm vào cuối lastDoc
      const { doc } = action.payload;
      state.lastDoc = [...state.lastDoc, doc];
    },
    updateLocalCus(state, action) {
      const { localCus, role } = action.payload;
      state.localCus = {
        ...state.localCus,
        [role]: localCus
      };
    },
    updateTotalCus(state, action) {
      const { totalCus, role } = action.payload;
      state.totalCus = {
        ...state.totalCus,
        [role]: totalCus
      };
    }
  }
});

export const reducer = slice.reducer;

const descendingComparator = (a, b, orderBy, listField) => {
  let field = orderBy.split('.')[0]; //1 biến chính là orderby
  let innerField = orderBy.split('.')[1];

  if (listField.includes(field)) {
    a = a[field];
    b = b[field];
    field = innerField;
  }

  //b O orderBy: doctor.name
  if (b[field] < a[field]) {
    return -1;
  }

  if (b[field] > a[field]) {
    return 1;
  }

  return 0;
};

const getComparator = (order, orderBy, listField) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy, listField)
    : (a, b) => -descendingComparator(a, b, orderBy, listField);
};
export const setLastDocument = (doc) => async (dispatch, getState) => {
  dispatch(slice.actions.setLastDocument({ doc: doc }));
};

export const refreshLastDocument = (doc) => async (dispatch, getState) => {
  await dispatch(slice.actions.refreshLastDocument());
  return true;
};

export const deleteCustomer = (id) => async (dispatch, getState) => {
  const { currentAccessLevel } = getState().user
  await customersRef.doc(id).delete();

  dispatch(slice.actions.deleteCustomer(id));
  dispatch(slice.actions.deleteLocalCustomer({id, role:currentAccessLevel}));
};

export const updateCusFrom = (lastDocList, limit, order, field, page, listField) => async (dispatch, getState) => {
  let query = null;
  let CusList = [];

  if (!page) {
    query = customersRef.orderBy(field, order).limit(limit);
  } else {
    query = customersRef
      .orderBy(field, order)
      .startAfter(lastDocList[page - 1])
      .limit(limit);
  }

  let docs = await query.get();

  let arr = getState().customers.localCus.slice();

  docs.forEach((doc) => {
    CusList.push(transformDate(doc.data()));
  });

  CusList.forEach((ele) => {
    let idx = arr.findIndex((x) => x.id === ele.id);
    if (idx > -1) {
      arr[idx] = ele;
    } else {
      arr.push(ele);
    }
  });

  arr.sort(getComparator(order, field, listField));
  dispatch(slice.actions.updateLocalCus({ localCus: arr }));
};

export const fetchCusFrom =
  (isFirstQuery, lastDoc, limit, order, field, listField) =>
    async (dispatch, getState) => {
      let query = null;
      let CusList = [];

      if (isFirstQuery) {
        query = customersRef.orderBy(field, order).limit(limit);
      } else {
        query = customersRef
          .orderBy(field, order)
          .startAfter(lastDoc)
          .limit(limit);
      }

      let docs = await query.get();
      if (docs.docs.length === 0) {
        return;
      }
      //Set last doc
      await dispatch(
        slice.actions.setLastDocument({ doc: docs.docs[docs.docs.length - 1] })
      );
      let arr = getState().customers.localCus.slice();

      docs.forEach((doc) => {
        let data = doc.data();
        let have = arr.find((x) => x.id === data.id);
        if (!have) {
          //Khác mới push vào
          CusList.push(transformDate(data));
        }
      });

      let newList = [...arr, ...CusList];
      newList.sort(getComparator(order, field, listField));

      dispatch(slice.actions.updateLocalCus({ localCus: newList }));
    };

export const fetchFirstLimitCus =
  (limit, order, field, listField) => async (dispatch, getState) => {
    const eleList = [];
    const query = customersRef.orderBy(field, order).limit(limit);
    const docs = await query.get();

    //Set last doc
    await dispatch(
      slice.actions.setLastDocument({ doc: docs.docs[docs.docs.length - 1] })
    );

    docs.forEach((doc) => {
      eleList.push(transformDate(doc.data()));
    });

    eleList.sort(getComparator(order, field, listField));
    dispatch(slice.actions.updateLocalCus({ localCus: eleList }));
  };

export const updateCusCacheById = (cusdetail) => async (dispatch, getState) => {
  dispatch(
    slice.actions.updateCusCacheById({
      cusCache: {
        id: cusdetail.id,
        detail: cusdetail
      }
    })
  );
};
export const setCusCacheById = (id) => async (dispatch, getState) => {
  let doc = await customerIDRef(id).get();
  if (doc.data()) {
    const data = transformDate(doc.data());

    dispatch(
      slice.actions.setCusCacheById({
        cusCache: {
          id: id,
          detail: data
        }
      })
    );
  } else {
    alert('Có lỗi khi lấy thông tin của phiếu nhập' + id.toString());
    history.push('/management/receive-notes');
  }
};

export const fetchCustomer = (id) => async (dispatch, getState) => {
  const { currentAccessLevel } = getState().user;
  if (currentAccessLevel) {
    const { customerRef } = queries[currentAccessLevel];
    const customer = await customerRef(id).get();
    dispatch(slice.actions.fetchCustomer(transformDate(customer.data())));
  }
};

export const fetchCustomerServices = (id) => async (dispatch, getState) => {

  const customer = await fetchCustServices(id);
  dispatch(slice.actions.fetchCustomerServices(customer));
};

export const fetchCustomers = () => async (dispatch, getState) => {
  const { currentAccessLevel, uid } = getState().user;
  const myEmployees = [uid];
  const { customersRef } = queries[currentAccessLevel];

  const customers = await customersRef(myEmployees).get();
  dispatch(
    slice.actions.fetchCustomers({
      customers: customers.data()
    })
  );
};

export const createCustomer = (data) => async (dispatch, getState) => {
  const { currentAccessLevel } = getState().user;
  const { customerCreateRef } = queries[currentAccessLevel];

  const newCustomerRef = customerCreateRef().doc();
  const id = newCustomerRef.id;
  const timestamp = serverTime();
  const transformData = transformToFirebaseTimestamp(data);
  const customer_data = {
    ...transformData,
    dateCreated: timestamp,
    dateModified: timestamp,
    id
  };

  await newCustomerRef.set(customer_data);
  dispatch(slice.actions.createCustomer(transformDate(transformData)));

  // history.push('/customers/list/');
};

export const editCustomer = (data, id) => async (dispatch, getState) => {
  const { currentAccessLevel } = getState().user;
  const { customerRef } = queries[currentAccessLevel];

  const timestamp = serverTime();
  const transformData = transformToFirebaseTimestamp(data);
  const customer_data = {
    ...transformData,
    dateModified: timestamp
  };

  await customerRef(id).update(customer_data);

  dispatch(slice.actions.editCustomer(transformDate(transformData)));

  // history.push(`/customers/${id}/`);
};

export const sortCus = (order, field, listField) => (dispatch, getState) => {
  const customer = getState().customers.localCus;
  const data = customer.slice().sort(getComparator(order, field, listField));
  dispatch(slice.actions.updateLocalCus({ localCus: data }));
};
/// GRAPH DB ///////
const fetchTotalCusFromDB = async (employeeId, accessLevel, searchField, employeeIdLst, timeRange) => {
  const user = store.getState().user.user;
  const company = store.getState().company.company;
  const client = apolloClient({
    authorization: `Bearer ${user.token}`,
    database: company.domain
  });
  const response = await client.query({
    fetchPolicy: 'network-only',
    query: gql`
      query fetchCustomerByRole($employeeId: String, $accessLevel: String, $searchField: String, $employeeIdLst: [String], $startTimeRange: Float, $endTimeRange: Float) {
        fetchCustomerByRole(
          employeeId: $employeeId
          accessLevel: $accessLevel,
          searchField: $searchField,
          employeeIdLst: $employeeIdLst,
          startTimeRange: $startTimeRange, 
          endTimeRange: $endTimeRange
        )
      }
    `,
    variables: {
      employeeId: employeeId,
      accessLevel,
      searchField: searchField,
      employeeIdLst: employeeIdLst,
      startTimeRange: timeRange.startTime,
      endTimeRange: timeRange.endTime
    }
  });
  return response.data.fetchCustomerByRole;
}


export const fetchToTalCusGraph = (employeeId, accessLevel, _, searchField = null, setLoading, employeeIdLst = null, __, timeRange = { startTime: null, endTime: null }) => async (dispatch, getState) => {
  setLoading(true);
  let data = await fetchTotalCusFromDB(employeeId, accessLevel, searchField, employeeIdLst, timeRange)
  dispatch(slice.actions.updateTotalCus({ totalCus: data, role: accessLevel }));
  setLoading(false);
  return data
};

const fetchCusFromDB = async (employeeId, accessLevel, sort, limit, skip, getState, searchField, employeeIdLst, timeRange) => {
  let [field, order] = sort.split('|');
  const user = getState().user.user;
  const company = getState().company.company;
  const client = apolloClient({
    authorization: `Bearer ${user.token}`,
    database: company.domain
  });
  const response = await client.query({
    fetchPolicy: 'network-only',
    query: gql`
      query fetchCustomer($employeeId: String, $accessLevel: String, $propName: String, $limit: Int!, $skip: Int!, $searchField: String, $employeeIdLst: [String], $startTimeRange: Float, $endTimeRange: Float){
      ${order === 'desc' ? 'fetchCustomerByRoleDesc' : 'fetchCustomerByRoleAsc'}
        (
          employeeId: $employeeId,
          accessLevel: $accessLevel, 
          propName: $propName, 
          limit: $limit, 
          skip: $skip,
          searchField: $searchField, 
          employeeIdLst: $employeeIdLst,
          startTimeRange: $startTimeRange, 
          endTimeRange: $endTimeRange
        ){
          id
          address
          addressObj{street, fullAddress}
          customerCode
          dateOfBirth
          dateCreated
          name
          email
          phoneNumber
          employees{id,name,email}
        }
      }
      `,
    variables: {
      employeeId: employeeId,
      accessLevel: accessLevel,
      skip: skip,
      limit: limit,
      propName: field,
      searchField: searchField,
      employeeIdLst: employeeIdLst,
      startTimeRange: timeRange.startTime,
      endTimeRange: timeRange.endTime
    }
  });
  return response;
};


export const fetchCusGraph = (employeeId, accessLevel, sort, _, searchField = null, limit, skip, listField, setLoading, fromSort = false, employeeIdLst = null, __, timeRange = { startTime: null, endTime: null }) => async (dispatch, getState) => {
  setLoading(true);

  let [field, order] = sort.split('|');

  const role = getState().user.currentAccessLevel;

  const response = await fetchCusFromDB(employeeId, accessLevel, sort, limit, skip, getState, searchField, employeeIdLst, timeRange);

  let data = order === 'desc' ? response.data.fetchCustomerByRoleDesc : response.data.fetchCustomerByRoleAsc;

  if (fromSort) {
    const arr = data.slice().sort(getComparator(order, field, listField));
    await dispatch(
      slice.actions.updateLocalCus({ localCus: arr, role: role })
    );
  } else {
    let arr = getState().customers.localCus[role] ?? []
    arr = arr.slice();
    data.forEach((ele) => {
      let have = arr.find((x) => x.id === ele.id);
      if (!have) {
        arr.push(ele);
      }
    });
    arr.sort(getComparator(order, field, listField));
    await dispatch(
      slice.actions.updateLocalCus({ localCus: arr, role: role })
    );
  }

  setLoading(false);
};

export const updateCusGraph = (employeeId, accessLevel, sort, _, searchField = null, limit, page, listField, employeeIdLst = null, __, timeRange = { startTime: null, endTime: null }) => async (dispatch, getState) => {
  const [field, order] = sort.split('|');
  const skip = page * limit;

  const role = getState().user.currentAccessLevel;
  const response = await fetchCusFromDB(employeeId, accessLevel, sort, limit, skip, getState, searchField, employeeIdLst, timeRange);

  let data = order === 'desc' ? response.data.fetchCustomerByRoleDesc : response.data.fetchCustomerByRoleAsc;

  let arr = getState().customers.localCus[role] ?? []
  arr = arr.slice();

  data.forEach((ele) => {
    let idx = arr.findIndex((x) => x.id === ele.id);
    if (idx > -1) {
      arr[idx] = ele;
    } else {
      arr.push(ele);
    }
  });

  arr.sort(getComparator(order, field, listField));
  dispatch(slice.actions.updateLocalCus({ localCus: arr, role: role }));
};

export default slice;
