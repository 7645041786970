import { useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Avatar,
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Popover,
  SvgIcon,
  Tooltip,
  Typography,
  Badge,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
  Bell as BellIcon,
  Package as PackageIcon,
  MessageCircle as MessageIcon,
  Truck as TruckIcon
} from 'react-feather';
import { useDispatch, useSelector } from 'src/store';
import {
  markNotiAsRead,
  createNoti
} from '../../../rolesbased-rules/rolesBasedQuery';
import { setNotifications } from '../../../store/slices/notification';

const iconsMap = {
  order_placed: PackageIcon,
  new_message: MessageIcon,
  item_shipped: TruckIcon
};

const useStyles = makeStyles((theme) => ({
  popover: {
    width: 320
  },
  icon: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText
  }
}));

const Notifications = () => {
  const classes = useStyles();
  const redux_dispatch = useDispatch();
  const [limit, setLimit] = useState(1);
  const { notifications } = useSelector((state) => state.notifications);
  const { user } = useSelector((state) => state.user);
  const { cntUnread } = useSelector((state) => state.notifications);

  const ref = useRef(null);

  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleMarkAsRead = () => {
    markNotiAsRead(user.id, notifications.slice(0, limit));
  };
  const loadMore = () => {
    redux_dispatch(
      setNotifications(
        user.id,
        1,
        notifications[notifications.length - 1].order
      )
    );
    setLimit(limit + 1);
  };
  return <>
    <Tooltip title="Thông báo">
      <IconButton color="inherit" ref={ref} onClick={handleOpen} size="large">
        <Badge badgeContent={cntUnread} color="secondary">
          <BellIcon />
        </Badge>
      </IconButton>
    </Tooltip>
    <Popover
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      classes={{ paper: classes.popover }}
      anchorEl={ref.current}
      onClose={handleClose}
      open={isOpen}
    >
      <Box p={2}>
        <Typography variant="h5" color="textPrimary">
          Thông báo
        </Typography>
      </Box>
      {notifications.length === 0 ? (
        <Box p={2}>
          <Typography variant="h6" color="textPrimary">
            Không có thông báo mới
          </Typography>
          <Button
            size="small"
            onClick={() =>
              createNoti(user.id, {
                createdAt: Date.now(),
                description: 'nội dung thông báo',
                title: 'thông báo',
                type: 'order_placed',
                unread: true
              })
            }
          >
            Tạo noti để test
          </Button>
        </Box>
      ) : (
        <>
          <List disablePadding>
            {notifications.map((notification, ind) => {
              if (ind >= limit) return null;
              const Icon = iconsMap[notification.type];

              return (
                <ListItem
                  component={RouterLink}
                  divider
                  key={notification.id}
                  to="#"
                >
                  <ListItemAvatar>
                    <Avatar className={classes.icon}>
                      <SvgIcon fontSize="small">
                        <Icon />
                      </SvgIcon>
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={notification.title}
                    primaryTypographyProps={{
                      variant: 'subtitle2',
                      color: 'textPrimary'
                    }}
                    secondary={notification.description}
                  />
                </ListItem>
              );
            })}
          </List>
          {limit < cntUnread ? (
            <Box p={1} display="flex" justifyContent="center">
              <Button size="small" onClick={loadMore}>
                Xem thêm
              </Button>
            </Box>
          ) : null}

          <Box p={1} display="flex" justifyContent="center">
            <Button size="small" onClick={handleMarkAsRead}>
              Đánh dấu tất cả đã đọc
            </Button>
          </Box>
          <Box p={1} display="flex" justifyContent="center">
            <Button
              size="small"
              onClick={() =>
                createNoti(user.id, {
                  createdAt: Date.now(),
                  description: 'nội dung thông báo',
                  title: 'thông báo',
                  type: 'order_placed',
                  unread: true
                })
              }
            >
              Tạo noti để test
            </Button>
          </Box>
        </>
      )}
    </Popover>
  </>;
};

export default Notifications;
