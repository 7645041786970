export const APP_VERSION = '3.1.0';

export const ENABLE_REDUX_DEV_TOOLS = true;

export const THEMES = {
  LIGHT: 'LIGHT',
  ONE_DARK: 'ONE_DARK',
  UNICORN: 'UNICORN',
  DARK: 'DARK'
};

export const APPLY_FOR = {
  DESKTOP_ONLY: 'DESKTOP_ONLY',
  MOBILE_ONLY: 'MOBILE_ONLY',
  ALL: 'ALL'
};

export const SALE_ORDER = {
  STATUS: {
    PARTIALLY_FULFILLED: 'PARTIALLY_FULFILLED',
    FULFILLED: 'FULFILLED',
    OPEN: 'OPEN',
    CANCEL: 'CANCEL'
  }
};

export const PRODUCT = {
  PRODUCT_TYPE: {
    SERVICE: 'SERVICE',
  }
}

export const QUOTE = {
  STATUS: {
    APPROVED: 'APPROVED',
    OPEN: 'OPEN',
    CANCEL: 'CANCEL'
  }
};
