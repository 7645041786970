import { createSlice } from '@reduxjs/toolkit';
import queries, {
  teamsRef,
  serverTime
} from 'src/rolesbased-rules/rolesBasedQuery';
import _ from 'lodash';
import {
  transformDate,
  transformToFirebaseTimestamp
} from 'src/utils/formater';

const initialState = {
  teams: {},
  isModalOpen: false
};

const slice = createSlice({
  name: 'teams',
  initialState,
  reducers: {
    getTeams(state, action) {
      state.teams = { ...state.teams, ..._.mapKeys(action.payload, 'id') };
    },
    createTeamAction(state, action) {
      state.teams = { ...state.teams, [action.payload.id]: action.payload };
    },
    deleteTeamAction(state, action) {
      delete state.teams[action.payload.id];
    },
    updateTeamAction(state, action) {
      state.teams = { ...state.teams, [action.payload.id]: action.payload };
    },
    openModal(state) {
      state.isModalOpen = true;
    },
    closeModal(state) {
      state.isModalOpen = false;
      state.selectedEventId = null;
      state.selectedRange = null;
    }
  }
});

export const reducer = slice.reducer;

export const getTeams = () => async (dispatch) => {
  const snap = await teamsRef.orderBy('name').get();
  const emp = [];
  snap.forEach((doc) => {
    emp.push(transformDate(doc.data()));
  });
  dispatch(slice.actions.getTeams(emp));
};

export const  createTeamAction = (data) => async (dispatch, getState) => {
  const { currentAccessLevel } = getState().user;
  const { teamsCreateRef } = queries[currentAccessLevel];

  const newTeamRef = teamsCreateRef().doc();
  const id = newTeamRef.id;
  const timestamp = serverTime();
  const transformData = transformToFirebaseTimestamp(data.team);
  const team_data = {
    id,
    ...transformData,
    dateCreated: timestamp,
    dataModified: timestamp
  };
  await newTeamRef.set(team_data);
  dispatch(slice.actions.createTeamAction(transformDate(team_data)));
};

export const deleteTeamAction = (team) => async (dispatch, getState) => {
  const { currentAccessLevel } = getState().user;
  const { teamRef } = queries[currentAccessLevel];
  await teamRef(team.id).delete();
  dispatch(slice.actions.deleteTeamAction(team));
};

export const updateTeamAction = (data) => async (dispatch, getState) => {
  const { currentAccessLevel } = getState().user;
  const { teamRef } = queries[currentAccessLevel];
  const transformData = transformToFirebaseTimestamp(data.team);
  const team_data = {
    ...transformData,
    dataModified: serverTime()
  };
  await teamRef(data.team.id).update(team_data);
  dispatch(slice.actions.updateTeamAction(transformDate(team_data)));
};

export const openModal = () => (dispatch) => {
  dispatch(slice.actions.openModal());
};

export const closeModal = () => (dispatch) => {
  dispatch(slice.actions.closeModal());
};

export default slice;
