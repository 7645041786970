import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import axios from 'src/utils/axios';
import { transformDate2 } from '../../utils/formater';
import { fetchBookings } from '../../rolesbased-rules/GQL/queries';
import history from '../../history';
import { productRef } from '../../rolesbased-rules/rolesBasedQuery';

const initialState = {
  events: [],
  bookings: {},
  isModalOpen: false,
  selectedEventId: null,
  selectedRange: null
};

const slice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    getBookings(state, action) {
      state.bookings = {
        ...state.bookings,
        ..._.mapKeys(action.payload, 'id')
      }
    },
    createEvent(state, action) {
      const { data } = action.payload;

      state.bookings = {
        ...state.bookings,
        [data.id] : data
      };
    },
    selectEvent(state, action) {
      const { eventId = null } = action.payload;

      state.isModalOpen = true;
      state.selectedEventId = eventId;
    },
    updateEvent(state, action) {
      const { data } = action.payload;

      state.bookings = {
        ...state.bookings,
        [data.id]: data
      }
    },
    updateRelatedSO(state, action) {
      const { id, relatedOrder } = action.payload;

      state.bookings = {
        ...state.bookings,
        [id]: { ...state.bookings[id], relatedOrder }
      }
    },
    deleteBooking(state, action) {
      const bookingId = action.payload;

      state.bookings = _.reject(state.bookings, { id: bookingId });
    },
    selectRange(state, action) {
      const { start, end } = action.payload;

      state.isModalOpen = true;
      state.selectedRange = {
        start,
        end
      };
    },
    openModal(state) {
      state.isModalOpen = true;
    },
    closeModal(state) {
      state.isModalOpen = false;
      state.selectedEventId = null;
      state.selectedRange = null;
    }
  }
});

export const reducer = slice.reducer;

export const getBookings = () => async (dispatch) => {

  const bookings = await fetchBookings();
  console.log("<<<<<<<<<<< GET BOOKING");
  console.log(bookings);
  dispatch(slice.actions.getBookings(bookings));
};

export const createEvent = (data) => async (dispatch, getState) => {
  console.log("<<<<<<< DATA BEFORE TRANSFORM")
  console.log(data)

  // process data
  const data_transform = transformDate2(data, 'datetime-epoch')
  const user = getState().user.user;
  const company = getState().company.company;
  data_transform.type = "BACK_OFFICE"
  data_transform.createdBy = {
    name: user.name,
    id: user.id,
    avatar: user.avatar
  }

  data_transform.services = data_transform.services.filter(x => x.id)

  console.log("<<<<<<< DATA TRANSFORM")
  console.log(data_transform)

  const response = await axios.post(
    '/api/v1/booking',
    data_transform,
    {
      headers: {
        authorization: `Bearer ${user.token}`,
        database: company.domain
      }
    }
  );

  dispatch(slice.actions.createEvent(response.data));
};

export const selectEvent = (eventId) => async (dispatch) => {
  dispatch(slice.actions.selectEvent({ eventId }));
};

export const updateEvent = (bookingId, data) => async (dispatch, getState) => {
  const data_transform = transformDate2(data, 'datetime-epoch')
  const user = getState().user.user;
  const company = getState().company.company;
  data_transform.type = "BACK_OFFICE"

  data_transform.services = data_transform.services.filter(x => x.id)


  const response = await axios.post(
    `/api/v1/booking/${bookingId}`,
    data_transform,
    {
      headers: {
        authorization: `Bearer ${user.token}`,
        database: company.domain
      }
    }
  );

  dispatch(slice.actions.updateEvent(response.data));
};


export const checkoutVisit = (bookingId) => async (dispatch, getState) => {
  const user = getState().user.user;
  const company = getState().company.company;
  console.log("<<<<<<<<< CALL API");
  const response = await axios.post(
    `/api/v1/booking/${bookingId}/checkout`,
    {},
    {
      headers: {
        authorization: `Bearer ${user.token}`,
        database: company.domain
      }
    }
  );

  dispatch(slice.actions.updateRelatedSO({
    id: bookingId,
    relatedOrder: response.data
  }));

  const order_id = response.data.data.id
  const order_path = `/management/orders/${order_id}/edit`

  history.push(order_path);
};

export const deleteBooking = (id) => async (dispatch, getState) => {

  const user = getState().user.user;
  const company = getState().company.company;

  await axios.delete(
    `/api/v1/booking/${id}`,
    {
      headers: {
        authorization: `Bearer ${user.token}`,
        database: company.domain
      }
    }
  );

  dispatch(slice.actions.deleteBooking(id));
};

export const selectRange = (start, end) => (dispatch) => {
  dispatch(slice.actions.selectRange({
    start: start.getTime(),
    end: end.getTime()
  }));
};

export const openModal = () => (dispatch) => {
  dispatch(slice.actions.openModal());
};

export const closeModal = () => (dispatch) => {
  dispatch(slice.actions.closeModal());
};

export default slice;
