import { createSlice } from '@reduxjs/toolkit';
import {
  transformDate,
  transformToFirebaseTimestamp
} from '../../utils/formater';
import queries, {
  serverTime,
  suppliersRef
} from '../../rolesbased-rules/rolesBasedQuery';
import _ from 'lodash';

const initialState = {
  suppliers: {}
};

const slice = createSlice({
  name: 'suppliers',
  initialState,
  reducers: {
    fetchSupplier(state, action) {
      state.suppliers = {
        ...state.suppliers,
        [action.payload.id]: action.payload
      };
    },
    fetchSuppliers(state, action) {
      state.suppliers = {
        ...state.suppliers,
        ..._.mapKeys(action.payload, 'id')
      };
    },
    createSupplier(state, action) {
      state.suppliers = {
        ...state.suppliers,
        [action.payload.id]: action.payload
      };
    },
    editSupplier(state, action) {
      state.suppliers = {
        ...state.suppliers,
        [action.payload.id]: action.payload
      };
    }
  }
});

export const reducer = slice.reducer;

export const fetchSupplier = (id) => async (dispatch, getState) => {
  const { currentAccessLevel } = getState().user;
  if (currentAccessLevel) {
    const { supplierRef } = queries[currentAccessLevel];
    const sup = await supplierRef(id).get();
    dispatch(slice.actions.fetchSupplier(transformDate(sup.data())));
  }
};

export const fetchSuppliers = () => async (dispatch, getState) => {
  const snap = await suppliersRef.get();
  const suppliers = [];
  snap.forEach((doc) => {
    suppliers.push(transformDate(doc.data()));
  });
  dispatch(slice.actions.fetchSuppliers(transformDate(suppliers)));
};

export const createSupplier = (data) => async (dispatch, getState) => {
  const { currentAccessLevel } = getState().user;
  const { supplierCreateRef } = queries[currentAccessLevel];

  const newsupRef = supplierCreateRef().doc();
  const id = newsupRef.id;
  const timestamp = serverTime();
  const transformData = transformToFirebaseTimestamp(data);
  const sup_data = {
    ...transformData,
    dateCreated: timestamp,
    dateModified: timestamp,
    id
  };

  await newsupRef.set(sup_data);
  // create default location

  dispatch(
    slice.actions.createSupplier(transformDate({ ...transformData, id }))
  );

  // history.push('/customers/list/');
};

export const editSupplier = (data, id) => async (dispatch, getState) => {
  const { currentAccessLevel } = getState().user;
  const { supplierRef } = queries[currentAccessLevel];

  const timestamp = serverTime();
  const transformData = transformToFirebaseTimestamp(data);
  const customer_data = {
    ...transformData,
    dateModified: timestamp
  };

  await supplierRef(id).update(customer_data);

  dispatch(slice.actions.editSupplier(transformDate({ ...transformData, id })));

  // history.push(`/customers/${id}/`);
};

export default slice;
