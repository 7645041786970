import { dataConverter } from './formater'

export const getComparator = (order, orderBy, listField) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy, listField)
    : (a, b) => -descendingComparator(a, b, orderBy, listField);
};

const descendingComparator = (a, b, orderBy, listField) => {
  let field = orderBy.split('.')[0]; //1 biến chính là orderby
  let innerField = orderBy.split('.')[1];

  if (listField.includes(field)) {
    a = a[field];
    b = b[field];
    field = innerField;
  }

  //b O orderBy: doctor.name
  if (b[field] < a[field]) {
    return -1;
  }

  if (b[field] > a[field]) {
    return 1;
  }

  return 0;
};

export const getNestedData = (obj, combineKeys, separator, dataType, inCaseEmpty = null, listField='name') => {
  let values = []
  for (const keys of combineKeys) {
    let elementValue = obj
    for (const key of keys) {
      if (elementValue && key in elementValue) {
        elementValue = elementValue[key]
      } else {
        elementValue = ''
      }
    }
    if (elementValue) {
      values.push(elementValue)
    }
  }

  let value
  if (!values) {
    return ''
  }

  if (values.length > 1) {
    value = values.join(separator)
  } else {
    value = dataConverter(dataType, values[0], inCaseEmpty, listField)
  }

  return value
}
