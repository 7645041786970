import { Suspense, Fragment, lazy } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import LoadingScreen from 'src/components/LoadingScreen';
import AuthGuard from 'src/components/AuthGuard';
import GuestGuard from 'src/components/GuestGuard';

export const renderRoutes = (routes = []) => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>
                  {route.routes ? (
                    renderRoutes(route.routes)
                  ) : (
                    <Component {...props} />
                  )}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);
const routes = [
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/errors/NotFoundView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,
    path: '/login-unprotected',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/register',
    component: lazy(() => import('src/views/auth/RegisterView'))
  },
  {
    exact: true,
    path: '/register-unprotected',
    component: lazy(() => import('src/views/auth/RegisterView'))
  },
  {
    path: '/',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/account',
        component: lazy(() => import('src/views/account/AccountView'))
      },
      {
        exact: true,
        path: '/management/calendar',
        component: lazy(() => import('src/views/calendar/CalendarView'))
      },
      {
        exact: true,
        path: '/management/warehouses',
        component: lazy(() => import('src/views/warehouse/WarehouseListView'))
      },
      {
        exact: true,
        path: '/management/warehouses/create',
        component: lazy(() => import('src/views/warehouse/WarehouseCreateView'))
      },
      {
        exact: true,
        path: '/management/warehouses/:warehouseId',
        component: lazy(() =>
          import('src/views/warehouse/WarehouseDetailsView')
        )
      },
      {
        exact: true,
        path: '/management/warehouses/:warehouseId/edit',
        component: lazy(() => import('src/views/warehouse/WarehouseEditView'))
      },
      {
        exact: true,
        path: '/management/suppliers',
        component: lazy(() => import('src/views/supplier/SupplierListView'))
      },
      {
        exact: true,
        path: '/management/suppliers/create',
        component: lazy(() => import('src/views/supplier/SupplierCreateView'))
      },
      {
        exact: true,
        path: '/management/suppliers/:supplierId',
        component: lazy(() => import('src/views/supplier/SupplierDetailsView'))
      },
      {
        exact: true,
        path: '/management/suppliers/:supplierId/edit',
        component: lazy(() => import('src/views/supplier/SupplierEditView'))
      },
      {
        exact: true,
        path: '/management/customers',
        component: lazy(() =>
          import('src/views/customer/CustomerListViewGraphQL')
        )
      },
      {
        exact: true,
        path: '/management/customers/create',
        component: lazy(() => import('src/views/customer/CustomerCreateView'))
      },
      {
        exact: true,
        path: '/management/customers/:customerId',
        component: lazy(() => import('src/views/customer/CustomerDetailsView'))
      },
      {
        exact: true,
        path: '/management/customers/:customerId/edit',
        component: lazy(() => import('src/views/customer/CustomerEditView'))
      },
      {
        exact: true,
        path: '/management/quotes',
        component: lazy(() => import('src/views/quote/QuoteListViewGraphQL'))
      },
      {
        exact: true,
        path: '/management/quotes/:quoteId/edit',
        component: lazy(() => import('src/views/quote/QuoteCreateView'))
      },
      {
        exact: true,
        path: '/management/quotes/create',
        component: lazy(() => import('src/views/quote/QuoteCreateView'))
      },
      {
        exact: true,
        path: '/management/quotes/:quoteId',
        component: lazy(() => import('src/views/quote/QuoteDetailsView'))
      },
      {
        exact: true,
        path: '/management/orders',
        component: lazy(() => import('src/views/order/OrderListViewGraphQL'))
      },
      {
        exact: true,
        path: '/management/orders/create',
        component: lazy(() => import('src/views/order/OrderCreateView'))
      },
      {
        exact: true,
        path: '/management/orders/create-v2',
        component: lazy(() => import('src/views/order/OrderCreateViewV2'))
      },
      {
        exact: true,
        path: '/management/orders/:orderId',
        component: lazy(() => import('src/views/order/OrderDetailsView'))
      },
      {
        exact: true,
        path: '/management/orders/:orderId/edit',
        component: lazy(() => import('src/views/order/OrderCreateView'))
      },
      {
        exact: true,
        path: '/management/orders/:orderId/edit-v2',
        component: lazy(() => import('src/views/order/OrderCreateViewV2'))
      },
      {
        exact: true,
        path: '/management/promotions',
        component: lazy(() =>
          import('src/views/promotion/PromotionListViewGraphQL')
        )
      },
      {
        exact: true,
        path: '/management/inventory-adjustment',
        component: lazy(() => import('src/views/adjustment/AdjustmentListView'))
      },
      {
        exact: true,
        path: '/management/inventory-adjustment/create',
        component: lazy(() =>
          import('src/views/adjustment/AdjustmentCreateView')
        )
      },
      {
        exact: true,
        path: '/management/inventory-adjustment/:adId',
        component: lazy(() =>
          import('src/views/adjustment/AdjustmentDetailsView')
        )
      },
      {
        exact: true,
        path: '/management/products',
        component: lazy(() => import('src/views/product/ProductListViewQL'))
      },
      {
        exact: true,
        path: '/management/products/create',
        component: lazy(() => import('src/views/product/ProductCreateView'))
      },
      {
        exact: true,
        path: '/management/products/:productId',
        component: lazy(() => import('src/views/product/ProductDetailsView'))
      },
      {
        exact: true,
        path: '/management/products/:productId/edit',
        component: lazy(() => import('src/views/product/ProductEditView'))
      },
      {
        exact: true,
        path: '/management/services',
        component: lazy(() => import('src/views/service/ServiceListViewQL'))
      },
      {
        exact: true,
        path: '/management/services/create',
        component: lazy(() => import('src/views/service/ServiceCreateView'))
      },
      {
        exact: true,
        path: '/management/services/:productId/edit',
        component: lazy(() => import('src/views/service/ServiceEditView'))
      },
      {
        exact: true,
        path: '/management/paid-plan',
        component: lazy(() => import('src/views/paid-plan/PaidPlanListViewQL'))
      },
      {
        exact: true,
        path: '/management/paid-plan/create',
        component: lazy(() => import('src/views/paid-plan/PaidPlanCreateView'))
      },
      {
        exact: true,
        path: '/management/paid-plan/:productId/edit',
        component: lazy(() => import('src/views/paid-plan/PaidPlanEditView'))
      },
      {
        exact: true,
        path: '/management/categories',
        component: lazy(() => import('src/views/categories/CategoryListView'))
      },
      {
        exact: true,
        path: '/management/categories/create',
        component: lazy(() => import('src/views/categories/CategoryCreateView'))
      },
      {
        exact: true,
        path: '/management/categories/:catId',
        component: lazy(() => import('src/views/categories/CategoryEditView'))
      },
      {
        exact: true,
        path: '/management',
        component: () => <Redirect to="/management/customers" />
      },
      {
        exact: true,
        path: '/reports/dashboard',
        component: lazy(() => import('src/views/reports/DashboardView'))
      },
      {
        exact: true,
        path: '/settings/company',
        component: lazy(() => import('src/views/company/CompanyDetailsView'))
      },
      {
        exact: true,
        path: '/settings/user',
        component: lazy(() => import('src/views/account/AccountView'))
      },
      {
        exact: true,
        path: '/settings/roles',
        component: lazy(() => import('src/views/role/RoleListViewGraphQL'))
      },
      {
        exact: true,
        path: '/settings/roles/:roleId',
        component: lazy(() => import('src/views/role/RoleListViewGraphQL'))
      },
      {
        exact: true,
        path: '/settings/employees',
        component: lazy(() => import('src/views/employees/EmployeeListView'))
      },
      {
        exact: true,
        path: '/settings/employees/create',
        component: lazy(() => import('src/views/employees/EmployeeCreateView'))
      },
      {
        exact: true,
        path: '/settings/employees/:employeeId',
        component: lazy(() => import('src/views/employees/EmployeeEditView'))
      },
      {
        exact: true,
        path: '/management/customers/create',
        component: lazy(() => import('src/views/customer/CustomerCreateView'))
      },
      {
        exact: true,
        path: '/management/customers/:customerId',
        component: lazy(() => import('src/views/customer/CustomerDetailsView'))
      },
      {
        exact: true,
        path: '/management/customers/:customerId/edit',
        component: lazy(() => import('src/views/customer/CustomerEditView'))
      },
      {
        exact: true,
        path: '/management/delivery-notes/:saleOrderId',
        component: lazy(() =>
          import('src/views/deliveryNote/deliveryNoteCreate')
        )
      },
      {
        exact: true,
        path: '/management/delivery-notes',
        component: lazy(() =>
          import('src/views/deliveryNote/deliveryNoteListGraphQL')
        )
      },
      {
        exact: true,
        path: '/reports',
        component: () => <Redirect to="/reports/dashboard" />
      },
      {
        exact: true,
        path: '/',
        component: () => <Redirect to="/reports/dashboard" />
      },
      {
        exact: true,
        path: '/management/receive-notes',
        component: lazy(() =>
          import('src/views/receiveNote/ReceiveNoteListView')
        )
      },
      {
        exact: true,
        path: '/management/receive-notes/create',
        component: lazy(() => import('src/views/receiveNote/RNCreateView'))
      },
      {
        exact: true,
        path: '/management/receive-notes/:rnId/edit',
        component: lazy(() => import('src/views/receiveNote/RNCreateView'))
      },
      {
        exact: true,
        path: '/management/receive-notes/:rnId',
        component: lazy(() => import('src/views/receiveNote/RNDetailView'))
      },
      {
        exact: true,
        path: '/management/blogs',
        component: lazy(() => import('src/views/blogs/blogsListView'))
      },
      {
        exact: true,
        path: '/management/blogs/create',
        component: lazy(() => import('src/views/blogs/blogsCreateView'))
      },
      {
        exact: true,
        path: '/management/blogs/:blogId',
        component: lazy(() => import('src/views/blogs/blogsDetailView'))
      },
      {
        exact: true,
        path: '/management/blogs/:blogId/edit',
        component: lazy(() => import('src/views/blogs/blogsCreateView'))
      },
      {
        exact: true,
        path: '/management/webpages',
        component: lazy(() => import('src/views/webpages/webpagesListView'))
      },
      {
        exact: true,
        path: '/management/webpages/create',
        component: lazy(() => import('src/views/webpages/webpagesCreateView'))
      },
      {
        exact: true,
        path: '/management/webpages/:webpageId',
        component: lazy(() => import('src/views/webpages/webpagesDetailView'))
      },
      {
        exact: true,
        path: '/management/webpages/:webpageId/edit',
        component: lazy(() => import('src/views/webpages/webpagesCreateView'))
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  }
];

export default routes;
