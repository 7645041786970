import { createSlice } from '@reduxjs/toolkit';
import {
  saleOrderCacheRef,
  ordersRef
} from '../../rolesbased-rules/rolesBasedQuery';
import history from '../../history';
import { transformDate } from '../../utils/formater';
import firebase from 'src/lib/firebase';
import { apolloClient } from '../../contexts/graphqlContext';
import { gql } from '@apollo/client';
import store from '../index'

const initialState = {
  totalSO: {
    CEO: -1,
    ADMIN: -1,
    WAREHOUSE_MANAGER: -1,
    SALES_REP: -1,
    SALES_MANAGER: -1,
    DOCTOR_MANAGER: -1,
    DOCTOR: -1
  },
  saleOrders: {},
  localSO: {
  },
  lastDoc: [],
  firstRender: true
};

// global store <--- reducer <---- action
// action = pure js obj {type: ""}
// actionCreator = pure js function, nhận vào 1 tham số, tham số này là payload trong action, return action
// const jump = (x) => {type:"JUMP", payload: x}

// reducer pure js func (Old state, action) => new state (=> state immutably update)
// state.user ="x"; ==> Mutably update
// newState ={...state, user: "x"} , state= newState ==> Imu
// pure js func: những func ko thay đổi bất kì giá trị nào của tham số đầu vào
// const sum = (a,b) => a+b
// const sum = (a,b) => {a+=1; return a+b;}
// createSlice (initialState, reducer, name, [extraReducer -- createAsyncThunk]) ---> slice {reducer, actionCreator(type=name+reducer)}

// slice (reducer)

const slice = createSlice({
  name: 'saleOrders',
  initialState,
  reducers: {
    updateSaleOrderCacheById(state, action) {
      const { soCache } = action.payload;
      state.saleOrders = {
        ...state.saleOrders,
        [soCache.id]: soCache.detail
      };
    },
    setSaleOrderCacheById(state, action) {
      const { soCache } = action.payload;
      state.saleOrders = {
        ...state.saleOrders,
        [soCache.id]: soCache.detail
      };
    },
    setFirstRender(state, action) {
      const { isFirst } = action.payload;
      state.firstRender = isFirst;
    },
    setLastDocument(state, action) {
      //Thêm vào cuối lastDoc
      const { doc } = action.payload;
      state.lastDoc = [...state.lastDoc, doc];
    },
    clearLastDoc(state, action) {
      //Xoá hết lastDoc
      state.lastDoc = [];
    },
    updateLocalSO(state, action) {
      // update local so
      const { localSO, role } = action.payload;
      state.localSO = {
        ...state.localSO,
        [role]: localSO
      };
    },
    updateTotalSO(state, action) {
      const { totalSO, role } = action.payload;
      state.totalSO = {
        ...state.totalSO,
        [role]: totalSO
      };
    }
  }
});

export const reducer = slice.reducer;

const descendingComparator = (a, b, orderBy, listField) => {
  let field = orderBy.split('.')[0]; //1 biến chính là orderby
  let innerField = orderBy.split('.')[1];

  if (listField.includes(field)) {
    a = a[field];
    b = b[field];
    field = innerField;
  }

  //b O orderBy: doctor.name
  if (b[field] < a[field]) {
    return -1;
  }

  if (b[field] > a[field]) {
    return 1;
  }

  return 0;
};
const getComparator = (order, orderBy, listField) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy, listField)
    : (a, b) => -descendingComparator(a, b, orderBy, listField);
};

export const updateSaleOrderCacheById =
  (sodetail) => async (dispatch, getState) => {
    dispatch(
      slice.actions.updateSaleOrderCacheById({
        soCache: {
          id: sodetail.id,
          detail: sodetail
        }
      })
    );
  };

export const setFirstRender = (status) => async (dispatch, getState) => {
  await dispatch(slice.actions.setFirstRender({ isFirst: status }));
};

export const clearLocalSO = () => async (dispatch, getState) => {
  await dispatch(slice.actions.updateLocalSO({ localSO: [] }));
};

export const setSaleOrderCacheById = (id) => async (dispatch, getState) => {
  let doc = await saleOrderCacheRef(id).get();
  if (doc.data()) {
    const response = await saleOrderCacheRef(id).collection('images').get();
    const data = doc.data();
    const docList = [];
    response.forEach(async (imageDoc) => {
      const storageUrl = imageDoc.data().storageUrl;
      const imgName = imageDoc.data().imgName;
      docList.push({
        id: imageDoc.data().id,
        storageUrl,
        name: imgName,
        isNew: false
      });
    });
    const imgLink = await Promise.all(
      docList.map(async (ele) => ({
        ...ele,
        image: await firebase.storage().ref(ele.storageUrl).getDownloadURL()
      }))
    );

    data.images = {};
    for (const img of imgLink) data.images[img.id] = img;
    console.log("<<<<<<<< SO DATA");
    console.log(data);
    data.dateCreated =
      data.dateCreated.seconds + data.dateCreated.nanoseconds / 1000000000;
    data.dateModified =
      data.dateModified.seconds + data.dateModified.nanoseconds / 1000000000;
    dispatch(
      slice.actions.updateSaleOrderCacheById({
        soCache: {
          id: id,
          detail: data
        }
      })
    );
  } else {
    alert('Có lỗi khi lấy thông tin của đơn hàng ' + id.toString());
    history.push('/management/orders');
  }
};

export const setLastDocument = (doc) => async (dispatch, getState) => {
  dispatch(slice.actions.setLastDocument({ doc: doc }));
};

export const clearLastDoc = (doc) => async (dispatch, getState) => {
  await dispatch(slice.actions.clearLastDoc());
  return true;
};

export const fetchFirstLimitSaleOrder =
  (limit, order, field, listField) => async (dispatch, getState) => {
    const orderList = [];
    const query = ordersRef.orderBy(field, order).limit(limit);
    const docs = await query.get();

    //Set last doc
    await dispatch(
      slice.actions.setLastDocument({ doc: docs.docs[docs.docs.length - 1] })
    );

    docs.forEach((doc) => {
      orderList.push(transformDate(doc.data()));
    });

    orderList.sort(getComparator(order, field, listField));
    dispatch(slice.actions.updateLocalSO({ localSO: orderList }));
  };

export const fetchSaleOrderFrom = (isFirstQuery, lastDoc, limit, order, field, listField) => async (dispatch, getState) => {
  let query = null;
  let orderList = [];

  if (isFirstQuery) {
    query = ordersRef.orderBy(field, order).limit(limit);
  } else {
    query = ordersRef.orderBy(field, order).startAfter(lastDoc).limit(limit);
  }

  let docs = await query.get();
  if (docs.docs.length === 0) {
    return;
  }
  //Set last doc
  await dispatch(
    slice.actions.setLastDocument({ doc: docs.docs[docs.docs.length - 1] })
  );
  let arr = getState().saleorder.localSO.slice();

  docs.forEach((doc) => {
    let data = doc.data();
    let have = arr.find((x) => x.id === data.id);
    if (!have) {
      //Khác mới push vào
      orderList.push(transformDate(data));
    }
  });

  let newList = [...arr, ...orderList];
  newList.sort(getComparator(order, field, listField));

  dispatch(slice.actions.updateLocalSO({ localSO: newList }));
};

export const updateSaleOrderFrom =
  (lastDocList, limit, order, field, page, listField) =>
    async (dispatch, getState) => {
      let query = null;
      let orderList = [];

      if (!page) {
        query = ordersRef.orderBy(field, order).limit(limit);
      } else {
        query = ordersRef
          .orderBy(field, order)
          .startAfter(lastDocList[page - 1])
          .limit(limit);
      }

      let docs = await query.get();

      let arr = getState().saleorder.localSO.slice();

      docs.forEach((doc) => {
        orderList.push(transformDate(doc.data()));
      });

      orderList.forEach((order) => {
        let idx = arr.findIndex((x) => x.id === order.id);
        if (idx > -1) {
          arr[idx] = order;
        } else {
          arr.push(order);
        }
      });

      arr.sort(getComparator(order, field, listField));
      dispatch(slice.actions.updateLocalSO({ localSO: arr }));
    };

////// FOR GRAPH DB //////////
const fetchTotalSOFromDB = async (employeeId, accessLevel, searchField, employeeIdLst, customerIdLst, timeRange) => {
  const user = store.getState().user.user;
  const company = store.getState().company.company;
  const client = apolloClient({
    authorization: `Bearer ${user.token}`,
    database: company.domain
  });
  const response = await client.query({
    fetchPolicy: 'network-only',
    query: gql`
        query fetchTotalSaleOrdersByRole($employeeId: String, $accessLevel: String, $searchField: String, $employeeIdLst: [String], $customerIdLst: [String], $startTimeRange: Float, $endTimeRange: Float) {
          fetchTotalSaleOrdersByRole(
            employeeId: $employeeId,
            accessLevel: $accessLevel, 
            searchField: $searchField, 
            employeeIdLst: $employeeIdLst, 
            customerIdLst: $customerIdLst, 
            startTimeRange: $startTimeRange, 
            endTimeRange: $endTimeRange
          ){
            soStatus,
            cnt
          }
        }
      `,
    variables: {
      employeeId: employeeId,
      accessLevel,
      searchField: searchField,
      customerIdLst: customerIdLst,
      employeeIdLst: employeeIdLst,
      startTimeRange: timeRange.startTime,
      endTimeRange: timeRange.endTime
    }
  });
  return response.data.fetchTotalSaleOrdersByRole;
}
export const fetchTotalSOGraph = (employeeId, accessLevel, soStatus, searchField, setLoading, employeeIdLst = null, customerIdLst = null, timeRange = { startTime: null, endTime: null }) => async (dispatch, getState) => {
  setLoading(true);
  let data = await fetchTotalSOFromDB(employeeId, accessLevel, searchField, employeeIdLst, customerIdLst, timeRange)

  let totalSOByCurrentRole = -1
  if (soStatus === null) {
    totalSOByCurrentRole = data.reduce((prev, cur) => prev + cur.cnt, 0)
  } else {
    const totalSOObjAr = data.filter(ele => ele.soStatus === soStatus)
    if (totalSOObjAr.length > 0) totalSOByCurrentRole = totalSOObjAr[0].cnt
  }

  dispatch(slice.actions.updateTotalSO({ totalSO: totalSOByCurrentRole, role: accessLevel }));
  setLoading(false)
  return data
};

const fetchSOFromDB = async (employeeId, accessLevel, sort, soStatus, searchField, limit, skip, getState, employeeIdLst, customerIdLst, timeRange) => {
  let [field, order] = sort.split('|');
  const user = getState().user.user;
  const company = getState().company.company;
  const client = apolloClient({
    authorization: `Bearer ${user.token}`,
    database: company.domain
  });
  const response = await client.query({
    fetchPolicy: 'network-only',
    query: gql`
      query fetchSO($employeeId: String, $accessLevel: String, $propName: String, $limit: Int!, $searchField: String, $skip: Int!, $soStatus: String, $employeeIdLst: [String], $customerIdLst: [String], $startTimeRange: Float, $endTimeRange: Float){
      ${order === 'desc' ? 'fetchSaleOrdersByRoleDesc' : 'fetchSaleOrdersByRoleAsc'}
        (
          employeeId: $employeeId,
          accessLevel: $accessLevel, 
          propName: $propName, 
          soStatus: $soStatus, 
          searchField: $searchField, 
          limit: $limit, 
          skip: $skip, 
          employeeIdLst: $employeeIdLst, 
          customerIdLst: $customerIdLst,
          startTimeRange: $startTimeRange, 
          endTimeRange: $endTimeRange
        ){
          id
          formNumber
          status
          dateCreated
          createdBy{name}
          customer{id,name}
          employees{name}
          total
          totalPromo
        }
      }
      `,
    variables: {
      employeeId: employeeId,
      accessLevel,
      skip: skip,
      limit: limit,
      propName: field,
      soStatus: soStatus === 'ALL' ? null : soStatus,
      searchField: searchField,
      employeeIdLst: employeeIdLst,
      customerIdLst: customerIdLst,
      startTimeRange: timeRange.startTime,
      endTimeRange: timeRange.endTime
    }
  });
  return response;
};
export const fetchSOGraph = (employeeId, accessLevel, sort, soStatus, searchField, limit, skip, listField, setLoading, fromSort = false, employeeIdLst = null, customerIdLst = null, timeRange = { startTime: null, endTime: null }) => async (dispatch, getState) => {
  setLoading(true);
  let [field, order] = sort.split('|');

  const role = getState().user.currentAccessLevel;

  const response = await fetchSOFromDB(employeeId, accessLevel, sort, soStatus, searchField, limit, skip, getState, employeeIdLst, customerIdLst, timeRange);
  let data = order === 'desc' ? response.data.fetchSaleOrdersByRoleDesc : response.data.fetchSaleOrdersByRoleAsc;

  if (fromSort) {
    const arr = data.slice().sort(getComparator(order, field, listField));
    await dispatch(slice.actions.updateLocalSO({ localSO: arr, role: role }));
  } else {
    let arr = getState().saleorder.localSO[role] ?? [];
    arr = arr.slice();
    data.forEach((ele) => {
      let have = arr.find((x) => x.id === ele.id);
      if (!have) {
        arr.push(ele);
      }
    });
    arr.sort(getComparator(order, field, listField));
    await dispatch(slice.actions.updateLocalSO({ localSO: arr, role: role }));
  }

  setLoading(false);
};

export const updateSOGraph = (employeeId, accessLevel, sort, soStatus, searchField, limit, page, listField, employeeIdLst = null, customerIdLst = null, timeRange = { startTime: null, endTime: null }) => async (dispatch, getState) => {
  const [field, order] = sort.split('|');
  const skip = page * limit;


  const role = getState().user.currentAccessLevel;
  const response = await fetchSOFromDB(employeeId, accessLevel, sort, soStatus, searchField, limit, skip, getState, employeeIdLst, customerIdLst, timeRange);

  let data =
    order === 'desc'
      ? response.data.fetchSaleOrdersByRoleDesc
      : response.data.fetchSaleOrdersByRoleAsc;
  let arr = getState().saleorder.localSO[role] ?? [];
  arr = arr.slice();

  data.forEach((ele) => {
    let idx = arr.findIndex((x) => x.id === ele.id);
    if (idx > -1) {
      arr[idx] = ele;
    } else {
      arr.push(ele);
    }
  });

  arr.sort(getComparator(order, field, listField));
  dispatch(slice.actions.updateLocalSO({ localSO: arr, role: role }));
};

export default slice;
