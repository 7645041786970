export const accentToNonAccentVNese = (str) => {
  str = str.toLowerCase();
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a');
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
  str = str.replace(/đ/g, 'd');
  str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ''); // ` ' ? ~ .
  str = str.replace(/\u02C6|\u0306|\u031B/g, ''); // Â, Ê, Ă, Ơ, Ư
  return str;
};
const trimRedundantSpace = (str) => {
  if (str.length === 0) return '';
  let strOut = str[0];

  for (let i = 1; i < str.length; i++) {
    if (str[i] === ' ' && str[i - 1] === ' ') continue;
    strOut += str[i];
  }
  if (strOut.length && strOut[0] === ' ') strOut = strOut.slice(1);
  if (strOut.length && strOut[strOut.length - 1] === ' ')
    strOut = strOut.slice(0, -1);

  return strOut;
};
const searchFunction = (optionsList, searchStr, key) => {
  let searchString = searchStr.slice();
  searchString = accentToNonAccentVNese(searchString);
  searchString = trimRedundantSpace(searchString);

  return optionsList.filter((opt) => {

    console.log("<<<<<<<<<<<<<<< search option")
    console.log(key)
    console.log(opt[key])

    let temp = opt[key].slice();
    let temp1 = trimRedundantSpace(temp);
    let temp2 = accentToNonAccentVNese(temp1);

    return (
      temp2.includes(searchString) ||
      opt[key].includes(searchStr) ||
      temp1.includes(searchStr)
    );
  });
};
export default searchFunction;
export const createSlugData = (str) => {
  str = accentToNonAccentVNese(str);
  str = trimRedundantSpace(str);
  return str;
};
