import { useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Button,
  Drawer,
  IconButton,
  InputAdornment,
  SvgIcon,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Search as SearchIcon, XCircle as XIcon } from 'react-feather';
import {
  SearchSOResultByProduct,
  SearchSOResultByCustomer,
  SearchProductRes,
  SearchCustomerRes,
  SearchSOResultByFormNumber
} from './SearchQL';
import { createSlugData } from '../../../utils/search';

const useStyles = makeStyles(() => ({
  drawer: {
    width: 500,
    maxWidth: '100%',
    zIndex: 1300
  }
}));

const Search = () => {
  const classes = useStyles();
  const [value, setValue] = useState('');
  const [isOpen, setOpen] = useState(false);
  const [searchStr, setSearchStr] = useState('');
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSearch = () => {
    setSearchStr(value);
  };

  return <>
    <Tooltip title="Tìm kiếm">
      <IconButton color="inherit" onClick={handleOpen} size="large">
        <SvgIcon fontSize="small">
          <SearchIcon />
        </SvgIcon>
      </IconButton>
    </Tooltip>
    <Drawer
      anchor="right"
      classes={{ paper: classes.drawer }}
      className={classes.drawer}
      ModalProps={{ BackdropProps: { invisible: true } }}
      onClose={handleClose}
      open={isOpen}
      variant="temporary"
    >
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Box p={3}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h4" color="textPrimary">
              Tìm kiếm
            </Typography>
            <IconButton onClick={handleClose} size="large">
              <SvgIcon fontSize="small">
                <XIcon />
              </SvgIcon>
            </IconButton>
          </Box>
          <Box mt={2}>
            <TextField
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon fontSize="small" color="action">
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                )
              }}
              onChange={(event) => setValue(event.target.value)}
              placeholder="Tìm sản phẩm, khách hàng &amp; đơn hàng"
              value={value}
              variant="outlined"
              onKeyPress={(e) => {
                if (e.key === 'Enter') setSearchStr(value);
              }}
            />
          </Box>
          <Box mt={2} display="flex" justifyContent="flex-end">
            <Button
              color="secondary"
              variant="contained"
              onClick={handleSearch}
            >
              Tìm kiếm
            </Button>
          </Box>
          <SearchProductRes searchStr={createSlugData(searchStr)} />
          <SearchCustomerRes searchStr={createSlugData(searchStr)} />
          <SearchSOResultByFormNumber searchStr={createSlugData(searchStr)} />
          <SearchSOResultByProduct searchStr={createSlugData(searchStr)} />
          <SearchSOResultByCustomer searchStr={createSlugData(searchStr)} />
        </Box>
      </PerfectScrollbar>
    </Drawer>
  </>;
};

export default Search;
