import { createSlice } from '@reduxjs/toolkit';
import {
  db,
  employeesRef,
  fromDate,
  productRef,
  storageRef
} from '../../rolesbased-rules/rolesBasedQuery';
import _ from 'lodash';
import history from '../../history';
import { transformDate } from '../../utils/formater';
import store from '../index';
import { apolloClient } from '../../contexts/graphqlContext';
import { gql } from '@apollo/client';
import { getComparator } from '../../utils/queryUtils';
import { firebaseFunction } from '../../lib/firebase';
import md5 from 'md5';
import { fetchEmployeeSubObjQL } from '../../rolesbased-rules/GQL/queries';

const initialState = {
  employees: {},
  isModalOpen: false,
  totalEmployee: {
    CEO: -1,
    ADMIN: -1,
    WAREHOUSE_MANAGER: -1,
    SALES_REP: -1,
    SALES_MANAGER: -1,
    DOCTOR_MANAGER: -1,
    DOCTOR: -1
  },
  localEmployeeQL: {
  },
};

const slice = createSlice({
  name: 'employees',
  initialState,
  reducers: {
    getEmployees(state, action) {
      state.employees = {
        ...state.employees,
        ..._.mapKeys(action.payload, 'id')
      };
    },
    fetchEmployee(state, action) {
      state.employees = {
        ...state.employees,
        [action.payload.id]: action.payload
      };
    },
    fetchEmpSubObj(state, action) {
      state.employees = {
        ...state.employees,
        [action.payload.id]: {
          ...state.employees[action.payload.id],
          products: action.payload.products
        }
      }
    },
    createEmployee(state, action) {
      const { employee } = action.payload;

      state.employees = [...state.employees, employee];
    },
    updateEmployee(state, action) {
      const { event } = action.payload;

      state.events = _.map(state.events, (_event) => {
        if (_event.id === event.id) {
          return event;
        }

        return _event;
      });
    },
    deleteEmployee(state, action) {
      state.employees = _.omit(state.employees, action.payload);
    },
    deleteLocalEmployee(state, action) {
      const { id, role } = action.payload;
      state.localEmployeeQL[role] = state.localEmployeeQL[role]
        .filter(cust => cust.id !== id)
    },
    openModal(state) {
      state.isModalOpen = true;
    },
    closeModal(state) {
      state.isModalOpen = false;
      state.selectedEventId = null;
      state.selectedRange = null;
    },
    updateLocalEmp(state, action) {
      const { localEmp, role } = action.payload;
      state.localEmployeeQL = {
        ...state.localEmployeeQL,
        [role]: localEmp
      };
    },
    updateTotalEmp(state, action) {
      const { totalEmp, role } = action.payload;
      state.totalEmployee = {
        ...state.totalEmployee,
        [role]: totalEmp
      };
    },
    addEmpProductMulti(state, action) {
      state.employees = {
        ...state.employees,
        [action.payload.id]: {
          ...state.employees[action.payload.id],
          products: {
            // ...state.employees[action.payload.id].products,
            ...action.payload.products
          }
        }
      }
    },
    rmEmpProductMulti(state, action) {
      state.employees = {
        ...state.employees,
        [action.payload.id]: {
          ...state.employees[action.payload.id],
          products: _.omit(
            state.employees[action.payload.id].products,
            action.payload.products
          )
        }
      }
    }
  }
});

export const reducer = slice.reducer;

export const getEmployees = () => async (dispatch) => {
  const snap = await employeesRef.orderBy('name').get();
  const emp = [];
  snap.forEach((doc) => {
    emp.push(transformDate(doc.data()));
  });
  dispatch(slice.actions.getEmployees(emp));
};

export const fetchEmployee = (id) => async (dispatch, getState) => {
  const { currentAccessLevel } = getState().user;
  if (currentAccessLevel) {
    const employee = await employeesRef.doc(id).get();
    const response = await employeesRef.doc(id).collection('images').get();

    const data = employee.data();
    data.images = [];

    response.docs.map(snapshot => {
      const imgDoc = snapshot.data();
      data.images.push({
        id: imgDoc.id,
        name: imgDoc.name,
        storageUrl: imgDoc.storageUrl,
        isNew: false
      })
    })

    dispatch(slice.actions.fetchEmployee(transformDate(data)));
  }
};

export const fetchEmployeeSubObj = (id) => async (dispatch, getState) => {
  const user = getState().user.user
  const company = getState().company.company
  const category = await fetchEmployeeSubObjQL(user.token, company.domain, id)

  dispatch(slice.actions.fetchEmpSubObj(category))
}

export const createEmployee = (values, snackbar) => async (dispatch, getState) => {
  const user = getState().user.user
  const company = getState().company

  const newEmployee = {
    ...values,
    // dateOfBirth: epochToDate(values.dateOfBirth),
    photoURL: `http://gravatar.com/avatar/${md5(values.email)}?d=identicon`,
    createdBy: {
      name: user.name,
      avatar: user.avatar,
      id: user.id
    }
  };
  const data = {
    company_id: company.company.domain,
    user: newEmployee
  };

  // add images to employees
  const images = newEmployee.images;
  delete newEmployee.images;
  let variant = "success";
  let message = "Nhân viên đã được tạo";

  try {
    const createEmployee = firebaseFunction.httpsCallable('createEmployee');
    const emp = await createEmployee(data);
    const emp_id = emp.data.employee

    const promisesImgToAdd = [];
    const imageToSaveLst = [];
    for (const image of images) {
      const storageUrl = `private/company/${window.location.hostname}/employees/${emp_id}/images/${image.name}`
      const storageImgRef = storageRef(
        storageUrl
      );
      if (!image.isNew && !image.isDeleted)
        imageToSaveLst.push({
          image: image.image,
          storageUrl: storageUrl,
          name: image.name
        });
      if (image.isNew && !image.isDeleted) {
        promisesImgToAdd.push(storageImgRef.put(image.file));
      }
    }

    const responses = await Promise.all(promisesImgToAdd);

    const imageCollectionPromises = [];
    const currentDate = new Date();
    // let i = 0;
    responses.forEach((response) => {
      const imgDocRef = employeesRef.doc(emp_id).collection('images').doc();
      currentDate.setSeconds(currentDate.getSeconds() + 5);
      imageCollectionPromises.push(
        imgDocRef.set({
          id: imgDocRef.id,
          dateCreated: fromDate(currentDate),
          empId: emp_id,
          storageUrl: response.metadata.fullPath,
          name: response.metadata.fullPath.substr(
            response.metadata.fullPath.indexOf(`${emp_id}/`) + 1 + emp_id.length
          )
        })
      )
    });
    await Promise.all(imageCollectionPromises);
    history.push(`/settings/employees/${emp_id}`);
  }
  catch (e) {
    variant = 'error'
    message = e.message
  }

  snackbar(message, { variant: variant })

  // dispatch(slice.actions.createProduct(transformDate(transformData)));
};

export const updateEmployee = (values, employee, snackbar) => async (dispatch) => {
  // const user = getState().user.user
  // const company = getState().company

  const data = {
    ...values,
    company_id: window.location.hostname,
    employee_id: employee.id
  };
  // if (data.dateOfBirth) {data.dateOfBirth = epochToDate(values.dateOfBirth)}


  console.log("<<<<<<<<<<< IMAGES")
  console.log(data.images)

  // add images to employees
  const images = data.images;
  delete data.images;
  let variant = "success";
  let message = "Nhân viên đã được cập nhật";

  try {
    const updateEmployee = firebaseFunction.httpsCallable('updateEmployee');
    const emp = await updateEmployee(data);
    const emp_id = emp.data.employee

    // const promisesImgToDel = [];
    const promisesImgToAdd = [];
    const imageCollectionPromises = [];
    const imageToSaveLst = [];

    for (const image of images) {
      const storageUrl = `private/company/${window.location.hostname}/employees/${emp_id}/images/${image.name}`
      const storageImgRef = storageRef(
        storageUrl
      );
      if (!image.isNew && image.isDeleted) {
        // promisesImgToDel.push(storageImgRef.delete()); // still keep the data in case need
        const imgDocRef = productRef.doc(id).collection('images').doc(image.id);
        imageCollectionPromises.push(imgDocRef.delete());
      }
      if (!image.isNew && !image.isDeleted)
        imageToSaveLst.push({
          image: image.image,
          storageUrl: storageUrl,
          name: image.name
        });
      if (image.isNew && !image.isDeleted) {
        promisesImgToAdd.push(storageImgRef.put(image.file));
      }
    }

    // await Promise.all(promisesImgToDel);
    const responses = await Promise.all(promisesImgToAdd);

    // const imageCollectionPromises = [];
    const currentDate = new Date();
    // let i = 0;
    responses.forEach((response) => {
      const imgDocRef = employeesRef.doc(emp_id).collection('images').doc();
      currentDate.setSeconds(currentDate.getSeconds() + 5);
      imageCollectionPromises.push(
        imgDocRef.set({
          id: imgDocRef.id,
          dateCreated: fromDate(currentDate),
          empId: emp_id,
          storageUrl: response.metadata.fullPath,
          name: response.metadata.fullPath.substr(
            response.metadata.fullPath.indexOf(`${emp_id}/`) + 1 + emp_id.length
          )
        })
      )
    });
    await Promise.all(imageCollectionPromises);
  }
  catch (e) {
    variant = 'error'
    message = e.message
  }

  snackbar(message, { variant: variant })
};

export const addEmpProductMulti = (products, emp_id) => async (dispatch) => {
  const cat = employeesRef.doc(emp_id)
  // const map_product_firestore = _.mapKeys(products, (o) => `products.${o.id}`)
  const map_product = _.mapKeys(products, 'id')

  await cat.update({ products: map_product })

  dispatch(slice.actions.addEmpProductMulti({ id: emp_id, products: map_product }))
}

export const rmEmpProductMulti = (products, emp_id) => async (dispatch) => {
  const batch = db().batch();
  const cat = employeesRef.doc(emp_id)
  for (const p of products) {
    batch.update(cat, { [`products.${p}`]: db.FieldValue.delete() })
  }
  await batch.commit();
  dispatch(slice.actions.rmEmpProductMulti({ id: emp_id, products }))
}

export const openModal = () => (dispatch) => {
  dispatch(slice.actions.openModal());
};

export const closeModal = () => (dispatch) => {
  dispatch(slice.actions.closeModal());
};

export const deleteEmployee = (id) => async (dispatch, getState) => {
  const { currentAccessLevel } = getState().user
  await employeesRef.doc(id).delete();

  dispatch(slice.actions.deleteEmployee(id));
  dispatch(slice.actions.deleteLocalEmployee({ id, role: currentAccessLevel }));
};

/// GRAPH DB ///////
const fetchTotalEmpFromDB = async (employeeId, accessLevel, searchField, employeeIdLst, timeRange) => {
  const user = store.getState().user.user;
  const company = store.getState().company.company;
  const client = apolloClient({
    authorization: `Bearer ${user.token}`,
    database: company.domain
  });
  const response = await client.query({
    fetchPolicy: 'network-only',
    query: gql`
      query fetchEmployeeByRole($employeeId: String, $accessLevel: String, 
        $searchField: String, $startTimeRange: Float, $endTimeRange: Float) {
        fetchEmployeeByRole(
          employeeId: $employeeId
          accessLevel: $accessLevel,
          searchField: $searchField,
          startTimeRange: $startTimeRange,
          endTimeRange: $endTimeRange
        )
      }
    `,
    variables: {
      employeeId: employeeId,
      accessLevel,
      searchField: searchField,
      employeeIdLst: employeeIdLst,
      startTimeRange: timeRange.startTime,
      endTimeRange: timeRange.endTime
    }
  });
  return response.data.fetchEmployeeByRole;
}


export const fetchToTalEmpGraph = (
  employeeId, accessLevel, _, searchField = null,
  setLoading, employeeIdLst = null, __,
  timeRange = { startTime: null, endTime: null }
) => async (dispatch, getState) => {
  setLoading(true);
  let data = await fetchTotalEmpFromDB(
    employeeId, accessLevel, searchField, employeeIdLst, timeRange)
  dispatch(slice.actions.updateTotalEmp({ totalEmp: data, role: accessLevel }));
  setLoading(false);
  return data
};

const fetchEmpFromDB = async (employeeId, accessLevel, sort, limit, skip, getState, searchField, employeeIdLst, timeRange) => {
  let [field, order] = sort.split('|');
  const user = getState().user.user;
  const company = getState().company.company;
  const client = apolloClient({
    authorization: `Bearer ${user.token}`,
    database: company.domain
  });
  const response = await client.query({
    fetchPolicy: 'network-only',
    query: gql`
      query fetchEmployee($employeeId: String, $accessLevel: String, 
        $propName: String, $limit: Int!, $skip: Int!, 
        $searchField: String, $startTimeRange: Float, $endTimeRange: Float){
        ${order === 'desc' ? 'fetchEmployeeByRoleDesc' : 'fetchEmployeeByRoleAsc'}
        (
        employeeId: $employeeId,
        accessLevel: $accessLevel,
        propName: $propName,
        limit: $limit,
        skip: $skip,
        searchField: $searchField,
        startTimeRange: $startTimeRange,
        endTimeRange: $endTimeRange
        ){
        id
        dateCreated
        name
        email
        phoneNumber
        employeeCode
      }
      }
    `,
    variables: {
      employeeId: employeeId,
      accessLevel,
      skip: skip,
      limit: limit,
      propName: field,
      searchField: searchField,
      employeeIdLst: employeeIdLst,
      startTimeRange: timeRange.startTime,
      endTimeRange: timeRange.endTime
    }
  });
  return response;
};


export const fetchEmpGraph = (
  employeeId, accessLevel, sort, _, searchField = null,
  limit, skip, listField, setLoading, fromSort = false,
  employeeIdLst = null, __,
  timeRange = { startTime: null, endTime: null }
) => async (dispatch, getState) => {
  setLoading(true);

  let [field, order] = sort.split('|');

  const role = getState().user.currentAccessLevel;

  const response = await fetchEmpFromDB(employeeId, accessLevel, sort, limit, skip, getState, searchField, employeeIdLst, timeRange);

  let data = order === 'desc' ? response.data.fetchEmployeeByRoleDesc : response.data.fetchEmployeeByRoleAsc;

  if (fromSort) {
    const arr = data.slice().sort(getComparator(order, field, listField));
    await dispatch(
      slice.actions.updateLocalEmp({ localEmp: arr, role: role })
    );
  } else {
    let arr = getState().employees.localEmployeeQL[role] ?? []
    arr = arr.slice();
    data.forEach((ele) => {
      let have = arr.find((x) => x.id === ele.id);
      if (!have) {
        arr.push(ele);
      }
    });
    arr.sort(getComparator(order, field, listField));
    await dispatch(
      slice.actions.updateLocalEmp({ localEmp: arr, role: role })
    );
  }

  setLoading(false);
};

export const updateEmpGraph = (
  employeeId, accessLevel, sort, _,
  searchField = null, limit, page,
  listField, employeeIdLst = null, __,
  timeRange = { startTime: null, endTime: null }
) => async (dispatch, getState) => {
  const [field, order] = sort.split('|');
  const skip = page * limit;

  const accessLevel = getState().user.currentAccessLevel;
  const response = await fetchEmpFromDB(
    employeeId, accessLevel, sort, limit, skip,
    getState, searchField, employeeIdLst, timeRange);

  let data = order === 'desc'
    ? response.data.fetchEmployeeByRoleDesc
    : response.data.fetchEmployeeByRoleAsc;

  let arr = getState().employees.localEmployeeQL[accessLevel] ?? []
  arr = arr.slice();

  data.forEach((ele) => {
    let idx = arr.findIndex((x) => x.id === ele.id);
    if (idx > -1) {
      arr[idx] = ele;
    } else {
      arr.push(ele);
    }
  });

  arr.sort(getComparator(order, field, listField));
  dispatch(slice.actions.updateLocalEmp({ localEmp: arr, role: accessLevel }));
};

export default slice;
