import ReplayIcon from '@mui/icons-material/Replay';
import {IconButton} from '@mui/material';
import firebase, {firebaseFunction} from 'src/lib/firebase';
import {updateBlogCacheById} from 'src/store/slices/blog';
import {updateSaleOrderCacheById} from 'src/store/slices/saleorder';
import history from '../history';
import store from '../store/index';

export const db = firebase.firestore;

export const branchesRef = firebase
  .firestore()
  .collection('company')
  .doc(window.location.hostname)
  .collection('branches');

export const companyRef = firebase
  .firestore()
  .collection('company')
  .doc(window.location.hostname);

export const paymentMethodsRef = firebase
  .firestore()
  .collection('company')
  .doc(window.location.hostname)
  .collection('payment-methods');

export const defaultTextRef = firebase
  .firestore()
  .collection('company')
  .doc(window.location.hostname)
  .collection('default-text')
  .doc('texts');

export const customersRef = firebase
  .firestore()
  .collection('company')
  .doc(window.location.hostname)
  .collection('customers');

export const rolesRef = firebase
  .firestore()
  .collection('company')
  .doc(window.location.hostname)
  .collection('roles');

export const warehousesRef = firebase
  .firestore()
  .collection('company')
  .doc(window.location.hostname)
  .collection('warehouses');

export const employeesRef = firebase
  .firestore()
  .collection('company')
  .doc(window.location.hostname)
  .collection('employees');

export const suppliersRef = firebase
  .firestore()
  .collection('company')
  .doc(window.location.hostname)
  .collection('suppliers');

export const teamsRef = firebase
  .firestore()
  .collection('company')
  .doc(window.location.hostname)
  .collection('teams');

export const promotionRef = firebase
  .firestore()
  .collection('company')
  .doc(window.location.hostname)
  .collection('promotions');

export const roleRef = firebase
  .firestore()
  .collection('company')
  .doc(window.location.hostname)
  .collection('roles');

export const productRef = firebase
  .firestore()
  .collection('company')
  .doc(window.location.hostname)
  .collection('products');

export const categoryRef = firebase
  .firestore()
  .collection('company')
  .doc(window.location.hostname)
  .collection('categories');

export const lotsRef = (productId) =>
  firebase
    .firestore()
    .collection('company')
    .doc(window.location.hostname)
    .collection('products')
    .doc(productId)
    .collection('lots');

export const productLocationsRef = (productId) =>
  firebase
    .firestore()
    .collection('company')
    .doc(window.location.hostname)
    .collection('products')
    .doc(productId)
    .collection('product-locations');

export const ordersRef = firebase
  .firestore()
  .collection('company')
  .doc(window.location.hostname)
  .collection('sales-orders');

export const quotesRef = firebase
  .firestore()
  .collection('company')
  .doc(window.location.hostname)
  .collection('quotes');

export const provincesRef = firebase
  .firestore()
  .collection('geography')
  .doc('vietnam');

export const receiveNoteRef = firebase
  .firestore()
  .collection('company')
  .doc(window.location.hostname)
  .collection('receive-note');

export const serverTime = firebase.firestore.FieldValue.serverTimestamp;
export const fromDate = firebase.firestore.Timestamp.fromDate;

export const companySalesAnalyticsRef = firebase
  .firestore()
  .collection('company')
  .doc(window.location.hostname)
  .collection('analytics')
  .doc('salesSummary');

export const mySalesAnalyticsRef = (id) =>
  firebase
    .firestore()
    .collection('company')
    .doc(window.location.hostname)
    .collection('employees')
    .doc(id)
    .collection('analytics')
    .doc('salesSummary');

export const companyCustomersAnalyticsRef = firebase
  .firestore()
  .collection('company')
  .doc(window.location.hostname)
  .collection('analytics')
  .doc('customerSummary');

export const myCustomersAnalyticsRef = (id) =>
  firebase
    .firestore()
    .collection('company')
    .doc(window.location.hostname)
    .collection('employees')
    .doc(id)
    .collection('analytics')
    .doc('customerSummary');

export const employeesCacheRef = firebase
  .firestore()
  .collection('company')
  .doc(window.location.hostname)
  .collection('cache_data')
  .doc('employees_dropdown');

export const customersCacheRef = firebase
  .firestore()
  .collection('company')
  .doc(window.location.hostname)
  .collection('cache_data')
  .doc('customers_dropdown');

export const productsCacheRef = firebase
  .firestore()
  .collection('company')
  .doc(window.location.hostname)
  .collection('cache_data')
  .doc('products_dropdown');

export const quoteCacheRef = (id) => {
  return firebase
    .firestore()
    .collection('company')
    .doc(window.location.hostname)
    .collection('quotes')
    .doc(id);
};

export const quoteRef = firebase
  .firestore()
  .collection('company')
  .doc(window.location.hostname)
  .collection('quotes');

export const saleOrderCacheRef = (id) => {
  return firebase
    .firestore()
    .collection('company')
    .doc(window.location.hostname)
    .collection('sales-orders')
    .doc(id);
};

export const notificationRef = (id) => {
  return firebase
    .firestore()
    .collection('company')
    .doc(window.location.hostname)
    .collection('employees')
    .doc(id);
};

export const adjustmentRef = firebase
  .firestore()
  .collection('company')
  .doc(window.location.hostname)
  .collection('inventory-adjustments');

export const createNoti = (userId, data) => {
  const notiRef = firebase
    .firestore()
    .collection('company')
    .doc(window.location.hostname)
    .collection('employees')
    .doc(userId)
    .collection('notifications')
    .doc();
  const id = notiRef.id;
  let autoNumRef = firebase
    .firestore()
    .collection('company')
    .doc(window.location.hostname)
    .collection('employees')
    .doc(userId)
    .collection('notifications')
    .doc('metaData');
  autoNumRef.get().then((orderId) => {
    autoNumRef.update({
      order: firebase.firestore.FieldValue.increment(1)
    });
    notiRef.set({
      ...data,
      id,
      order: orderId.data().order,
      createdAt: firebase.firestore.Timestamp.fromMillis(data.createdAt)
    });
  });
};

export const markNotiAsRead = (userId, notiList) => {
  let batch = firebase.firestore().batch();
  notiList.map((noti) => {
    //if (!noti.unread) return
    let notiRef = firebase
      .firestore()
      .collection('company')
      .doc(window.location.hostname)
      .collection('employees')
      .doc(userId)
      .collection('notifications')
      .doc(noti.id);
    batch.update(notiRef, {unread: false});
  });
  batch.commit();
};

export const createPdfAuth = (data) => {
  return firebaseFunction.httpsCallable('createPdfAuth')(data);
};

export const createOrderFromQuote = (data) => {
  return firebaseFunction.httpsCallable('createOrderFromQuote')(data);
};

export const exportListData = (data) => {
  data['companyId'] = window.location.hostname;
  return firebaseFunction.httpsCallable('exportListData')(data);
};

export const createQuote = (companyName, qName, quote) => {
  let dbRef = firebase
    .firestore()
    .collection('company')
    .doc(companyName)
    .collection('quotes');
  let order = {...quote};
  order.dateCreated = firebase.firestore.Timestamp.fromMillis(
    order.dateCreated * 1000
  );
  order.dateModified = firebase.firestore.Timestamp.fromMillis(
    order.dateModified * 1000
  );
  if (qName === '') {
    const qRef = dbRef.doc();
    const id = qRef.id;
    qRef
      .set({...order, id})
      .then(() => {
        history.push(`/management/quotes/${id}`);
      })
      .catch((er) => alert('Có lỗi đã xảy ra: ' + er));
  } else {
    dbRef
      .doc(qName)
      .set(order)
      .then(() => {
        history.push(`/management/quotes/${qName}`);
      })
      .catch((er) => alert('Có lỗi đã xảy ra: ' + er));
  }
};

export const createSO = async (companyName, soName, saleOrder, images, enqueueSnackbar = null) => {
  let dbRef = firebase.firestore()
    .collection('company').doc(companyName)
    .collection('sales-orders');

  let order = {...saleOrder};
  order.dateCreated = firebase.firestore.Timestamp.fromMillis(
    order.dateCreated * 1000
  );
  order.dateModified = firebase.firestore.Timestamp.fromMillis(
    order.dateModified * 1000
  );

  let soID = soName;
  let soRef = null;
  if (soName === '') {
    soRef = dbRef.doc();
    soID = soRef.id;
  }

  db().runTransaction(async (transaction) => {
    const remoteOrder = await transaction.get(dbRef.doc(soID));

    let remoteVersion = remoteOrder.exists && remoteOrder.data().version ? remoteOrder.data().version : 0;
    if (remoteVersion !== order.version) throw "ERR_INVALID_ORDER";
    order.version = remoteVersion + 1;
    saleOrder.version = order.version;
    if (soName === '') {
      let autoNumRef = firebase.firestore().collection('company').doc(window.location.hostname)
        .collection('formnumber').doc('autonumber');
      const autoDoc = await transaction.get(autoNumRef);
      if (!autoDoc.exists) throw "ERR_AUTONUM";
      order.formNumber = autoDoc.data().SO;
      saleOrder.formNumber = order.formNumber;

      await Promise.all([
        transaction.set(soRef, {...order, id: soID}),
        transaction.update(autoNumRef, {SO: order.formNumber + 1})
      ])
    } else {
      await transaction.update(dbRef.doc(soID), {...order})
    }
  }).then(async () => {
    const promisesImgToAdd = [];
    const promisesImgToDel = [];
    const imageCollectionPromises = [];
    const newImgInfo = [];
    const updatedOrder = {...saleOrder};
    updatedOrder.images = {};

    for (let index in images) {
      const storageRef = firebase
        .storage()
        .ref()
        .child(
          `private/company/${window.location.hostname}/sales-orders/${soID}/${images[index].name}`
        );
      if (!images[index].isNew && images[index].isDeleted) {
        promisesImgToDel.push(storageRef.delete());
        const imgDocRef = dbRef
          .doc(soID)
          .collection('images')
          .doc(images[index].id);
        imageCollectionPromises.push(imgDocRef.delete());
      }
      if (!images[index].isNew && !images[index].isDeleted) {
        updatedOrder.images[index] = images[index];
        const imgDocRef = dbRef
          .doc(soID)
          .collection('images')
          .doc(images[index].id);
        imageCollectionPromises.push(
          imgDocRef.update({customerId: order.customer.id})
        );
      }
      if (images[index].isNew && !images[index].isDeleted)
        promisesImgToAdd.push(storageRef.put(images[index].file));
    }

    const responsesAddImg = await Promise.all(promisesImgToAdd);
    await Promise.all(promisesImgToDel);

    responsesAddImg.forEach((response) => {
      const imgDocRef = dbRef.doc(soID).collection('images').doc();
      imageCollectionPromises.push(
        imgDocRef.set({
          id: imgDocRef.id,
          customerId: order.customer.id,
          dateCreated: order.dateCreated,
          soId: soID,
          storageUrl: response.metadata.fullPath,
          imgName: response.metadata.fullPath.substr(
            response.metadata.fullPath.indexOf(`${soID}/`) + 1 + soID.length
          )
        })
      );
      newImgInfo.push({
        id: imgDocRef.id,
        storageUrl: response.metadata.fullPath,
        name: response.metadata.fullPath.substr(
          response.metadata.fullPath.indexOf(`${soID}/`) + 1 + soID.length
        ),
        isNew: false
      });
    });

    await Promise.all(imageCollectionPromises);
    const newImgInfoWithLink = await Promise.all(
      newImgInfo.map(async (ele) => ({
        ...ele,
        image: await firebase.storage().ref(ele.storageUrl).getDownloadURL()
      }))
    );
    for (const img of newImgInfoWithLink) updatedOrder.images[img.id] = img;
    store.dispatch(updateSaleOrderCacheById(updatedOrder));

    history.push(`/management/orders/${soID}`);
  }).catch(er => {
    console.log(er)
    if (enqueueSnackbar) {
      let msg = 'Có lỗi xảy ra vui lòng thử lại';
      let obj = {variant: 'warning'};
      if (er === "ERR_INVALID_ORDER") {
        msg = 'Đơn hàng đã bị chỉnh sửa, vui lòng tải lại trang';
        obj.action = (key) => (
          <IconButton
            onClick={() => window.location.reload()}
          >
            <ReplayIcon sx={{color: 'white'}}/>
          </IconButton>
        )
      }
      enqueueSnackbar(msg, obj);
    }
  })

};

export const getProductInfoById = async (id) => {
  return await firebase
    .firestore()
    .collection('company')
    .doc(window.location.hostname)
    .collection('products')
    .doc(id)
    .get();
};
export const addProduct = () => {
  return firebase
    .firestore()
    .collection('company')
    .doc(window.location.hostname)
    .collection('products');
};

export const addProductCache = () => {
  return firebase
    .firestore()
    .collection('company')
    .doc(window.location.hostname)
    .collection('cache_data')
    .doc('products_dropdown');
};

export const getDeliveryNoteBySoID = (soid) => {
  return firebase
    .firestore()
    .collection('company')
    .doc(window.location.hostname)
    .collection('sales-orders')
    .doc(soid)
    .collection('delivery-notes')
    .get();
};

export const deliveryAutoNumRef = (soid) =>
  firebase
    .firestore()
    .collection('company')
    .doc(window.location.hostname)
    .collection('sales-orders')
    .doc(soid)
    .collection('delivery-formnumber')
    .doc('autonumber');

export const receiveAutoNumRefById = (id) =>
  firebase
    .firestore()
    .collection('company')
    .doc(window.location.hostname)
    .collection('receive-note')
    .doc(id)
    .collection('receive-formnumber')
    .doc('autonumber');

export const receiveAutoNumRef = firebase
  .firestore()
  .collection('company')
  .doc(window.location.hostname)
  .collection('formnumber')
  .doc('autonumber');

export const getReceiveNoteByID = (id) => {
  return firebase
    .firestore()
    .collection('company')
    .doc(window.location.hostname)
    .collection('receive-note')
    .doc(id)
    .collection('receives')
    .get();
};

export const createDeliveryNote = (deliveryInfo, soid) => {
  let dbRef = firebase
    .firestore()
    .collection('company')
    .doc(window.location.hostname)
    .collection('sales-orders')
    .doc(soid)
    .collection('delivery-notes');

  let deliveryNote = {...deliveryInfo};
  //deliveryNote.dateCreated = firebase.firestore.Timestamp.fromMillis(deliveryNote.dateCreated * 1000)
  const soRef = dbRef.doc();
  const id = soRef.id;
  soRef
    .set({...deliveryNote, id})
    .then(() => {
      history.push(`/management/orders/${soid}`);
    })
    .catch((er) => alert('Có lỗi đã xảy ra: ' + er));
};

export const createReceiveNoteIn = async (
  receiveInfo,
  rnid,
  setProcessingNote
) => {
  let dbRef = firebase
    .firestore()
    .collection('company')
    .doc(window.location.hostname)
    .collection('receive-note')
    .doc(rnid)
    .collection('receives');

  let receiveNote = {...receiveInfo};
  const rnRef = dbRef.doc();
  const id = rnRef.id;
  try {
    await rnRef.set({...receiveNote, id});
    // history.push(`/management/receive-notes`);
    setTimeout(() => {
      document.location.reload();
      setProcessingNote(false);
    }, 2000);
  } catch (er) {
    alert('Có lỗi đã xảy ra: ' + er);
  }
};

export const logoCompanyStorageRef = () =>
  firebase.storage().ref().child(`company/${window.location.hostname}/logo`);
export const editCompanyInfo = (info) => {
  return companyRef.set(
    {
      ...info,
      dateModified: firebase.firestore.Timestamp.fromMillis(Date.now())
    },
    {merge: true}
  );
};

export const receiveNoteIDRef = (id) => {
  return firebase
    .firestore()
    .collection('company')
    .doc(window.location.hostname)
    .collection('receive-note')
    .doc(id);
};

export const customerIDRef = (id) => {
  return firebase
    .firestore()
    .collection('company')
    .doc(window.location.hostname)
    .collection('customers')
    .doc(id);
};

export const createRN = (companyName, RNName, receiveNote) => {
  let dbRef = firebase
    .firestore()
    .collection('company')
    .doc(companyName)
    .collection('receive-note');
  let pushRN = {...receiveNote};
  if (RNName === '') {
    pushRN.dateCreated = firebase.firestore.Timestamp.fromMillis(
      pushRN.dateCreated * 1000
    );
    pushRN.dateModified = firebase.firestore.Timestamp.fromMillis(
      pushRN.dateModified * 1000
    );
  } else {
    pushRN.dateCreated = firebase.firestore.Timestamp.fromMillis(
      pushRN.dateCreated
    );
    pushRN.dateModified = firebase.firestore.Timestamp.fromMillis(
      pushRN.dateModified * 1000
    );
  }

  if (RNName === '') {
    const RNRef = dbRef.doc();
    const id = RNRef.id;
    RNRef.set({...pushRN, id})
      .then(() => {
        // history.push(`/management/orders/${id}`);
        history.push(`/management/receive-notes`);
      })
      .catch((er) => alert('Có lỗi đã xảy ra: ' + er));
  } else {
    dbRef
      .doc(RNName)
      .set(pushRN)
      .then(() => {
        // history.push(`/management/orders/${RNName}`);
        history.push(`/management/receive-notes`);
      })
      .catch((er) => alert('Có lỗi đã xảy ra: ' + er));
  }
};
export const getImageDownloadURL = (ref) =>
  firebase.storage().ref(ref).getDownloadURL();
export const storageRef = (ref) => firebase.storage().ref().child(ref);

export const createBlog = async (blogContent, blogName, images, blogId, blogOtherInfo, blogThumnail, blogPages) => {
  const domain = store.getState().company.company.domain;

  let dbRef = firebase.firestore()
    .collection('company').doc(domain)
    .collection('blogs');

  try {
    let id = "";
    let blogRef = "";
    const otherInfo = {...blogOtherInfo};
    otherInfo.dateCreated = firebase.firestore.Timestamp.fromMillis(otherInfo.dateCreated);
    otherInfo.dateModified = firebase.firestore.Timestamp.fromMillis(otherInfo.dateModified);

    if (blogId !== null && blogId !== undefined) {
      id = blogId;
      blogRef = dbRef.doc(id);
    } else {
      blogRef = dbRef.doc();
      id = blogRef.id;
    }

    const newImgPromises = [];

    Object.values(images).map(ele => {
      if (blogContent.indexOf(ele.data) === -1) return;

      const storageRef = firebase.storage().ref()
        .child(`public/company/${window.location.hostname}/blogs/${ele.file.name}`);
      newImgPromises.push(storageRef.put(ele.file));
    });

    const newImgResponses = await Promise.all(newImgPromises);

    const blogImgPromises = [];
    newImgResponses.forEach((response) => {
      const blogImgDocRef = imgRef(domain).doc();
      const imgPath = response.metadata.fullPath;
      const imgName = response.metadata.fullPath.substr(response.metadata.fullPath.indexOf(`blogs/`) + "blogs/".length);
      const timestamp = serverTime()

      blogImgPromises.push(
        blogImgDocRef.set({
          dateCreated: timestamp,
          id: blogImgDocRef.id,
          name: imgName,
          storageUrl: imgPath,
          type: "BLOG",
          blogId: id,
        })
      );
    });

    await Promise.all(blogImgPromises);

    const urlResponses = await Promise.all(newImgResponses.map(async (response) => {
      const imgPath = response.metadata.fullPath;
      const imgName = response.metadata.fullPath.substr(response.metadata.fullPath.indexOf(`blogs/`) + "blogs/".length);
      const imgUrl = await firebase.storage().ref(imgPath).getDownloadURL();
      return ({
        ...images[imgName],
        url: imgUrl
      })
    }));

    for (const ele of urlResponses) {
      blogContent = blogContent.replace(ele.data, ele.url)
    }

    const blog = {title: blogName, content: blogContent, id: id, thumnail: '', webpages: blogPages};

    if (blogThumnail.prevImg.firebasePath !== '') {
      await firebase.storage().ref().child(blogThumnail.prevImg.firebasePath).delete();
    }


    if (blogThumnail.curImg.image !== '') {
      if (blogThumnail.curImg.firebasePath === '') {
        const ele = blogThumnail.curImg;
        const thumnailRef = firebase.storage().ref().child(`public/company/${window.location.hostname}/blogs/${ele.file.name}`);
        const thumnailResponse = await thumnailRef.put(ele.file);

        const imgPath = thumnailResponse.metadata.fullPath;
        blog.thumnail = imgPath;
      } else {
        blog.thumnail = blogThumnail.curImg.firebasePath;
      }
    }

    await blogRef.set({...blog, ...otherInfo});
    if (blog.thumnail === '') {
      blog.thumnailUrl = '';
    } else {
      blog.thumnailUrl = blogThumnail.curImg.image;
    }
    store.dispatch(updateBlogCacheById({...blog, ...blogOtherInfo}));

    history.push(`/management/blogs/${id}`);
  } catch (er) {
    console.error(er);
    alert('Có lỗi đã xảy ra, vui lòng thử lại');
  }
};

export const imgRef = (domain) => firebase
  .firestore()
  .collection('company').doc(domain)
  .collection('images')

export const webpagesRef = (domain) => firebase
  .firestore()
  .collection('company')
  .doc(domain)
  .collection('web-pages')

export const createWebpage = (webpage) => {
  return webpagesRef(window.location.hostname).doc(webpage.hostName).set(
    webpage,
    { merge: true }
  );
};

export const blogRef = (domain, id) => firebase.firestore()
  .collection('company').doc(domain)
  .collection('blogs').doc(id)

export const promoRef = (id) => firebase.firestore()
  .collection('company').doc(window.location.hostname)
  .collection('promotions').doc(id)

const queries = {
  ADMIN: {
    branchRef: (id) => branchesRef.doc(id),
    branchesRef: () => branchesRef,
    branchesCreateRef: () => branchesRef,
    customersRef: () => customersRef,
    customerCreateRef: () => customersRef,
    warehouseCreateRef: () => warehousesRef,
    warehouseRef: (id) => warehousesRef.doc(id),
    supplierRef: (id) => suppliersRef.doc(id),
    supplierCreateRef: () => suppliersRef,
    customerRef: (id) => customersRef.doc(id),
    teamsCreateRef: () => teamsRef,
    teamsRef: () => teamsRef,
    teamRef: (id) => teamsRef.doc(id),
    promotionsCreateRef: () => promotionRef,
    promotionsRef: () => promotionRef,
    promotionRef: (id) => promotionRef.doc(id),
    rolesCreateRef: () => roleRef,
    rolesRef: () => roleRef,
    roleRef: (id) => roleRef.doc(id),
    employeesRef: '',
    ordersRef: ordersRef
  },
  CEO: {
    branchRef: (id) => branchesRef.doc(id),
    branchesRef: () => branchesRef,
    branchesCreateRef: () => branchesRef,
    customersRef: () => customersRef,
    customerCreateRef: () => customersRef,
    warehouseCreateRef: () => warehousesRef,
    warehouseRef: (id) => warehousesRef.doc(id),
    supplierRef: (id) => suppliersRef.doc(id),
    supplierCreateRef: () => suppliersRef,
    customerRef: (id) => customersRef.doc(id),
    teamsCreateRef: () => teamsRef,
    teamsRef: () => teamsRef,
    teamRef: (id) => teamsRef.doc(id),
    promotionsCreateRef: () => promotionRef,
    promotionsRef: () => promotionRef,
    promotionRef: (id) => promotionRef.doc(id),
    rolesCreateRef: () => roleRef,
    rolesRef: () => roleRef,
    roleRef: (id) => roleRef.doc(id),
    employeesRef: '',
    ordersRef: ordersRef
  },
  SALES_MANAGER: {
    // Can access customer list of my sales team
    branchRef: (id) => branchesRef.doc(id),
    branchesRef: () => branchesRef,
    branchesCreateRef: () => branchesRef,
    customersRef: () => customersRef,
    warehouseRef: (id) => warehousesRef.doc(id),
    supplierRef: (id) => suppliersRef.doc(id),
    supplierCreateRef: () => suppliersRef,
    customerCreateRef: () => customersRef,
    customerRef: (id) => customersRef.doc(id),
    teamsCreateRef: () => teamsRef,
    teamsRef: () => teamsRef,
    teamRef: (id) => teamsRef.doc(id),
    promotionsCreateRef: () => promotionRef,
    promotionsRef: () => promotionRef,
    promotionRef: (id) => promotionRef.doc(id),
    employeesRef: '',
    ordersRef: ordersRef // Can access customer list of my sales team
  },
  DOCTOR_MANAGER: {
    customersRef: (myTeamIds) =>
      customersRef.where('doctors', 'array-contains-any', myTeamIds),
    customerCreateRef: () => customersRef,
    customerRef: (id) => customersRef.doc(id),
    warehouseRef: (id) => warehousesRef.doc(id),
    supplierRef: (id) => suppliersRef.doc(id),
    supplierCreateRef: () => suppliersRef,
    teamsCreateRef: () => teamsRef,
    teamsRef: () => teamsRef,
    teamRef: (id) => teamsRef.doc(id),
    employeesRef: '',
    ordersRef: ordersRef
  },
  DOCTOR: {
    branchRef: (id) => branchesRef.doc(id),
    branchesRef: () => branchesRef,
    branchesCreateRef: () => branchesRef,
    customersRef: (userId) =>
      customersRef.where('doctors', 'array-contains', userId),
    customerRef: (id) => customersRef.doc(id),
    warehouseRef: (id) => warehousesRef.doc(id),
    supplierRef: (id) => suppliersRef.doc(id),
    supplierCreateRef: () => suppliersRef,
    teamsCreateRef: () => teamsRef,
    teamsRef: () => teamsRef,
    teamRef: (id) => teamsRef.doc(id),
    employeesRef: '',
    ordersRef: ordersRef
  },
  SALES_REP: {
    customersRef: (userId) =>
      customersRef.where('sales', 'array-contains', userId),
    customerCreateRef: () => customersRef,
    customerRef: (id) => customersRef.doc(id),
    warehouseRef: (id) => warehousesRef.doc(id),
    supplierRef: (id) => suppliersRef.doc(id),
    supplierCreateRef: () => suppliersRef,
    promotionsCreateRef: () => promotionRef,
    promotionsRef: () => promotionRef,
    promotionRef: (id) => promotionRef.doc(id),
    employeesRef: '',
    ordersRef: ordersRef
  },
  COLLABORATOR: {
    customersRef: () => {
    },
    customerRef: (id) => customersRef.doc(id),
    employeesRef: '',
    ordersRef: ordersRef
  }
};

export default queries;
