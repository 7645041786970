import { createSlice } from '@reduxjs/toolkit';
import { notificationRef } from '../../rolesbased-rules/rolesBasedQuery';

const initialState = {
  notifications: [],
  cntUnread: 0
};

const slice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    setNotifications(state, action) {
      const data = action.payload;
      state.notifications = state.notifications.concat(data);
    },
    setCntUnread(state, action) {
      const data = action.payload;
      state.cntUnread = data;
    }
  }
});

export const reducer = slice.reducer;

export const setNotificationsInit = (id, limit) => (dispatch) => {
  notificationRef(id)
    .collection('notifications')
    .where('unread', '==', true)
    .orderBy('order', 'desc')
    .limit(limit)
    .get()
    .then((response) => {
      let data = [];
      response.forEach((doc) => {
        let noti = doc.data();
        noti.createdAt =
          noti.createdAt.seconds + noti.createdAt.nanoseconds / 1000000000;
        data.push(noti);
      });
      data.sort(function (a, b) {
        return b.order - a.order;
      });
      dispatch(slice.actions.setNotifications(data));
    });
};
export const setNotifications = (id, limit, startAt) => (dispatch) => {
  notificationRef(id)
    .collection('notifications')
    .where('unread', '==', true)
    .orderBy('order', 'desc')
    .limit(limit)
    .startAfter(startAt)
    .get()
    .then((response) => {
      let data = [];
      response.forEach((doc) => {
        let noti = doc.data();
        noti.createdAt =
          noti.createdAt.seconds + noti.createdAt.nanoseconds / 1000000000;
        data.push(noti);
      });
      data.sort(function (a, b) {
        return b.order - a.order;
      });
      dispatch(slice.actions.setNotifications(data));
    });
};
export const setCntUnread = (id) => (dispatch, getState) => {
  return notificationRef(id)
    .collection('notifications')
    .doc('metaData')
    .onSnapshot((doc) => {
      if (doc.data()) {
        dispatch(slice.actions.setCntUnread(doc.data().cntUnread));
      }
    });
};

export default slice;
