import { createSlice } from '@reduxjs/toolkit';
import { adjustmentRef } from '../../rolesbased-rules/rolesBasedQuery';
import _ from 'lodash';
import { transformDate } from '../../utils/formater';
import store from '../index'
import { apolloClient } from '../../contexts/graphqlContext'
import { gql } from '@apollo/client'
import { getComparator } from '../../utils/queryUtils'

const initialState = {
  adjustments: {},
  totalAdjustment: {
    CEO: -1,
    ADMIN: -1,
    WAREHOUSE_MANAGER: -1,
    SALES_REP: -1,
    SALES_MANAGER: -1,
    DOCTOR_MANAGER: -1,
    DOCTOR: -1
  },
  localAdjustmentQL: {
  },
};

const slice = createSlice({
  name: 'adjustments',
  initialState,
  reducers: {
    getAdjustments(state, action) {
      state.adjustments = {
        ...state.adjustments,
        ..._.mapKeys(action.payload, 'id')
      };
    },
    // for graphQL
    updateLocalAdjustment(state, action) {
      const { localAdjustmentQL, role } = action.payload;
      state.localAdjustmentQL = {
        ...state.localAdjustmentQL,
        [role]: localAdjustmentQL
      };
    },
    updateTotalAdjustment(state, action) {
      const { totalAdjustment, role } = action.payload;
      state.totalAdjustment = {
        ...state.totalAdjustment,
        [role]: totalAdjustment
      };
    }
  }
});

export const reducer = slice.reducer;

export const getAdjustments = () => async (dispatch) => {
  const snap = await adjustmentRef.get();
  const adj = [];
  snap.forEach((doc) => {
    adj.push(transformDate(doc.data()));
  });
  console.log(adj);
  dispatch(slice.actions.getAdjustments(adj));
};


////// FOR GRAPH DB //////////
const fetchTotalAdjustmentFromDB = async (employeeId, accessLevel, searchField) => {
  const user = store.getState().user.user;
  const company = store.getState().company.company;
  const client = apolloClient({
    authorization: `Bearer ${user.token}`,
    database: company.domain
  });
  const response = await client.query({
    fetchPolicy: 'network-only',
    query: gql`
        query fetchTotalAdjustmentByRole($employeeId: String, $accessLevel: String, $searchField: String) {
            fetchTotalAdjustmentByRole(
                employeeId: $employeeId, accessLevel: $accessLevel,  searchField: $searchField
            ){
                status,
                cnt
            }
        }
    `,
    variables: {
      employeeId: employeeId,
      accessLevel,
      searchField: searchField
    }
  });
  return response.data.fetchTotalAdjustmentByRole;
}

export const fetchTotalAdjustmentGraph = (
  employeeId, accessLevel, status, searchField, setLoading
) => async (dispatch, getState) => {
  setLoading(true);
  let data = await fetchTotalAdjustmentFromDB(employeeId, accessLevel, searchField)

  let totalAdjustmentByCurrentRole = -1
  if (status === null) {
    totalAdjustmentByCurrentRole = data.reduce((prev, cur) => prev + cur.cnt, 0)
  } else {
    const totalAdjustmentObjAr = data.filter(ele => ele.status === status)
    if (totalAdjustmentObjAr.length > 0) totalAdjustmentByCurrentRole = totalAdjustmentObjAr[0].cnt
  }

  dispatch(slice.actions.updateTotalAdjustment({ totalAdjustment: totalAdjustmentByCurrentRole, role: accessLevel }))
  setLoading(false)
  return data
};

const fetchAdjustmentFromDB = async (
  employeeId, accessLevel, sort, status, searchField, limit, skip, getState
) => {
  let [field, order] = sort.split('|');
  const user = getState().user.user;
  const company = getState().company.company;
  const client = apolloClient({
    authorization: `Bearer ${user.token}`,
    database: company.domain
  });
  return await client.query({
    fetchPolicy: 'network-only',
    query: gql`
        query fetchAdjustment(
            $employeeId: String, $accessLevel: String, $propName: String,
            $limit: Int!, $searchField: String, $skip: Int!, $status: String
        ){
            ${order === 'desc' ? 'fetchAdjustmentsByRoleDesc' : 'fetchAdjustmentsByRoleAsc'}
            (employeeId: $employeeId, accessLevel: $accessLevel, propName: $propName,
            status: $status, searchField: $searchField, limit: $limit, skip: $skip)
        {
            id
            status
            dateCreated
            reason
            createdBy{name}
            products{id}
        }
        }
    `,
    variables: {
      employeeId: employeeId,
      accessLevel,
      skip: skip,
      limit: limit,
      propName: field,
      quoteStatus: status,
      searchField: searchField
    }
  });
};

export const fetchAdjustmentGraph = (
  employeeId, accessLevel, sort, status, searchField,
  limit, skip, listField, setLoading, fromSort = false
) => async (dispatch, getState) => {
  setLoading(true);
  let [field, order] = sort.split('|');

  const accessLevel = getState().user.currentAccessLevel;

  const response = await fetchAdjustmentFromDB(
    employeeId, accessLevel, sort, status, searchField, limit, skip, getState);

  let data = order === 'desc' ? response.data.fetchAdjustmentsByRoleDesc : response.data.fetchAdjustmentsByRoleAsc;

  if (fromSort) {
    const arr = data.slice().sort(getComparator(order, field, listField));
    await dispatch(slice.actions.updateLocalAdjustment({ localAdjustmentQL: arr, role: accessLevel }));
  } else {
    let arr = getState().adjustments.localAdjustmentQL[accessLevel] ?? []
    arr = arr.slice();
    data.forEach((ele) => {
      let have = arr.find((x) => x.id === ele.id);
      if (!have) {
        arr.push(ele);
      }
    });
    arr.sort(getComparator(order, field, listField));
    await dispatch(slice.actions.updateLocalAdjustment({ localAdjustmentQL: arr, role: accessLevel }));
  }

  setLoading(false);
};

export const updateAdjustmentGraph = (
  employeeId, accessLevel, sort, status, searchField, limit, page, listField
) => async (dispatch, getState) => {
  const [field, order] = sort.split('|');
  const skip = page * limit;

  const role = getState().user.currentAccessLevel;
  const response = await fetchAdjustmentFromDB(
    employeeId, accessLevel, sort, status, searchField, limit, skip, getState
  );

  let data = order === 'desc' ? response.data.fetchAdjustmentsByRoleDesc : response.data.fetchAdjustmentsByRoleAsc;
  let arr = getState().adjustments.localAdjustmentQL[role] ?? []
  arr = arr.slice();

  data.forEach((ele) => {
    let idx = arr.findIndex((x) => x.id === ele.id);
    if (idx > -1) {
      arr[idx] = ele;
    } else {
      arr.push(ele);
    }
  });

  arr.sort(getComparator(order, field, listField));
  dispatch(slice.actions.updateLocalAdjustment({ localAdjustmentQL: arr, role: role }));
};

export default slice;
