import firebase from 'src/lib/firebase';
import moment from 'moment';
import _ from 'lodash';

const fromDate = firebase.firestore.Timestamp.fromDate;

export function convertDateTime(value) {
  let convertedValue;
  if (value instanceof firebase.firestore.Timestamp) {
    convertedValue = value.toDate().getTime();
  } else if (value instanceof Array) {
    convertedValue = value.length < 1 ? [] : value;
  } else if (value instanceof Object && _.isEmpty(value)) {
    // case of empty object
    convertedValue = 0;
  } else if (value instanceof Object && !(value instanceof Array)) {
    // case of Object and not an array
    convertedValue = transformDate(value);
  } else {
    convertedValue = value;
  }
  return convertedValue;
}

export function convertDateTime2(value, convertType) {
  let convertedValue;
  const converter = {
    'firebase-epoch': value => value.toDate().getTime(),
    'firebase-datetime': value => value.toDate(),
    'datetime-epoch': value => value.getTime(),
    'epoch-datetime': value => new Date(value),
    'epoch-iso': value => new Date(value).toISOString(),
  }
  if (value instanceof firebase.firestore.Timestamp) {
    // transform firestore timestamp to epoch
    convertedValue = converter[convertType ?? 'firebase-epoch'](value)
  } else if (
    (value instanceof Date || (typeof value == 'number' && value > 1454448400000))
    && ['datetime-epoch', 'epoch-datetime', 'epoch-iso'].includes(convertType)) {
    try {
      convertedValue = converter[convertType](value)
    } catch (e) {
      convertedValue = value
      console.log("FAIL TO CONVERT VALUE", value)
      console.log("TYPE", convertType)
    }
  }
  else if (value instanceof Array) {
    convertedValue = value.length < 1
      ? [] : value.map(x => transformDate2(x, convertType));
  } else if (value instanceof Object && _.isEmpty(value)) {
    // case of empty object
    convertedValue = 0;
  } else if (value instanceof Object && !(value instanceof Array)) {
    // case of Object and not an array
    convertedValue = transformDate2(value, convertType);
  } else {
    convertedValue = value;
  }
  return convertedValue;
}

// firebase timestamp --> date time
export const transformDate = (data) =>
  Object.fromEntries(
    Object.entries(data).map(([k, v]) => [k, convertDateTime(v)])
  );

// firebase timestamp --> date time
export const transformDate2 = (data, convertType=null) =>
  Object.fromEntries(
    Object.entries(data).map(([k, v]) => [k, convertDateTime2(v, convertType)])
  );

export const transformToFirebaseTimestamp = data =>
  Object.fromEntries(
    Object.entries(data).map(([k, v]) => [
      k,
      moment.isMoment(v) ? fromDate(v.toDate()) : v
    ])
  );

export const convertFromTimestamp = (timestamp, format = 'DD-MM-YYYY') =>
  moment(timestamp).format(format);

export const formatCurrency = number =>
  new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'vnd',
    minimumFractionDigits: 0
  }).format(number);

export const epochToDate = epochValue => {
  if (typeof epochValue == 'string') {
    // this case is used when date value is defined in string
    return Date.parse(epochValue);
  }
  let convertedDate;
  const currentTime = new Date().getTime();
  if (currentTime / epochValue > 10) {
    convertedDate = moment.unix(epochValue).toDate();
  } else {
    convertedDate = moment(epochValue).toDate();
  }
  return convertedDate;
};

export const dataConverter = (dataType, value, inCaseEmpty, listField='name') => {
  if (dataType === 'VND') {
    return formatCurrency(value ? value : 0);
  }
  if (dataType === 'date') {
    return convertFromTimestamp(value);
  }
  if (dataType === 'countList') {
    return value.length ? value.length : inCaseEmpty;
  }
  if (dataType === 'list') {
    return value.length ? value.map(x => x[listField]).join(', ') : inCaseEmpty;
  }
  return value;
};
