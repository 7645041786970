import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import {
  mySalesAnalyticsRef,
  companySalesAnalyticsRef,
  myCustomersAnalyticsRef,
  companyRef
} from 'src/rolesbased-rules/rolesBasedQuery';
import { apolloClient } from 'src/contexts/graphqlContext';
import { gql } from '@apollo/client';
import {TimeProcessor} from 'src/utils/timeProcessor'


const getApolloClient = (user, company) => {
  return apolloClient({
    authorization: `Bearer ${user.token}`,
    database: company.domain
  });
}

const groupDataByTime = (timeRange, records, key) => {
  let data;
  if (timeRange == 'thisYear'){
    data = Array(12).fill(0)
    
    for (const record of records){
      const createdDate = new Date(record.dateCreated)
      const createdMonth = createdDate.getMonth()
      const totalValue = record[key]
      data[createdMonth] += totalValue
    }
  } else if (timeRange == 'thisMonth') {
    const today = new Date()
    const lastDate = new Date(today.getFullYear(), today.getMonth() + 1, 0).getDate()
    data = Array(lastDate).fill(0)
    
    for (const record of records) {
      const createdDate = new Date(record.dateCreated)
      const createdMonth = createdDate.getMonth()
      if (createdMonth === today.getMonth()){
        data[createdDate.getDate() - 1] += record[key]
      }
    }
  } else {
    data = Array(7).fill(0)
    const today = new Date().getTime()
    const dateRangeInMilis = 24 * 60 * 60 * 1000
    for (const record of records) {
      const timeRange = Math.floor((today - record.dateCreated)/dateRangeInMilis)
      data[6 - timeRange] += record[key]
    }
  }
  return data
}

const initialState = {
  companySalesAnalytics: {
    overallSales: {
      totalSO: 0,
      orderCount: 0
    }
  },
  mySalesAnalytics: null,
  customersAnalyticsByRole: {},
  myCustomersAnalytics: null,
  allCompanySalesAnalytics: [],
  salesAnalyticsByRole: {},
  receiveNotes: { year: [], month: [], sevenDaysAgo: [] },
  costOfGoodsSold: { year: [], month: [], sevenDaysAgo: [] }
};

const slice = createSlice({
  name: 'analytics',
  initialState,
  reducers: {
    fetchCompanySalesAnalytics(state, action) {
      const { companySalesAnalytics } = action.payload;
      state.companySalesAnalytics = companySalesAnalytics;
    },
    fetchMySalesAnalytics(state, action) {
      const { mySalesAnalytics } = action.payload;
      state.mySalesAnalytics = mySalesAnalytics;
    },
    fetchCustomersAnalyticsByRole(state, action) {
      const { analyticsByRole, currentAccessLevel } = action.payload;
      state.customersAnalyticsByRole = {
        ...state.customersAnalyticsByRole,
        [currentAccessLevel]: analyticsByRole
      };
    },
    fetchMyCustomersAnalytics(state, action) {
      const { myCustomersAnalytics } = action.payload;
      state.myCustomersAnalytics = myCustomersAnalytics;
    },
    fetchAllCompanySalesAnalytics(state, action) {
      const { allCompanySalesAnalytics } = action.payload;
      state.allCompanySalesAnalytics = allCompanySalesAnalytics;
    },
    fetchCompanySalesAnalyticsIn(state, action) {
      const { compactSalesAnalytics } = action.payload;
      state.compactSalesAnalytics = compactSalesAnalytics;
    },
    getSalesAnalytics(state, action) {
      const { currentAnalytics, lastAnalytics } = action.payload;
      state.currentAnalytics = currentAnalytics;
      state.lastAnalytics = lastAnalytics;
    },
    fetchAllSalesAnalyticsObj(state, action) {
      const { allSalesAnalytics } = action.payload;
      state.allSalesAnalyticsObj = allSalesAnalytics;
    },
    
    // fetch receive note reducer
    fetchReceiveNoteData(state, action) {
      const {timeRange, data} = action.payload;
      let currentState = state.receiveNotes
      if (timeRange === "thisYear"){
        currentState.year = data
      } else if (timeRange == "thisMonth") {
        currentState.month = data
      } else {
        currentState.sevenDaysAgo = data
      }
      state.receiveNotes = {...currentState}
    },
    
    // fetch cost of goods sold reducer
    fetchCOGSData(state, action) {
      const {timeRange, data} = action.payload;
      let currentState = state.costOfGoodsSold
      if (timeRange === "thisYear"){
        currentState.year = data
      } else if (timeRange == "thisMonth") {
        currentState.month = data
      } else {
        currentState.sevenDaysAgo = data
      }
      state.costOfGoodsSold = {...currentState}
    },
    
    fetchCompanyTargets(state, action) {
      const { companyTargets } = action.payload;
      state.companyTargets = companyTargets;
    },
    getSalesAnalyticsByRole(state, action) {
      const { analyticsByRole, currentAccessLevel } = action.payload;
      state.salesAnalyticsByRole = {
        ...state.salesAnalyticsByRole,
        [currentAccessLevel]: analyticsByRole
      };
    }
  }
});

export const reducer = slice.reducer;

export const fetchCompanySalesAnalytics = () => async (dispatch, getState) => {
  const now = Date.now();
  const month_str = moment(now).format('MM-YYYY');
  const last_month_date = new Date();
  last_month_date.setDate(1);
  last_month_date.setMonth(last_month_date.getMonth() - 1);
  const last_month_str = moment(last_month_date).format('MM-YYYY');

  const all = companySalesAnalyticsRef.get();
  const thisMonth = companySalesAnalyticsRef
    .collection('sales-history')
    .doc(month_str)
    .get();
  const prevMonth = companySalesAnalyticsRef
    .collection('sales-history')
    .doc(last_month_str)
    .get();
  const promises = [all, thisMonth, prevMonth];
  const [all_snap, this_month_snap, prev_month_snap] = await Promise.all(
    promises
  );
  dispatch(
    slice.actions.fetchCompanySalesAnalytics({
      companySalesAnalytics: {
        overallSales: all_snap.data(),
        thisMonthSales: this_month_snap.data(),
        prevMonthSales: prev_month_snap.data()
      }
    })
  );
};

export const fetchAllCompanySalesAnalytics = () => (dispatch, getState) => {
  let allCompanySalesAnalytics = [];
  companySalesAnalyticsRef
    .collection('sales-history')
    .get()
    .then((snapShot) => {
      snapShot.forEach((doc) => {
        allCompanySalesAnalytics.push(doc.data());
      });
    })
    .then(() => {
      dispatch(
        slice.actions.fetchAllCompanySalesAnalytics({
          allCompanySalesAnalytics: allCompanySalesAnalytics
        })
      );
    });
};

export const fetchCompanyTargets = () => (dispatch, getState) => {
  companyRef.get().then((doc) => {
    dispatch(
      slice.actions.fetchCompanyTargets({ companyTargets: doc.data().target })
    );
  });
};

export const getSalesAnalyticsByRoleAndTimeRange =
  (yearOrMonth, now, currentAccessLevel) => async (dispatch, getState) => {
    const salesAnalyticsByRole = getState().analytics.salesAnalyticsByRole;
    let salesAnalyticsByCurrentRole = [];
    if (currentAccessLevel in salesAnalyticsByRole) {
      salesAnalyticsByCurrentRole = [...salesAnalyticsByRole[currentAccessLevel]];
      dispatch(
        getSalesAnalytics(yearOrMonth, now, salesAnalyticsByCurrentRole, true)
      );
    }

    const user = getState().user.user;
    const company = getState().company.company;
    const client = getApolloClient(user, company)
    const response = await client.query({
      fetchPolicy: 'network-only',
      query: gql`
        query fetchSalesOrders(
          $employeeId: String
          $accessLevel: String
          $startTime: Float
          $endTime: Float
        ) {
          fetchSalesOrders(
            employeeId: $employeeId
            accessLevel: $accessLevel
            startTime: $startTime
            endTime: $endTime
          ) {
            id
            dateCreated
            total
            customerId
          }
        }
      `,
      variables: {
        employeeId: user.id,
        accessLevel: currentAccessLevel,
        endTime: new Date().getTime(),
        startTime: new Date().setFullYear(new Date().getFullYear() - 1, 1, 1)
      }
    });
    const analyticsByRole = {};
    for (const saleOrder of response.data.fetchSalesOrders) {
      const date = moment(saleOrder.dateCreated).format('DD-MM-YYYY');
      const monthYear = moment(saleOrder.dateCreated).format('MM-YYYY');
      if (!(monthYear in analyticsByRole)) analyticsByRole[monthYear] = {};
      if (!(date in analyticsByRole[monthYear]))
        analyticsByRole[monthYear][date] = {
          orderList: [],
          orderCount: 0,
          totalSO: 0
        };
      analyticsByRole[monthYear][date] = {
        orderList: [
          ...analyticsByRole[monthYear][date].orderList,
          saleOrder.id
        ],
        orderCount: analyticsByRole[monthYear][date].orderCount + 1,
        totalSO: analyticsByRole[monthYear][date].totalSO + saleOrder.total
      };
    }
    salesAnalyticsByCurrentRole = Object.values(analyticsByRole);
    dispatch(
      slice.actions.getSalesAnalyticsByRole({
        analyticsByRole: Object.values(analyticsByRole),
        currentAccessLevel
      })
    );
    dispatch(
      getSalesAnalytics(yearOrMonth, now, salesAnalyticsByCurrentRole, true)
    );
  };

export const getSalesAnalytics = (yearOrMonth, now, allSaleAnaByRole = [], fetchByRole = false) => (dispatch, getState) => {
  let allSaleAna = null;
  if (fetchByRole) allSaleAna = allSaleAnaByRole;
  else allSaleAna = getState().analytics.allCompanySalesAnalytics;

  // yearOrMonth === 0 la month, 1 la year
  let currentAnalytics = [];
  let lastAnalytics = [];

  let last = new Date();
  if (!yearOrMonth) {
    //Month
    last.setMonth(now.getMonth() - 1);
  } else {
    //Year
    last.setYear(now.getFullYear() - 1);
  }

  allSaleAna.forEach((element) => {
    let date = Object.keys(element)[0];
    let docMonth = parseInt(date.split('-')[1]);
    let docYear = parseInt(date.split('-')[2]);

    if (!yearOrMonth) {
      if (
        docMonth === parseInt(now.getMonth()) + 1 &&
        docYear === parseInt(now.getFullYear())
      ) {
        currentAnalytics.push(element);
      } else if (
        docMonth === parseInt(last.getMonth()) + 1 &&
        docYear === parseInt(last.getFullYear())
      ) {
        lastAnalytics.push(element);
      }
    } else {
      if (docYear === parseInt(now.getFullYear())) {
        currentAnalytics.push(element);
      } else if (docYear === parseInt(last.getFullYear())) {
        lastAnalytics.push(element);
      }
    }
  });
  dispatch(
    slice.actions.getSalesAnalytics({
      currentAnalytics: currentAnalytics,
      lastAnalytics: lastAnalytics
    })
  );
  // companySalesAnalyticsRef.collection('sales-history').get().then(
  //     (snapShot)=>{
  //         snapShot.forEach(
  //             (doc) =>{
  //                 let docMonth = parseInt(doc.id.split('-')[0]);
  //                 let docYear = parseInt(doc.id.split('-')[1]);

  //                 if (!yearOrMonth){
  //                     if (docMonth === parseInt(now.getMonth())+1 && docYear === parseInt(now.getFullYear())){
  //                         currentAnalytics.push(doc.data());
  //                     }
  //                     else if (docMonth === parseInt(last.getMonth())+1 && docYear === parseInt(last.getFullYear())){
  //                         lastAnalytics.push(doc.data());
  //                     }
  //                 }
  //                 else{
  //                     if (docYear === parseInt(now.getFullYear())){
  //                         currentAnalytics.push(doc.data());
  //                     }
  //                     else if (docYear === parseInt(last.getFullYear())){
  //                         lastAnalytics.push(doc.data());
  //                     }
  //                 }
  //             }
  //         )
  //     }
  // ).then(
  //     ()=>{dispatch(slice.actions.getSalesAnalytics({currentAnalytics:currentAnalytics,lastAnalytics:lastAnalytics}))}
  // )
};

export const fetchAllSalesAnalyticsObj = () => (dispatch, getState) => {
  let allSalesAnalytics = [];
  companySalesAnalyticsRef
    .collection('sales-history')
    .get()
    .then((snapShot) => {
      snapShot.forEach((doc) => {
        let obj = {};
        obj[doc.id] = doc.data();
        allSalesAnalytics.push(obj);
      });
    })
    .then(() => {
      dispatch(
        slice.actions.fetchAllSalesAnalyticsObj({
          allSalesAnalytics: allSalesAnalytics
        })
      );
    });
};

// export const fetchCompanySalesAnalyticsIn = (yearOrMonth, number) => (dispatch,getState)=>{
//     //yearOrMonth === 0 la month, 1 la year
//     let compactSalesAnalytics = [];
//     companySalesAnalyticsRef.collection('sales-history').get().then(
//         (snapShot)=>{
//             snapShot.forEach(
//                 (doc) =>{
//                     if (parseInt(doc.id.split('-')[yearOrMonth])!==number) return;
//                     compactSalesAnalytics.push(doc.data());
//                 }
//             )
//         }
//     ).then(
//         () => {dispatch(slice.actions.fetchCompanySalesAnalyticsIn({ compactSalesAnalytics: compactSalesAnalytics })) }
//     )
// }

export const fetchMySalesAnalytics = (id) => async (dispatch, getState) => {
  const now = Date.now();
  const month_str = moment(now).format('MM-YYYY');
  const last_month_date = now;
  last_month_date.setDate(1);
  last_month_date.setMonth(last_month_date.getMonth() - 1);
  const last_month_str = moment(last_month_date).format('MM-YYYY');

  const all = mySalesAnalyticsRef(id).get();
  const thisMonth = mySalesAnalyticsRef(id)
    .collection('sales-history')
    .doc(month_str)
    .get();
  const prevMonth = mySalesAnalyticsRef(id)
    .collection('sales-history')
    .doc(last_month_str)
    .get();
  const promises = [all, thisMonth, prevMonth];
  const [all_snap, this_month_snap, prev_month_snap] = await Promise.all(
    promises
  );
  dispatch(
    slice.actions.fetchMySalesAnalytics({
      mySalesAnalytics: {
        overallSales: all_snap.data(),
        thisMonthSales: this_month_snap.data(),
        prevMonthSales: prev_month_snap.data()
      }
    })
  );
};

export const fetchCustomersAnalyticsByRole =
  (currentAccessLevel) => async (dispatch, getState) => {

    const customerAnalyticsByRole =
      getState().analytics.customersAnalyticsByRole[currentAccessLevel];
    
    const user = getState().user.user;
    const company = getState().company.company;
    const client = getApolloClient(user, company)
    
    dispatch(
      slice.actions.fetchCustomersAnalyticsByRole({
        analyticsByRole: customerAnalyticsByRole || {
          overallCustomers: { customerCount: 0 }
        },
        currentAccessLevel
      })
    );

    const response = await client.query({
      fetchPolicy: 'network-only',
      query: gql`
        query fetchCustomerWithSales(
          $employeeId: String
          $accessLevel: String
          $startTime: Float
          $endTime: Float
        ) {
          fetchCustomerWithSales(
            employeeId: $employeeId
            accessLevel: $accessLevel
            startTime: $startTime
            endTime: $endTime
          ) {
            total
            customerId
          }
        }
      `,
      variables: {
        employeeId: user.id,
        accessLevel: currentAccessLevel,
        endTime: new Date().getTime(),
        startTime: new Date().setFullYear(new Date().getFullYear() - 1, 1, 1)
      }
    });
    let customerIdList = [];
    for (const cust of response.data.fetchCustomerWithSales) {
      customerIdList.push(cust.customerId);
    }
    customerIdList = [...new Set(customerIdList)];
    dispatch(
      slice.actions.fetchCustomersAnalyticsByRole({
        analyticsByRole: {
          overallCustomers: { customerCount: customerIdList.length }
        },
        currentAccessLevel
      })
    );
  };

export const fetchMyCustomersAnalytics = (id) => async (dispatch, getState) => {
  const now = Date.now();
  const month_str = moment(now).format('MM-YYYY');
  const last_month_date = now;
  last_month_date.setDate(1);
  last_month_date.setMonth(last_month_date.getMonth() - 1);
  const last_month_str = moment(last_month_date).format('MM-YYYY');

  const all = myCustomersAnalyticsRef(id).get();
  const thisMonth = myCustomersAnalyticsRef(id)
    .collection('sales-history')
    .doc(month_str)
    .get();
  const prevMonth = myCustomersAnalyticsRef(id)
    .collection('sales-history')
    .doc(last_month_str)
    .get();
  const promises = [all, thisMonth, prevMonth];
  const [all_snap, this_month_snap, prev_month_snap] = await Promise.all(
    promises
  );

  dispatch(
    slice.actions.fetchMySalesAnalytics({
      myCustomersAnalytics: {
        overallCustomers: all_snap.data(),
        thisMonthCustomers: this_month_snap.data(),
        prevMonthCustomers: prev_month_snap.data()
      }
    })
  );
};

export const fetchReceiveNoteAnalytics = (timeRange, currentAccessLevel) => async (dispatch, getState) => {
  /**
   * @param timeRange: contains startTime and endTime
   * @param currentRole: role of the logged in user in the session
   */
  
  const user = getState().user.user;
  const company = getState().company.company;
  const client = getApolloClient(user, company)
  
  const timeProcessor = new TimeProcessor()
  const endTime = timeProcessor.currentTime.getTime()
  let startTime
  switch(timeRange) {
    case 'thisYear': 
      startTime = timeProcessor.getFirstDateOfYear().getTime()
      break
    case 'thisMonth': 
      startTime = timeProcessor.getFirstDateOfMonth().getTime()
      break
    default: // handle today and this week 
      startTime = timeProcessor.getNumberOfDaysAgo(6).getTime()
      break
  }
  
  const response = await client.query({
    fetchPolicy: 'network-only',
    query: gql`
        query fetchReceiveNotes(
          $employeeId: String
          $accessLevel: String
          $startTime: Float
          $endTime: Float
        ) {
          fetchReceiveNotes(
            employeeId: $employeeId
            accessLevel: $accessLevel
            startTime: $startTime
            endTime: $endTime
          ) {
            dateCreated
            total
          }
        }
      `,
    variables: {
      employeeId: user.id,
      accessLevel: currentAccessLevel,
      endTime: endTime,
      startTime: startTime
    }
  });

  const receiveNotes = groupDataByTime(timeRange, response.data.fetchReceiveNotes, 'total')
  dispatch(slice.actions.fetchReceiveNoteData({
    timeRange: timeRange,
    data: receiveNotes
  }))
};


export const fetchCOGSAnalytics = (timeRange, currentAccessLevel) => async (dispatch, getState) => {
  /**
   * @param timeRange: contains startTime and endTime
   * @param currentRole: role of the logged in user in the session
   */
  
  const user = getState().user.user;
  const company = getState().company.company;
  const client = getApolloClient(user, company)
  
  const timeProcessor = new TimeProcessor()
  const endTime = timeProcessor.currentTime.getTime()
  let startTime
  switch(timeRange) {
    case 'thisYear': 
      startTime = timeProcessor.getFirstDateOfYear().getTime()
      break
    case 'thisMonth': 
      startTime = timeProcessor.getFirstDateOfMonth().getTime()
      break
    default: // handle today and this week 
      startTime = timeProcessor.getNumberOfDaysAgo(6).getTime()
      break
  }
  
  const response = await client.query({
    fetchPolicy: 'network-only',
    query: gql`
        query fetchSalesOrders(
          $employeeId: String
          $accessLevel: String
          $startTime: Float
          $endTime: Float
        ) {
          fetchSalesOrders(
            employeeId: $employeeId
            accessLevel: $accessLevel
            startTime: $startTime
            endTime: $endTime
          ) {
            dateCreated
            cogs
          }
        }
      `,
    variables: {
      employeeId: user.id,
      accessLevel: currentAccessLevel,
      endTime: endTime,
      startTime: startTime
    }
  });

  const costOfGoodsSold = groupDataByTime(timeRange, response.data.fetchSalesOrders, 'cogs')
  dispatch(slice.actions.fetchCOGSData({
    timeRange: timeRange,
    data: costOfGoodsSold
  }))
};

export default slice;
