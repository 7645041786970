import { createSlice } from '@reduxjs/toolkit';
import {
  transformDate,
  transformToFirebaseTimestamp
} from '../../utils/formater';
import queries, {
  serverTime,
  warehousesRef,
  productLocationsRef
} from '../../rolesbased-rules/rolesBasedQuery';
import _ from 'lodash';
import { fetchTotalWHProductFromDB, fetchWHProductFromDB } from '../../rolesbased-rules/GQL/queries';
import { getComparator } from '../../utils/queryUtils';

const initialState = {
  warehouses: {}
};

const slice = createSlice({
  name: 'warehouses',
  initialState,
  reducers: {
    fetchWarehouse(state, action) {
      state.warehouses = {
        ...state.warehouses,
        [action.payload.id]: action.payload
      };
    },
    fetchWarehouseLocations(state, action) {
      state.warehouses = {
        ...state.warehouses,
        [action.payload.id]: action.payload
      };
    },
    fetchWarehouses(state, action) {
      state.warehouses = {
        ...state.warehouses,
        ..._.mapKeys(action.payload, 'id')
      };
    },
    fetchWarehousesWithSpecificProduct(state, action) {
      state.warehouses = { ..._.mapKeys(action.payload, 'id') };
    },
    createWarehouse(state, action) {
      state.warehouses = {
        ...state.warehouses,
        [action.payload.id]: action.payload
      };
    },
    editWarehouse(state, action) {
      state.warehouses = {
        ...state.warehouses,
        [action.payload.id]: action.payload
      };
    },
    updateLocalWHProduct(state, action) {
      const { localProductQL, role, warehouse } = action.payload;
      state.warehouses[warehouse].localProductQL = {
        ...state.warehouses[warehouse].localProductQL,
        [role]: localProductQL
      };
    },
    updateTotalWHProduct(state, action) {
      const { totalProduct, role, warehouse } = action.payload;
      state.warehouses[warehouse].totalProduct = {
        ...state.warehouses[warehouse].totalProduct,
        [role]: totalProduct
      };
    }
  }
});

export const reducer = slice.reducer;

export const fetchWarehouseLocations = (id) => async (dispatch, getState) => {
  const snap = await warehousesRef.doc(id).collection('locations').get();
  const locations = [];
  snap.forEach((doc) => {
    locations.push(transformDate(doc.data()));
  });
  dispatch(slice.actions.fetchWarehouseLocations(transformDate(locations)));
};

export const fetchWarehouse = (id) => async (dispatch, getState) => {
  const { accessLevel } = getState().user;
  if (accessLevel) {
    const { warehouseRef } = queries[accessLevel];
    const wh = await warehouseRef(id).get();
    dispatch(slice.actions.fetchWarehouse(transformDate(wh.data())));
  }
};

export const fetchWarehousesWithSpecificProduct =
  (productId) => async (dispatch, getState) => {
    const response = await productLocationsRef(productId)
      .where('stock', '>', 0)
      .get();
    const whId = new Set();
    const warehouses = [];
    response.forEach((doc) => {
      if (!whId.has(doc.data().whId)) {
        warehouses.push({
          id: doc.data().whId,
          name: doc.data().whName
        });
        whId.add(doc.data().whId);
      }
    });
    dispatch(
      slice.actions.fetchWarehousesWithSpecificProduct(
        transformDate(warehouses)
      )
    );
  };

export const fetchWarehousesWithSpecificProductWithoutRedux = async (
  productId
) => {
  const response = await productLocationsRef(productId)
    .where('stock', '>', 0)
    .get();
  const whId = new Set();
  const warehouses = [];
  response.forEach((doc) => {
    if (!whId.has(doc.data().whId)) {
      warehouses.push({
        id: doc.data().whId,
        name: doc.data().whName
      });
      whId.add(doc.data().whId);
    }
  });
  return warehouses;
};

export const fetchWarehouses = () => async (dispatch, getState) => {
  const snap = await warehousesRef.get();
  const warehouses = [];
  snap.forEach((doc) => {
    warehouses.push(transformDate(doc.data()));
  });
  dispatch(slice.actions.fetchWarehouses(transformDate(warehouses)));
};

export const createWarehouse = (data) => async (dispatch, getState) => {
  const { accessLevel } = getState().user;
  const { warehouseCreateRef } = queries[accessLevel];

  const newWhRef = warehouseCreateRef().doc();
  const id = newWhRef.id;
  const timestamp = serverTime();
  const transformData = transformToFirebaseTimestamp(data);
  const wh_data = {
    ...transformData,
    dateCreated: timestamp,
    dateModified: timestamp,
    id
  };

  await newWhRef.set(wh_data);
  const locRef = newWhRef.collection('locations').doc();
  const loc_id = locRef.id;
  await locRef.set({ name: '', id: loc_id });
  // create default location

  dispatch(
    slice.actions.createWarehouse(transformDate({ ...transformData, id }))
  );

  // history.push('/customers/list/');
};

export const editWarehouse = (data, id) => async (dispatch, getState) => {
  const { accessLevel } = getState().user;
  const { warehouseRef } = queries[accessLevel];

  const timestamp = serverTime();
  const transformData = transformToFirebaseTimestamp(data);
  const customer_data = {
    ...transformData,
    dateModified: timestamp
  };

  await warehouseRef(id).update(customer_data);

  dispatch(
    slice.actions.editWarehouse(transformDate({ ...transformData, id }))
  );

  // history.push(`/customers/${id}/`);
};

export const fetchTotalWHProductGraph = (warehouse) => (
  employeeId, accessLevel, status, searchField, setLoading
) => async (dispatch, getState) => {
  setLoading(true);
  let data = await fetchTotalWHProductFromDB(
    employeeId, accessLevel, searchField, warehouse)

  let totalProductByCurrentRole = -1
  if (status === null) {
    totalProductByCurrentRole = data.reduce((prev, cur) => prev + cur.cnt, 0)
  } else {
    const totalProductObjAr = data.filter(ele => ele.status === status)
    if (totalProductObjAr.length > 0) totalProductByCurrentRole = totalProductObjAr[0].cnt
  }

  dispatch(slice.actions.updateTotalWHProduct({
    totalProduct: totalProductByCurrentRole,
    role: accessLevel,
    warehouse
  }))
  setLoading(false)
  return data
};

export const fetchWHProductGraph = (warehouse) => (
  employeeId, accessLevel, sort, status, searchField,
  limit, skip, listField, setLoading, fromSort = false
) => async (dispatch, getState) => {
  setLoading(true);
  let [field, order] = sort.split('|');

  const accessLevel = getState().user.currentAccessLevel;

  const response = await fetchWHProductFromDB(
    employeeId, accessLevel, sort, status,
    searchField, limit, skip, getState, warehouse
  );

  let data = order === 'desc' ? response.data.fetchProductByRoleDesc : response.data.fetchProductByRoleAsc;

  if (fromSort) {
    const arr = data.slice().sort(getComparator(order, field, listField));
    await dispatch(slice.actions.updateLocalWHProduct({
      localProductQL: arr,
      role: accessLevel,
      warehouse
    }));
  } else {
    let arr = [];
    if (getState().warehouses.warehouses[warehouse].localProductQL) {
      arr = getState().warehouses.warehouses[warehouse].localProductQL[accessLevel].slice();
    }
    data.forEach((ele) => {
      let have = arr.find((x) => x.id === ele.id);
      if (!have) {
        arr.push(ele);
      }
    });
    arr.sort(getComparator(order, field, listField));
    await dispatch(slice.actions.updateLocalWHProduct({
      localProductQL: arr,
      role: accessLevel,
      warehouse
    }));
  }

  setLoading(false);
};

export const updateWHProductGraph = (warehouse) => (
  employeeId, accessLevel, sort, status, searchField, limit, page, listField
) => async (dispatch, getState) => {
  const [field, order] = sort.split('|');
  const skip = page * limit;

  const accessLevel = getState().user.currentAccessLevel;
  const response = await fetchWHProductFromDB(
    employeeId, accessLevel, sort, status,
    searchField, limit, skip, getState, warehouse
  );

  let data = order === 'desc' ? response.data.fetchProductByRoleDesc : response.data.fetchProductByRoleAsc;
  let arr = [];
  if (getState().warehouses.warehouses[warehouse].localProductQL) {
    arr = getState().warehouses.warehouses[warehouse].localProductQL[accessLevel].slice();
  }

  data.forEach((ele) => {
    let idx = arr.findIndex((x) => x.id === ele.id);
    if (idx > -1) {
      arr[idx] = ele;
    } else {
      arr.push(ele);
    }
  });

  arr.sort(getComparator(order, field, listField));
  dispatch(slice.actions.updateLocalWHProduct({
    localProductQL: arr,
    role: accessLevel,
    warehouse
  }));
};

export default slice;
