import { createSlice } from '@reduxjs/toolkit';
import queries, {
  branchesRef,
  serverTime
} from '../../rolesbased-rules/rolesBasedQuery';
import _ from 'lodash';
import {
  transformDate,
  transformToFirebaseTimestamp
} from '../../utils/formater';

const initialState = {
  branches: {},
  isModalOpen: false
};

const slice = createSlice({
  name: 'branches',
  initialState,
  reducers: {
    getBranches(state, action) {
      state.branches = {
        ...state.branches,
        ..._.mapKeys(action.payload, 'id')
      };
    },
    createBranchAction(state, action) {
      state.branches = {
        ...state.branches,
        [action.payload.id]: action.payload
      };
    },
    deleteBranchAction(state, action) {
      delete state.branches[action.payload.id];
    },
    updateBranchAction(state, action) {
      state.branches = {
        ...state.branches,
        [action.payload.id]: action.payload
      };
    },
    openModal(state) {
      state.isModalOpen = true;
    },
    closeModal(state) {
      state.isModalOpen = false;
      state.selectedEventId = null;
      state.selectedRange = null;
    }
  }
});

export const reducer = slice.reducer;

export const getBranches = () => async (dispatch) => {
  const snap = await branchesRef.orderBy('name').get();
  const branches = [];

  snap.forEach((doc) => {
    branches.push(transformDate(doc.data()));
  });
  dispatch(slice.actions.getBranches(branches));
};

export const createBranchAction = (data) => async (dispatch, getState) => {
  const { currentAccessLevel } = getState().user;
  const { branchesCreateRef } = queries[currentAccessLevel];

  const newBranchRef = branchesCreateRef().doc();
  const id = newBranchRef.id;
  const timestamp = serverTime();
  const transformData = transformToFirebaseTimestamp(data.branch);
  const branch_data = {
    id,
    ...transformData,
    dateCreated: timestamp,
    dataModified: timestamp
  };
  await newBranchRef.set(branch_data);
  dispatch(slice.actions.createBranchAction(transformDate(branch_data)));
};

export const deleteBranchAction = (branch) => async (dispatch, getState) => {
  const { currentAccessLevel } = getState().user;
  const { branchRef } = queries[currentAccessLevel];
  await branchRef(branch.id).delete();
  dispatch(slice.actions.deleteBranchAction(branch));
};

export const updateBranchAction = (data) => async (dispatch, getState) => {
  const { currentAccessLevel } = getState().user;
  const { branchRef } = queries[currentAccessLevel];
  const transformData = transformToFirebaseTimestamp(data.branch);
  const branch_data = {
    ...transformData,
    dataModified: serverTime()
  };
  await branchRef(data.branch.id).update(branch_data);
  dispatch(slice.actions.updateBranchAction(transformDate(branch_data)));
};

export const openModal = () => (dispatch) => {
  dispatch(slice.actions.openModal());
};

export const closeModal = () => (dispatch) => {
  dispatch(slice.actions.closeModal());
};

export default slice;
