import { createSlice } from '@reduxjs/toolkit';
import {
  receiveAutoNumRef,
  receiveNoteRef,
  receiveNoteIDRef
} from '../../rolesbased-rules/rolesBasedQuery';
import { transformDate } from '../../utils/formater';
import history from '../../history';
import store from '../index'
import { apolloClient } from '../../contexts/graphqlContext'
import { gql } from '@apollo/client'

const initialState = {
  numberOfRN: 0,
  localRN: [],
  lastDoc: [],
  detailRN: {},
  totalReceiveNote: {
    CEO: -1,
    ADMIN: -1,
    WAREHOUSE_MANAGER: -1,
    SALES_REP: -1,
    SALES_MANAGER: -1,
    DOCTOR_MANAGER: -1,
    DOCTOR: -1
  },
  localReceiveNoteQL: {
  },
};

const slice = createSlice({
  name: 'receiveNote',
  initialState,
  reducers: {
    updateRNCacheById(state, action) {
      const { RNCache } = action.payload;
      state.detailRN = {
        ...state.detailRN,
        [RNCache.id]: RNCache.detail
      };
    },
    setNumberOfReceiveNote(state, action) {
      const { noRN } = action.payload;
      state.numberOfRN = noRN.receiveNote;
    },
    fetchReceiveNoteFrom(state, action) {
      const { RNFrom } = action.payload;
      state.localRN = RNFrom;
    },
    fetchSearchReceiveNote(state, action) {
      const { RN } = action.payload;
      state.localRN = RN;
    },
    setReceiveNoteCacheById(state, action) {
      const { RNCache } = action.payload;
      state.detailRN = {
        ...state.detailRN,
        [RNCache.id]: RNCache.detail
      };
    },
    refreshLastDocument(state, action) {
      //Xoá hết lastDoc
      state.lastDoc = [];
    },
    fetchFirstLimitRN(state, action) {
      //Xoá Ele local và lấy Ele lại
      const { localRN } = action.payload;
      state.localRN = localRN;
    },
    setLastDocument(state, action) {
      //Thêm vào cuối lastDoc
      const { doc } = action.payload;
      state.lastDoc = [...state.lastDoc, doc];
    },
    fetchRNFrom(state, action) {
      //Lấy RN về
      const { localRN } = action.payload;
      state.localRN = localRN;
    },
    updateRNFrom(state, action) {
      //Update RN
      const { localRN } = action.payload;
      state.localRN = localRN;
    },
    fetchSearchRN(state, action) {
      const { localRN } = action.payload;
      state.localRN = localRN;
    },
    // for graphQL
    updateLocalReceiveNote(state, action) {
      const { localReceiveNoteQL, role } = action.payload;
      state.localReceiveNoteQL = {
        ...state.localReceiveNoteQL,
        [role]: localReceiveNoteQL
      };
    },
    updateTotalReceiveNote(state, action) {
      const { totalReceiveNote, role } = action.payload;
      state.totalReceiveNote = {
        ...state.totalReceiveNote,
        [role]: totalReceiveNote
      };
    }
  }
});

export const reducer = slice.reducer;

const descendingComparator = (a, b, orderBy, listField) => {
  let field = orderBy.split('.')[0]; //1 biến chính là orderby
  let innerField = orderBy.split('.')[1];

  if (listField.includes(field)) {
    a = a[field];
    b = b[field];
    field = innerField;
  }

  //b O orderBy: doctor.name
  if (b[field] < a[field]) {
    return -1;
  }

  if (b[field] > a[field]) {
    return 1;
  }

  return 0;
};

const getComparator = (order, orderBy, listField) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy, listField)
    : (a, b) => -descendingComparator(a, b, orderBy, listField);
};

//Functions
export const updateRNCacheById = (rndetail) => async (dispatch, getState) => {
  dispatch(
    slice.actions.updateRNCacheById({
      RNCache: {
        id: rndetail.id,
        detail: rndetail
      }
    })
  );
};

export const setLastDocument = (doc) => async (dispatch, getState) => {
  dispatch(slice.actions.setLastDocument({ doc: doc }));
};

export const refreshLastDocument = (doc) => async (dispatch, getState) => {
  await dispatch(slice.actions.refreshLastDocument());
  return true;
};

export const setNumberOfReceiveNote = () => async (dispatch, getState) => {
  await receiveAutoNumRef.get().then((snapShot) => {
    dispatch(slice.actions.setNumberOfReceiveNote({ noRN: snapShot.data() }));
  });
};

export const fetchFirstLimitRN =
  (limit, order, field, listField) => async (dispatch, getState) => {
    const eleList = [];
    const query = receiveNoteRef.orderBy(field, order).limit(limit);
    const docs = await query.get();

    //Set last doc
    await dispatch(
      slice.actions.setLastDocument({ doc: docs.docs[docs.docs.length - 1] })
    );

    docs.forEach((doc) => {
      eleList.push(transformDate(doc.data()));
    });

    eleList.sort(getComparator(order, field, listField));
    dispatch(slice.actions.fetchFirstLimitRN({ localRN: eleList }));
  };

export const fetchRNFrom = (isFirstQuery, lastDoc, limit, order, field, listField) => async (dispatch, getState) => {
  let query = null;
  let RNList = [];

  if (isFirstQuery) {
    query = receiveNoteRef.orderBy(field, order).limit(limit);
  } else {
    query = receiveNoteRef
      .orderBy(field, order)
      .startAfter(lastDoc)
      .limit(limit);
  }

  let docs = await query.get();
  if (docs.docs.length === 0) {
    return;
  }
  //Set last doc
  await dispatch(
    slice.actions.setLastDocument({ doc: docs.docs[docs.docs.length - 1] })
  );
  let arr = getState().receiveNote.localRN.slice();

  docs.forEach((doc) => {
    let data = doc.data();
    let have = arr.find((x) => x.id === data.id);
    if (!have) {
      //Khác mới push vào
      RNList.push(transformDate(data));
    }
  });

  let newList = [...arr, ...RNList];
  newList.sort(getComparator(order, field, listField));

  dispatch(slice.actions.fetchRNFrom({ localRN: newList }));
};

export const updateRNFrom =
  (lastDocList, limit, order, field, page, listField) =>
    async (dispatch, getState) => {
      let query = null;
      let RNList = [];

      if (!page) {
        query = receiveNoteRef.orderBy(field, order).limit(limit);
      } else {
        query = receiveNoteRef
          .orderBy(field, order)
          .startAfter(lastDocList[page - 1])
          .limit(limit);
      }

      let docs = await query.get();

      let arr = getState().receiveNote.localRN.slice();

      docs.forEach((doc) => {
        RNList.push(transformDate(doc.data()));
      });

      console.log(RNList, 'update list nè');

      RNList.forEach((ele) => {
        let idx = arr.findIndex((x) => x.id === ele.id);
        if (idx > -1) {
          arr[idx] = ele;
        } else {
          arr.push(ele);
        }
      });

      arr.sort(getComparator(order, field, listField));
      dispatch(slice.actions.updateRNFrom({ localRN: arr }));
    };

export const fetchSearchRN =
  (order, field, listField, searchStr) => async (dispatch, getState) => {
    const eleList = [];
    let query = receiveNoteRef
      .where('supplier.name', '>=', searchStr)
      .where('supplier.name', '<=', searchStr + '\uf8ff');

    let docs = await query.get();

    if (docs.docs.length === 0) {
      const tmp = Number.parseFloat(searchStr);
      if (!isNaN(tmp)) {
        query = receiveNoteRef.where('formNumber', '==', tmp);

        docs = await query.get();
      }
    }

    docs.forEach((doc) => {
      eleList.push(transformDate(doc.data()));
    });
    eleList.sort(getComparator(order, field, listField));
    dispatch(slice.actions.fetchSearchRN({ localRN: eleList }));
  };

export const setReceiveNoteCacheById = (id) => async (dispatch, getState) => {
  let doc = await receiveNoteIDRef(id).get();
  if (doc.data()) {
    const data = transformDate(doc.data());

    dispatch(
      slice.actions.setReceiveNoteCacheById({
        RNCache: {
          id: id,
          detail: data
        }
      })
    );
  } else {
    alert('Có lỗi khi lấy thông tin của phiếu nhập' + id.toString());
    history.push('/management/receive-notes');
  }
};

////// FOR GRAPH DB //////////
const fetchTotalReceiveNoteFromDB = async (employeeId, accessLevel, searchField) => {
  const user = store.getState().user.user;
  const company = store.getState().company.company;
  const client = apolloClient({
    authorization: `Bearer ${user.token}`,
    database: company.domain
  });
  const response = await client.query({
    fetchPolicy: 'network-only',
    query: gql`
        query fetchTotalReceiveNoteByRole($employeeId: String, $accessLevel: String, $searchField: String) {
            fetchTotalReceiveNoteByRole(
                employeeId: $employeeId, accessLevel: $accessLevel,  searchField: $searchField
            ){
                status,
                cnt
            }
        }
    `,
    variables: {
      employeeId: employeeId,
      accessLevel,
      searchField: searchField
    }
  });
  return response.data.fetchTotalReceiveNoteByRole;
}

export const fetchTotalReceiveNoteGraph = (
  employeeId, accessLevel, status, searchField, setLoading
) => async (dispatch, getState) => {
  setLoading(true);
  let data = await fetchTotalReceiveNoteFromDB(employeeId, accessLevel, searchField)

  let totalReceiveNoteByCurrentRole = -1
  if (status === null) {
    totalReceiveNoteByCurrentRole = data.reduce((prev, cur) => prev + cur.cnt, 0)
  } else {
    const totalReceiveNoteObjAr = data.filter(ele => ele.status === status)
    if (totalReceiveNoteObjAr.length > 0) totalReceiveNoteByCurrentRole = totalReceiveNoteObjAr[0].cnt
  }

  dispatch(slice.actions.updateTotalReceiveNote({ totalReceiveNote: totalReceiveNoteByCurrentRole, role: accessLevel }))
  setLoading(false)
  return data
};

const fetchReceiveNoteFromDB = async (
  employeeId, accessLevel, sort, status, searchField, limit, skip, getState
) => {
  let [field, order] = sort.split('|');
  const user = getState().user.user;
  const company = getState().company.company;
  const client = apolloClient({
    authorization: `Bearer ${user.token}`,
    database: company.domain
  });
  return await client.query({
    fetchPolicy: 'network-only',
    query: gql`
        query fetchReceiveNote(
            $employeeId: String, $accessLevel: String, $propName: String,
            $limit: Int!, $searchField: String, $skip: Int!, $status: String
        ){
            ${order === 'desc' ? 'fetchReceiveNotesByRoleDesc' : 'fetchReceiveNotesByRoleAsc'}
            (employeeId: $employeeId, accessLevel: $accessLevel, propName: $propName,
            status: $status, searchField: $searchField, limit: $limit, skip: $skip)
        {
            id
            status
            formNumber
            dateCreated
            status
            total
            createdBy{name}
        }
        }
    `,
    variables: {
      employeeId: employeeId,
      accessLevel,
      skip: skip,
      limit: limit,
      propName: field,
      quoteStatus: status,
      searchField: searchField
    }
  });
};

export const fetchReceiveNoteGraph = (
  employeeId, accessLevel, sort, status, searchField,
  limit, skip, listField, setLoading, fromSort = false
) => async (dispatch, getState) => {
  setLoading(true);
  let [field, order] = sort.split('|');

  const role = getState().user.currentRole;

  const response = await fetchReceiveNoteFromDB(
    employeeId, accessLevel, sort, status, searchField, limit, skip, getState);

  let data = order === 'desc' ? response.data.fetchReceiveNotesByRoleDesc : response.data.fetchReceiveNotesByRoleAsc;

  if (fromSort) {
    const arr = data.slice().sort(getComparator(order, field, listField));
    await dispatch(slice.actions.updateLocalReceiveNote({ localReceiveNoteQL: arr, role: role }));
  } else {
    let arr = getState().receiveNote.localReceiveNoteQL[role] ?? []
    arr = arr.slice();
    data.forEach((ele) => {
      let have = arr.find((x) => x.id === ele.id);
      if (!have) {
        arr.push(ele);
      }
    });
    arr.sort(getComparator(order, field, listField));
    await dispatch(slice.actions.updateLocalReceiveNote({ localReceiveNoteQL: arr, role: role }));
  }

  setLoading(false);
};

export const updateReceiveNoteGraph = (
  employeeId, accessLevel, sort, status, searchField, limit, page, listField
) => async (dispatch, getState) => {
  const [field, order] = sort.split('|');
  const skip = page * limit;

  const role = getState().user.currentRole;
  const response = await fetchReceiveNoteFromDB(
    employeeId, accessLevel, sort, status, searchField, limit, skip, getState
  );

  let data = order === 'desc' ? response.data.fetchReceiveNotesByRoleDesc : response.data.fetchReceiveNotesByRoleAsc;
  let arr = getState().receiveNote.localReceiveNoteQL[role] ?? []
  arr = arr.slice();

  data.forEach((ele) => {
    let idx = arr.findIndex((x) => x.id === ele.id);
    if (idx > -1) {
      arr[idx] = ele;
    } else {
      arr.push(ele);
    }
  });

  arr.sort(getComparator(order, field, listField));
  dispatch(slice.actions.updateLocalReceiveNote({ localReceiveNoteQL: arr, role: role }));
};


export default slice;
