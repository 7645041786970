import { Link as RouterLink } from 'react-router-dom';
import {
  LinearProgress,
  Link,
  List,
  ListItem,
  Typography,
  TableRow,
  Table,
  TableHead,
  TableBody,
  TableCell,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { gql, useQuery } from '@apollo/client';
import moment from 'moment';

const useStyles = makeStyles(() => ({
  searchRes: {
    fontWeight: 'bold',
    color: '#3949ab',
    marginBottom: '5px',
    fontSize: '1.25rem'
  },
  notFound: {
    fontSize: '1rem',
    fontFamily: 'Roboto',
    fontWeight: '400'
  }
}));
const GET_SALESORDERS = gql`
  query searchSaleOrder($str: String!) {
    searchSaleOrder(searchString: $str) {
      id
      formNumber
      dateCreated
      customer {
        name
        id
      }
    }
  }
`;

const GET_SALESORDERS_BY_PRODUCT = gql`
  query searchSaleOrderByProduct($str: String!) {
    searchSaleOrderByProduct(searchString: $str) {
      id
      formNumber
      dateCreated
      customer {
        name
        id
      }
    }
  }
`;
const GET_SALESORDERS_BY_CUSTOMER = gql`
  query searchSaleOrderByCustomer($str: String!) {
    searchSaleOrderByCustomer(searchString: $str) {
      id
      formNumber
      dateCreated
      customer {
        name
        id
      }
    }
  }
`;
const GET_PRODUCT = gql`
  query searchProduct($str: String!) {
    searchProduct(searchString: $str) {
      id
      name
    }
  }
`;
const GET_CUSTOMER = gql`
  query searchCustomer($str: String!) {
    searchCustomer(searchString: $str) {
      id
      name
    }
  }
`;

const getLink = (res) => {
  if (!res.customer) return '/404';
  if (!res.customer.id) return '/404';
  return `/management/customers/${res.customer.id}`;
};

const getName = (res) => {
  if (!res.customer) return '';
  if (!res.customer.name) return '';
  return res.customer.name;
};

export const SearchSOResultByFormNumber = ({ searchStr, setSearchQuery }) => {
  const classes = useStyles();
  const { loading, error, data } = useQuery(GET_SALESORDERS, {
    variables: {
      str: searchStr
    },
    fetchPolicy: 'cache-and-network'
  });
  if (searchStr === '') return null;
  if (loading) return <LinearProgress className={classes.searchRes} />;
  if (error) return `Error! ${error}`;

  return (
    <>
      <Typography className={classes.searchRes}>Đơn hàng theo mã số</Typography>
      {data.searchSaleOrder.length === 0 ? (
        <List>
          <ListItem className={classes.notFound}>
            Không có đơn hàng nào thỏa yêu cầu
          </ListItem>
        </List>
      ) : (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Mã đơn hàng</TableCell>
              <TableCell>Khách hàng</TableCell>
              <TableCell>Ngày tạo</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.searchSaleOrder.map((res, index) => (
              <TableRow key={index}>
                <TableCell>
                  <Link
                    variant="body1"
                    color="inherit"
                    to={`/management/orders/${res.id}`}
                    component={RouterLink}
                  >
                    {res.formNumber}
                  </Link>
                </TableCell>
                <TableCell>
                  <Link
                    variant="body1"
                    color="inherit"
                    to={`/management/customers/${
                      res.customer ? res.customer.id : ''
                    }`}
                    component={RouterLink}
                  >
                    {res.customer ? res.customer.name : ''}
                  </Link>
                </TableCell>
                <TableCell>
                  {moment(res.dateCreated).format('DD/MM/YYYY HH:mm', true)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </>
  );
};

export const SearchSOResultByProduct = ({ searchStr, setSearchQuery }) => {
  const classes = useStyles();
  const { loading, error, data } = useQuery(GET_SALESORDERS_BY_PRODUCT, {
    variables: {
      str: searchStr
    },
    fetchPolicy: 'cache-and-network'
  });
  if (searchStr === '') return null;
  if (loading) return <LinearProgress className={classes.searchRes} />;
  if (error) return `Error! ${error}`;

  return (
    <>
      <Typography className={classes.searchRes}>
        Đơn hàng theo sản phẩm
      </Typography>
      {data.searchSaleOrderByProduct.length === 0 ? (
        <List>
          <ListItem className={classes.notFound}>
            Không có đơn hàng nào thỏa yêu cầu
          </ListItem>
        </List>
      ) : (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Mã đơn hàng</TableCell>
              <TableCell>Khách hàng</TableCell>
              <TableCell>Ngày tạo</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.searchSaleOrderByProduct.map((res, index) => (
              <TableRow key={index}>
                <TableCell>
                  <Link
                    variant="body1"
                    color="inherit"
                    to={`/management/orders/${res.id}`}
                    component={RouterLink}
                  >
                    {res.formNumber}
                  </Link>
                </TableCell>
                <TableCell>
                  <Link
                    variant="body1"
                    color="inherit"
                    to={getLink(res)}
                    component={RouterLink}
                  >
                    {getName(res)}
                  </Link>
                </TableCell>
                <TableCell>
                  {moment(res.dateCreated).format('DD/MM/YYYY HH:mm', true)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </>
  );
};
export const SearchSOResultByCustomer = ({ searchStr, setSearchQuery }) => {
  const classes = useStyles();
  const { loading, error, data } = useQuery(GET_SALESORDERS_BY_CUSTOMER, {
    variables: {
      str: searchStr
    },
    fetchPolicy: 'cache-and-network'
  });
  if (searchStr === '') return null;
  if (loading) return <LinearProgress className={classes.searchRes} />;
  if (error) return `Error! ${error}`;

  return (
    <>
      <Typography className={classes.searchRes}>
        Đơn hàng theo khách hàng
      </Typography>
      {data.searchSaleOrderByCustomer.length === 0 ? (
        <List>
          <ListItem className={classes.notFound}>
            Không có đơn hàng nào thỏa yêu cầu
          </ListItem>
        </List>
      ) : (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Mã đơn hàng</TableCell>
              <TableCell>Khách hàng</TableCell>
              <TableCell>Ngày tạo</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.searchSaleOrderByCustomer.map((res, index) => (
              <TableRow key={index}>
                <TableCell>
                  <Link
                    variant="body1"
                    color="inherit"
                    to={`/management/orders/${res.id}`}
                    component={RouterLink}
                  >
                    {res.formNumber}
                  </Link>
                </TableCell>
                <TableCell>
                  <Link
                    variant="body1"
                    color="inherit"
                    to={`/management/customers/${res.customer.id}`}
                    component={RouterLink}
                  >
                    {res.customer.name}
                  </Link>
                </TableCell>
                <TableCell>
                  {moment(res.dateCreated).format('DD/MM/YYYY HH:mm', true)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </>
  );
};
export const SearchProductRes = ({ searchStr, setSearchQuery }) => {
  const classes = useStyles();
  const { loading, error, data } = useQuery(GET_PRODUCT, {
    variables: {
      str: searchStr
    },
    fetchPolicy: 'cache-and-network'
  });
  if (searchStr === '') return null;
  if (loading) return <LinearProgress className={classes.searchRes} />;
  if (error) return `Error! ${error}`;

  return (
    <>
      <Typography className={classes.searchRes}>Sản phẩm</Typography>
      <List>
        {data.searchProduct.map((res, index) => (
          <ListItem key={index}>
            <Link
              variant="body1"
              color="inherit"
              to={`/management/products/${res.id}`}
              component={RouterLink}
            >
              {res.name}
            </Link>
          </ListItem>
        ))}
        {data.searchProduct.length === 0 ? (
          <ListItem className={classes.notFound}>
            Không có sản phẩm thỏa yêu cầu
          </ListItem>
        ) : null}
      </List>
    </>
  );
};
export const SearchCustomerRes = ({ searchStr, setSearchQuery }) => {
  const classes = useStyles();
  const { loading, error, data } = useQuery(GET_CUSTOMER, {
    variables: {
      str: searchStr
    },
    fetchPolicy: 'cache-and-network'
  });
  if (searchStr === '') return null;
  if (loading) return <LinearProgress className={classes.searchRes} />;
  if (error) return `Error! ${error}`;

  return (
    <>
      <Typography className={classes.searchRes}>Khách hàng</Typography>
      <List>
        {data.searchCustomer.map((res, index) => (
          <ListItem key={index}>
            <Link
              variant="body1"
              color="inherit"
              to={`/management/customers/${res.id}`}
              component={RouterLink}
            >
              {res.name}
            </Link>
          </ListItem>
        ))}
        {data.searchCustomer.length === 0 ? (
          <ListItem className={classes.notFound}>
            Không có khách hàng thỏa yêu cầu
          </ListItem>
        ) : null}
      </List>
    </>
  );
};
