export class TimeProcessor {
  
  constructor(now) {
    this.currentTime = now ? now : new Date()
    this.year = this.currentTime.getFullYear()
    this.month = this.currentTime.getMonth()
    this.date = this.currentTime.getDate()
    this.day = this.currentTime.getDay() // 0 - Sunday
    this.currentDate = new Date(this.year, this.month, this.date, 0, 0, 0, 0)
  }
  
  getFirstDateOfYear() {
    return new Date(this.year, 1, 1)
  }
  
  getFirstDateOfMonth(){
    return new Date(this.year, this.month, 1, 0, 0, 0, 0)
  }
  
  getFirstDateOfWeek(){
    const gapDays = this.day > 0 ? this.day - 1 : 6
    return new Date(this.currentDate.getTime() - gapDays * 24 * 60 * 60 * 1000)
  }
  
  getNumberOfDaysAgo(numberOfDay) {
    return new Date(this.currentDate.getTime() - numberOfDay * 24 * 60 * 60 * 1000)
  }
  
  getTimeRange(mode){
    const endDate = this.currentTime.getTime() + 24 * 60 * 60 * 60 * 1000 - 1000
    let startDate;
    switch (mode) {
      case 'year':
        startDate = this.getFirstDateOfYear()
        break;
      case 'month':
        startDate = this.getFirstDateOfMonth()
        break;
      case 'week':
        startDate = this.getFirstDateOfWeek()
        break;
      default:
        startDate = this.currentTime
        break
    }
    
    return {startDate: startDate, endDate: endDate}
  }
}
