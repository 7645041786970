import { createSlice } from '@reduxjs/toolkit';
import {
  employeesCacheRef,
  customersCacheRef,
  productsCacheRef,
  productRef,
  saleOrderCacheRef,
  customersRef,
  ordersRef,
  companySalesAnalyticsRef,
  suppliersRef,
  quotesRef
} from '../../rolesbased-rules/rolesBasedQuery';
import history from '../../history';
import { transformDate } from '../../utils/formater';

const initialState = {
  saleOrders: {},
  productsDropdown: {},
  customersDropdown: {},
  currentSaleOrders: [],
  currentQuotes: [],
  currentProducts: [],
  currentCustomers: [],
  currentSuppliers: []
};

const slice = createSlice({
  name: 'cache',
  initialState,
  reducers: {
    setEmployeesDropdownCache(state, action) {
      const { employeesDropdown } = action.payload;
      state.employeesDropdown = employeesDropdown;
    },
    setCustomersDropdownCache(state, action) {
      const { customersDropdown } = action.payload;
      state.customersDropdown = customersDropdown;
    },
    setProductsDropdownCache(state, action) {
      const { productsDropdown } = action.payload;
      state.productsDropdown = productsDropdown;
    },
    setProductsFrom(state, action) {
      const { productsFrom } = action.payload;
      state.currentProducts = productsFrom;
    },
    deleteProductsFrom(state, action) {
      state.currentProducts = state.currentProducts.filter(
        (x) => !action.payload.includes(x.id)
      );
    },
    updateSaleOrderCacheById(state, action) {
      const { soCache } = action.payload;
      state.saleOrders = {
        ...state.saleOrders,
        [soCache.id]: soCache.detail
      };
    },
    setSaleOrderCacheById(state, action) {
      const { soCache } = action.payload;
      state.saleOrders = {
        ...state.saleOrders,
        [soCache.id]: soCache.detail
      };
    },
    setCustomersFrom(state, action) {
      const { customersFrom } = action.payload;
      state.currentCustomers = customersFrom;
    },
    setSaleOrderFrom(state, action) {
      const { saleOrderFrom } = action.payload;
      state.currentSaleOrders = [...state.currentSaleOrders, ...saleOrderFrom];
    },
    setAnalyticsSaleSummary(state, action) {
      const { saleSummary } = action.payload;
      state.saleSummary = saleSummary;
    },
    updateSaleOrdersFrom(state, action) {
      const { SO } = action.payload;
      state.currentSaleOrders = SO;
    },
    fetchQuoteFrom(state, action) {
      const { saleOrderFrom } = action.payload;
      state.currentQuotes = saleOrderFrom;
    },
    fetchSaleOrderFrom(state, action) {
      const { saleOrderFrom } = action.payload;
      state.currentSaleOrders = saleOrderFrom;
    },
    fetchSearchQuote(state, action) {
      const { SO } = action.payload;
      state.currentQuotes = SO;
    },
    fetchSearchSaleOrder(state, action) {
      const { SO } = action.payload;
      state.currentSaleOrders = SO;
    },
    sortSOListInStore(state, action) {
      const { sortedSO } = action.payload;
      state.currentSaleOrders = sortedSO;
    },
    deleteAllSO(state, action) {
      const { blank } = action.payload;
      state.currentSaleOrders = blank;
    },
    updateProductsFrom(state, action) {
      const { products } = action.payload;
      state.currentProducts = products;
    },
    fetchProductFrom(state, action) {
      const { products } = action.payload;
      state.currentProducts = products;
    },
    fetchSearchProduct(state, action) {
      const { products } = action.payload;
      state.currentProducts = products;
    },
    fetchCustomersFrom(state, action) {
      const { customers } = action.payload;
      state.currentCustomers = customers;
    },
    updateCustomersFrom(state, action) {
      const { customers } = action.payload;
      state.currentCustomers = customers;
    },
    fetchSearchCustomer(state, action) {
      const { customers } = action.payload;
      state.currentCustomers = customers;
    },
    setSuppliers(state, action) {
      const { suppliers } = action.payload;
      state.currentSuppliers = suppliers;
    }
  }
});

export const reducer = slice.reducer;

export const setEmployeesDropdownCache = () => (dispatch, getState) => {
  return employeesCacheRef.onSnapshot((doc) => {
    if (doc.data()) {
      dispatch(
        slice.actions.setEmployeesDropdownCache({
          employeesDropdown: doc.data()
        })
      );
    }
  });
};

export const setCustomersDropdownCache = () => (dispatch, getState) => {
  return customersCacheRef.onSnapshot((doc) => {
    if (doc.data()) {
      dispatch(
        slice.actions.setCustomersDropdownCache({
          customersDropdown: doc.data()
        })
      );
    }
  });
};

export const setProductsDropdownCache = () => (dispatch, getState) => {
  return productsCacheRef.onSnapshot((doc) => {
    if (doc.data()) {
      dispatch(
        slice.actions.setProductsDropdownCache({ productsDropdown: doc.data() })
      );
    }
  });
};

export const setProductsFrom = (offset, proPerPage) => (dispatch, getState) => {
  let proList = [];
  productRef
    .orderBy('id')
    .startAt(offset)
    .limit(proPerPage)
    .get()
    .then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        proList.push(transformDate(doc.data()));
      });
    })
    .then(() => {
      dispatch(slice.actions.setProductsFrom({ productsFrom: proList }));
    });
};

export const deleteProductsFrom = (ids) => (dispatch, getState) => {
  dispatch(slice.actions.deleteProductsFrom(ids));
};

export const updateSaleOrderCacheById =
  (sodetail) => async (dispatch, getState) => {
    dispatch(
      slice.actions.updateSaleOrderCacheById({
        soCache: {
          id: sodetail.id,
          detail: sodetail
        }
      })
    );
  };

export const setSaleOrderCacheById = (id) => async (dispatch, getState) => {
  let doc = await saleOrderCacheRef(id).get();
  if (doc.data()) {
    const data = doc.data();
    data.dateCreated =
      data.dateCreated.seconds * 1000 + data.dateCreated.nanoseconds / 1000000;
    data.dateModified =
      data.dateModified.seconds * 1000 +
      data.dateModified.nanoseconds / 1000000;
    dispatch(
      slice.actions.updateSaleOrderCacheById({
        soCache: {
          id: id,
          detail: data
        }
      })
    );
  } else {
    alert('Có lỗi khi lấy thông tin của đơn hàng ' + id.toString());
    history.push('/management/orders');
  }
};

export const setCustomersFrom =
  (offset, cusPerPage, order, field) => (dispatch, getState) => {
    let cusList = [];
    customersRef
      .orderBy(field, order)
      .startAt(offset)
      .limit(cusPerPage)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          cusList.push(doc.data());
        });
      })
      .then(() => {
        dispatch(slice.actions.setCustomersFrom({ customersFrom: cusList }));
      });
  };

export const fetchSearchProduct =
  (order, field, searchString, limit) => (dispatch, getState) => {
    let productList = [];

    const descendingComparator = (a, b, orderBy) => {
      if (b[orderBy] < a[orderBy]) {
        return -1;
      }

      if (b[orderBy] > a[orderBy]) {
        return 1;
      }

      return 0;
    };
    const getComparator = (order, orderBy) => {
      return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
    };

    productRef
      .where('name', '>=', searchString)
      .where('name', '<=', searchString + '\uf8ff')
      .limit(limit)
      .get()
      .then((snapShot) =>
        snapShot.forEach((doc) => {
          let data = doc.data();
          //Neu chua ton tai thi moi push
          let arr = getState().cache.currentProducts.filter(
            (pro) => pro.id === data.id
          );
          if (arr.length === 0) {
            productList.push(transformDate(data));
          }
        })
      )
      .then(() => {
        productRef
          .where('productCode', '>=', searchString)
          .where('productCode', '<=', searchString + '\uf8ff')
          .limit(limit)
          .get()
          .then((snapShot) =>
            snapShot.forEach((doc) => {
              let data = doc.data();
              //Neu chua ton tai thi moi push
              let arr = getState().cache.currentProducts.filter(
                (pro) => pro.id === data.id
              );
              if (arr.length === 0) {
                productList.push(transformDate(data));
              }
            })
          );
      })
      .then(() => {
        let arr = getState().cache.currentProducts.slice();
        let newProList = [...arr, ...productList];
        newProList.sort(getComparator(order, field));
        dispatch(slice.actions.fetchSearchProduct({ products: newProList }));
      });
  };

export const updateProductsFrom =
  (lastDocList, limit, order, field, page) => (dispatch, getState) => {
    const descendingComparator = (a, b, orderBy) => {
      let field = orderBy.split('.')[0];
      let innerField = orderBy.split('.')[1];

      if (field === 'nearestExpLot') {
        a = a[field];
        b = b[field];
        field = innerField;
      }

      //b O orderBy: doctor.name
      if (b[field] < a[field]) {
        return -1;
      }

      if (b[field] > a[field]) {
        return 1;
      }

      return 0;
    };
    const getComparator = (order, orderBy) => {
      return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
    };

    let proList = [];
    let query = null;

    if (page === 0) query = productRef.orderBy(field, order).limit(limit);
    else
      query = productRef
        .orderBy(field, order)
        .startAfter(lastDocList[page - 1])
        .limit(limit);

    query
      .get()
      .then((snapShot) => {
        snapShot.forEach((doc) => {
          proList.push(transformDate(doc.data()));
        });
      })
      .then(() => {
        let arr = getState().cache.currentProducts.slice();
        //update each element
        proList.forEach((ele) => {
          let idx = arr.findIndex((x) => x.id === ele.id);
          if (idx > -1) {
            arr[idx] = ele;
          } else {
            arr.push(ele);
          }
        });
        let tmp = [...arr];
        let newProList = [];
        tmp.forEach((ele) => {
          if (ele.stock === undefined) {
            ele.stock = 0;
          }
          if (ele.nearestExpLot === undefined) {
            ele.nearestExpLot = { expiredDate: 0 };
          }
          newProList.push(ele);
        });
        //sort lại data
        newProList.sort(getComparator(order, field));
        dispatch(slice.actions.updateProductsFrom({ products: newProList }));
      });
  };

export const fetchProductFrom = (lastDoc, limit, order, field, isFirstQuery, callBack) => (dispatch, getState) => {
  let productList = [];
  let query = null;

  // const descendingComparator = (a, b, orderBy) => {
  //     if (b[orderBy] < a[orderBy]) {
  //       return -1;
  //     }

  //     if (b[orderBy] > a[orderBy]) {
  //       return 1;
  //     }

  //     return 0;
  // };
  const descendingComparator = (a, b, orderBy) => {
    let field = orderBy.split('.')[0];
    let innerField = orderBy.split('.')[1];

    if (field === 'nearestExpLot') {
      a = a[field];
      b = b[field];
      field = innerField;
    }

    //b O orderBy: doctor.name
    if (b[field] < a[field]) {
      return -1;
    }

    if (b[field] > a[field]) {
      return 1;
    }

    return 0;
  };
  const getComparator = (order, orderBy) => {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  if (isFirstQuery) query = productRef.orderBy(field, order).limit(limit);
  else {
    if (lastDoc) {
      query = productRef
        .orderBy(field, order)
        .startAfter(lastDoc)
        .limit(limit);
    } else {
      query = productRef.orderBy(field, order).limit(2 * limit);
    }
  }

  query
    .get()
    .then((snapShot) => {
      snapShot.forEach((doc) => {
        let data = doc.data();
        //Neu chua ton tai thi moi push
        let arr = getState().cache.currentProducts.filter(
          (pro) => pro.id === data.id
        );
        if (arr.length === 0) {
          productList.push(transformDate(data));
        }
      });
      callBack(snapShot.docs[snapShot.docs.length - 1]);
    })
    .then(() => {
      let arr = getState().cache.currentProducts.slice();
      let tmp = [...arr, ...productList];
      let newProList = [];
      tmp.forEach((ele) => {
        if (ele.stock === undefined) {
          ele.stock = 0;
        }
        if (ele.nearestExpLot === undefined) {
          ele.nearestExpLot = { expiredDate: 0 };
        }
        newProList.push(ele);
      });
      newProList.sort(getComparator(order, field));
      dispatch(slice.actions.fetchProductFrom({ products: newProList }));
    });
};

export const fetchSearchCustomer =
  (order, field, searchString, limit) => (dispatch, getState) => {
    let customerList = [];

    const descendingComparator = (a, b, orderBy) => {
      if (b[orderBy] < a[orderBy]) {
        return -1;
      }

      if (b[orderBy] > a[orderBy]) {
        return 1;
      }

      return 0;
    };
    const getComparator = (order, orderBy) => {
      return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
    };

    customersRef
      .where('name', '>=', searchString)
      .where('name', '<=', searchString + '\uf8ff')
      .limit(limit)
      .get()
      .then((snapShot) =>
        snapShot.forEach((doc) => {
          let data = doc.data();
          //Neu chua ton tai thi moi push
          let arr = getState().cache.currentCustomers.filter(
            (cus) => cus.id === data.id
          );
          if (arr.length === 0) {
            customerList.push(transformDate(data));
          }
        })
      )
      .then(() => {
        customersRef
          .where('phoneNumber', '>=', searchString)
          .where('phoneNumber', '<=', searchString + '\uf8ff')
          .limit(limit)
          .get()
          .then((snapShot) =>
            snapShot.forEach((doc) => {
              let data = doc.data();
              //Neu chua ton tai thi moi push
              let arr = getState().cache.currentCustomers.filter(
                (cus) => cus.id === data.id
              );
              if (arr.length === 0) {
                customerList.push(transformDate(data));
              }
            })
          );
      })
      .then(() => {
        let arr = getState().cache.currentCustomers.slice();
        let newCusList = [...arr, ...customerList];
        newCusList.sort(getComparator(order, field));
        dispatch(slice.actions.fetchSearchCustomer({ customers: newCusList }));
      });
  };

export const updateCustomersFrom =
  (lastDocList, limit, order, field, page) => (dispatch, getState) => {
    const descendingComparator = (a, b, orderBy) => {
      if (b[orderBy] < a[orderBy]) {
        return -1;
      }

      if (b[orderBy] > a[orderBy]) {
        return 1;
      }

      return 0;
    };
    const getComparator = (order, orderBy) => {
      return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
    };

    let cusList = [];
    let query = null;

    if (page === 0) query = customersRef.orderBy(field, order).limit(limit);
    else
      query = customersRef
        .orderBy(field, order)
        .startAfter(lastDocList[page - 1])
        .limit(limit);

    query
      .get()
      .then((snapShot) => {
        snapShot.forEach((doc) => {
          cusList.push(transformDate(doc.data()));
        });
      })
      .then(() => {
        let arr = getState().cache.currentCustomers.slice();
        //update each element
        cusList.forEach((ele) => {
          let idx = arr.findIndex((x) => x.id === ele.id);
          if (idx > -1) {
            arr[idx] = ele;
          } else {
            arr.push(ele);
          }
        });
        //sort lại data
        arr.sort(getComparator(order, field));
        dispatch(slice.actions.updateCustomersFrom({ customers: arr }));
      });
  };

export const fetchCustomersFrom =
  (lastDoc, limit, order, field, isFirstQuery, callBack) =>
    (dispatch, getState) => {
      let cusList = [];
      let query = null;

      const descendingComparator = (a, b, orderBy) => {
        if (b[orderBy] < a[orderBy]) {
          return -1;
        }

        if (b[orderBy] > a[orderBy]) {
          return 1;
        }

        return 0;
      };
      const getComparator = (order, orderBy) => {
        return order === 'desc'
          ? (a, b) => descendingComparator(a, b, orderBy)
          : (a, b) => -descendingComparator(a, b, orderBy);
      };

      if (isFirstQuery) query = customersRef.orderBy(field, order).limit(limit);
      else {
        if (lastDoc) {
          query = customersRef
            .orderBy(field, order)
            .startAfter(lastDoc)
            .limit(limit);
        } else {
          query = customersRef.orderBy(field, order).limit(2 * limit);
        }
      }

      query
        .get()
        .then((snapShot) => {
          snapShot.forEach((doc) => {
            let data = doc.data();
            //Neu chua ton tai thi moi push
            let arr = getState().cache.currentCustomers.filter(
              (cus) => cus.id === data.id
            );
            if (arr.length === 0) {
              cusList.push(transformDate(data));
            }
          });
          callBack(snapShot.docs[snapShot.docs.length - 1]);
        })
        .then(() => {
          let arr = getState().cache.currentCustomers.slice();
          let newCusList = [...arr, ...cusList];
          newCusList.sort(getComparator(order, field));
          dispatch(slice.actions.fetchCustomersFrom({ customers: newCusList }));
        });
    };

export const fetchSearchQuote =
  (order, field, searchString, limit) => (dispatch, getState) => {
    let SOList = [];

    // const descendingComparator = (a, b, orderBy) => {
    //     if (b[orderBy] < a[orderBy]) {
    //       return -1;
    //     }

    //     if (b[orderBy] > a[orderBy]) {
    //       return 1;
    //     }

    //     return 0;
    // };
    const descendingComparator = (a, b, orderBy) => {
      let field = orderBy.split('.')[0];
      let innerField = orderBy.split('.')[1];

      if (
        field === 'createdBy' ||
        field === 'customer' ||
        field === 'doctor' ||
        field === 'salesRep'
      ) {
        a = a[field];
        b = b[field];
        field = innerField;
      }

      //b O orderBy: doctor.name
      if (b[field] < a[field]) {
        return -1;
      }

      if (b[field] > a[field]) {
        return 1;
      }

      return 0;
    };
    const getComparator = (order, orderBy) => {
      return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
    };

    quotesRef
      .where('customer.name', '==', searchString)
      .limit(limit)
      .get()
      .then((snapShot) =>
        snapShot.forEach((doc) => {
          let data = doc.data();
          //Neu chua ton tai thi moi push
          let arr = getState().cache.currentQuotes.filter(
            (SO) => SO.id === data.id
          );
          if (arr.length === 0) {
            SOList.push(transformDate(data));
          }
        })
      )
      .then(() => {
        quotesRef
          .where('formNumber', '==', searchString)
          .limit(limit)
          .get()
          .then((snapShot) =>
            snapShot.forEach((doc) => {
              let data = doc.data();
              //Neu chua ton tai thi moi push
              let arr = getState().cache.currentQuotes.filter(
                (SO) => SO.id === data.id
              );
              if (arr.length === 0) {
                SOList.push(transformDate(data));
              }
            })
          );
      })
      .then(() => {
        let arr = getState().cache.currentQuotes.slice();
        let newSOList = [...arr, ...SOList];
        newSOList.sort(getComparator(order, field));
        dispatch(slice.actions.fetchSearchQuote({ SO: newSOList }));
      });
  };

export const fetchSearchSaleOrder =
  (order, field, searchString, limit) => (dispatch, getState) => {
    let SOList = [];

    // const descendingComparator = (a, b, orderBy) => {
    //     if (b[orderBy] < a[orderBy]) {
    //       return -1;
    //     }

    //     if (b[orderBy] > a[orderBy]) {
    //       return 1;
    //     }

    //     return 0;
    // };
    const descendingComparator = (a, b, orderBy) => {
      let field = orderBy.split('.')[0];
      let innerField = orderBy.split('.')[1];

      if (
        field === 'createdBy' ||
        field === 'customer' ||
        field === 'doctor' ||
        field === 'salesRep'
      ) {
        a = a[field];
        b = b[field];
        field = innerField;
      }

      //b O orderBy: doctor.name
      if (b[field] < a[field]) {
        return -1;
      }

      if (b[field] > a[field]) {
        return 1;
      }

      return 0;
    };
    const getComparator = (order, orderBy) => {
      return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
    };

    ordersRef
      .where('customer.name', '==', searchString)
      .limit(limit)
      .get()
      .then((snapShot) =>
        snapShot.forEach((doc) => {
          let data = doc.data();
          //Neu chua ton tai thi moi push
          let arr = getState().cache.currentSaleOrders.filter(
            (SO) => SO.id === data.id
          );
          if (arr.length === 0) {
            SOList.push(transformDate(data));
          }
        })
      )
      .then(() => {
        ordersRef
          .where('formNumber', '==', searchString)
          .limit(limit)
          .get()
          .then((snapShot) =>
            snapShot.forEach((doc) => {
              let data = doc.data();
              //Neu chua ton tai thi moi push
              let arr = getState().cache.currentSaleOrders.filter(
                (SO) => SO.id === data.id
              );
              if (arr.length === 0) {
                SOList.push(transformDate(data));
              }
            })
          );
      })
      .then(() => {
        let arr = getState().cache.currentSaleOrders.slice();
        let newSOList = [...arr, ...SOList];
        newSOList.sort(getComparator(order, field));
        dispatch(slice.actions.fetchSearchSaleOrder({ SO: newSOList }));
      });
  };

export const updateSaleOrdersFrom =
  (lastDocList, limit, order, field, page) => (dispatch, getState) => {
    const descendingComparator = (a, b, orderBy) => {
      let field = orderBy.split('.')[0];
      let innerField = orderBy.split('.')[1];

      if (
        field === 'createdBy' ||
        field === 'customer' ||
        field === 'doctor' ||
        field === 'salesRep'
      ) {
        a = a[field];
        b = b[field];
        field = innerField;
      }

      //b O orderBy: doctor.name
      if (b[field] < a[field]) {
        return -1;
      }

      if (b[field] > a[field]) {
        return 1;
      }

      return 0;
    };
    const getComparator = (order, orderBy) => {
      return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
    };

    let SOList = [];
    let query = null;

    if (page === 0) query = ordersRef.orderBy(field, order).limit(limit);
    else
      query = ordersRef
        .orderBy(field, order)
        .startAfter(lastDocList[page - 1])
        .limit(limit);

    query
      .get()
      .then((snapShot) => {
        snapShot.forEach((doc) => {
          SOList.push(transformDate(doc.data()));
        });
      })
      .then(() => {
        let arr = getState().cache.currentSaleOrders.slice();
        //update each element
        SOList.forEach((ele) => {
          let idx = arr.findIndex((x) => x.id === ele.id);
          if (idx > -1) {
            arr[idx] = ele;
          } else {
            arr.push(ele);
          }
        });
        //sort lại data
        arr.sort(getComparator(order, field));
        dispatch(slice.actions.updateSaleOrdersFrom({ SO: arr }));
      });
  };

export const fetchQuoteFrom =
  (lastDoc, limit, order, field, isFirstQuery, callBack) =>
    (dispatch, getState) => {
      let orderList = [];
      let query = null;
      let last = null;

      const descendingComparator = (a, b, orderBy) => {
        let field = orderBy.split('.')[0];
        let innerField = orderBy.split('.')[1];

        if (
          field === 'createdBy' ||
          field === 'customer' ||
          field === 'doctor' ||
          field === 'salesRep'
        ) {
          a = a[field];
          b = b[field];
          field = innerField;
        }

        //b O orderBy: doctor.name
        if (b[field] < a[field]) {
          return -1;
        }

        if (b[field] > a[field]) {
          return 1;
        }

        return 0;
      };
      const getComparator = (order, orderBy) => {
        return order === 'desc'
          ? (a, b) => descendingComparator(a, b, orderBy)
          : (a, b) => -descendingComparator(a, b, orderBy);
      };

      //getState().cache
      if (isFirstQuery) query = quotesRef.orderBy(field, order).limit(limit);
      else {
        if (lastDoc) {
          query = quotesRef
            .orderBy(field, order)
            .startAfter(lastDoc)
            .limit(limit);
        } else {
          query = quotesRef.orderBy(field, order).limit(2 * limit);
        }
      }

      query
        .get()
        .then((snapShot) => {
          snapShot.forEach((doc) => {
            let data = doc.data();
            //Neu chua ton tai thi moi push
            let arr = getState().cache.currentQuotes.filter(
              (SO) => SO.id === data.id
            );
            if (arr.length === 0) {
              orderList.push(transformDate(data));
            }
            last = data[field];
          });
          //last = snapShot.docs[snapShot.docs.length-1].data()[field];
          callBack(snapShot.docs[snapShot.docs.length - 1]);
        })
        .then(() => {
          let arr = getState().cache.currentQuotes.slice();
          let newSOList = [...arr, ...orderList];
          newSOList.sort(getComparator(order, field));
          dispatch(slice.actions.fetchQuoteFrom({ saleOrderFrom: newSOList }));
        });
    };

export const fetchSaleOrderFrom =
  (lastDoc, limit, order, field, isFirstQuery, callBack) =>
    (dispatch, getState) => {
      let orderList = [];
      let query = null;
      let last = null;

      const descendingComparator = (a, b, orderBy) => {
        let field = orderBy.split('.')[0];
        let innerField = orderBy.split('.')[1];

        if (
          field === 'createdBy' ||
          field === 'customer' ||
          field === 'doctor' ||
          field === 'salesRep'
        ) {
          a = a[field];
          b = b[field];
          field = innerField;
        }

        //b O orderBy: doctor.name
        if (b[field] < a[field]) {
          return -1;
        }

        if (b[field] > a[field]) {
          return 1;
        }

        return 0;
      };
      const getComparator = (order, orderBy) => {
        return order === 'desc'
          ? (a, b) => descendingComparator(a, b, orderBy)
          : (a, b) => -descendingComparator(a, b, orderBy);
      };

      //getState().cache
      if (isFirstQuery) query = ordersRef.orderBy(field, order).limit(limit);
      else {
        if (lastDoc) {
          query = ordersRef
            .orderBy(field, order)
            .startAfter(lastDoc)
            .limit(limit);
        } else {
          query = ordersRef.orderBy(field, order).limit(2 * limit);
        }
      }

      query
        .get()
        .then((snapShot) => {
          snapShot.forEach((doc) => {
            let data = doc.data();
            //Neu chua ton tai thi moi push
            let arr = getState().cache.currentSaleOrders.filter(
              (SO) => SO.id === data.id
            );
            if (arr.length === 0) {
              orderList.push(transformDate(data));
            }
            last = data[field];
          });
          //last = snapShot.docs[snapShot.docs.length-1].data()[field];
          callBack(snapShot.docs[snapShot.docs.length - 1]);
        })
        .then(() => {
          let arr = getState().cache.currentSaleOrders.slice();
          let newSOList = [...arr, ...orderList];
          newSOList.sort(getComparator(order, field));
          dispatch(
            slice.actions.fetchSaleOrderFrom({ saleOrderFrom: newSOList })
          );
        });
    };

//Delete all currentSO
export const deleteAllSO = () => (dispatch, getState) => {
  dispatch(slice.actions.deleteAllSO({ blank: [] }));
};

//FOR Sorting SO -Test
export const sortSOListInStore = (sort) => (dispatch, getState) => {
  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }

    if (b[orderBy] > a[orderBy]) {
      return 1;
    }

    return 0;
  };
  const getComparator = (order, orderBy) => {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };
  let arr = getState().cache.currentSaleOrders.slice();
  arr.sort(getComparator(sort.split('|')[1], sort.split('|')[0]));
  dispatch(slice.actions.sortSOListInStore({ sortedSO: arr }));
};
/////////////////////////
export const setSaleOrderFrom =
  (offset, orderPerPage, order) => (dispatch, getState) => {
    let orderList = [];
    ordersRef
      .orderBy('formNumber', order)
      .startAt(offset)
      .limit(orderPerPage)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          orderList.push(transformDate(doc.data()));
        });
      })
      .then(() => {
        dispatch(slice.actions.setSaleOrderFrom({ saleOrderFrom: orderList }));
      });
  };

export const setAnalyticsSaleSummary = () => (dispatch, getState) => {
  return companySalesAnalyticsRef.onSnapshot((doc) => {
    if (doc.data()) {
      dispatch(
        slice.actions.setAnalyticsSaleSummary({ saleSummary: doc.data() })
      );
    }
  });
};

export const setSuppliers = () => (dispatch, getState) => {
  let suppliers = [];
  suppliersRef
    .get()
    .then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        suppliers.push(doc.data());
      });
    })
    .then(() => {
      dispatch(slice.actions.setSuppliers({ suppliers: suppliers }));
    });
};

export default slice;
