import { createSlice } from '@reduxjs/toolkit'
import queries, {
  db, productRef, roleRef, rolesRef,
  serverTime
} from '../../rolesbased-rules/rolesBasedQuery'
import {
  transformDate,
  transformToFirebaseTimestamp
} from '../../utils/formater'
import store from '../index'
import { apolloClient } from '../../contexts/graphqlContext'
import { gql } from '@apollo/client'
import { getComparator } from '../../utils/queryUtils'
import _ from 'lodash'

const initialState = {
  totalRole: {
    CEO: -1,
    ADMIN: -1,
    WAREHOUSE_MANAGER: -1,
    SALES_REP: -1,
    SALES_MANAGER: -1,
    DOCTOR_MANAGER: -1,
    DOCTOR: -1
  },
  roles: {},
  localRoleQL: {
  },
}

export const slice = createSlice({
  name: 'roles',
  initialState,
  reducers: {
    fetchRoles(state, action) {
      state.roles = {
        ...state.roles,
        ..._.mapKeys(action.payload, 'id')
      };
    },
    createRoleAction (state, action) {
      state.roles = {
        ...state.roles,
        [action.payload.id]: action.payload
      }
    },
    deleteRole(state, action) {
      state.roles = _.omit(state.roles, action.payload);
    },
    updateRoleAction (state, action) {
      state.roles = {
        ...state.roles,
        [action.payload.id]: action.payload
      }
    },
    updateLocalRole(state, action) {
      const { localRoleQL, role } = action.payload;
      state.localRoleQL = {
        ...state.localRoleQL,
        [role]: localRoleQL
      };
    },
    insertLocalRole(state, action) {
      const { localRoleQL, role } = action.payload;
      state.localRoleQL[role] = [localRoleQL, ...state.localRoleQL[role]];
    },
    updateTotalRole(state, action) {
      const { totalRole, role } = action.payload;
      state.totalRole = {
        ...state.totalRole,
        [role]: totalRole
      };
    },
    addRolePermissions(state, action) {
      state.roles = {
        ...state.roles,
        [action.payload.id]: {
          ...state.roles[action.payload.id],
          permissions: [
            ...state.roles[action.payload.id].permissions ?
              state.roles[action.payload.id].permissions
                .filter(x =>
                  !action.payload.permissions.includes(x)
                )
              : [],
            ...action.payload.permissions
          ]
        }
      }
    },
    removeRolePermissions(state, action) {
      state.roles = {
        ...state.roles,
        [action.payload.id]: {
          ...state.roles[action.payload.id],
          permissions: state.roles[action.payload.id].permissions ?
            state.roles[action.payload.id].permissions
              .filter(x =>
                !action.payload.permissions.includes(x)
              )
            : []
        }
      }
    }
  }
})

export const reducer = slice.reducer


export const fetchRoles = () => async (dispatch, getState) => {
  const snap = await rolesRef.get();
  const role = [];
  snap.forEach((doc) => {
    role.push(transformDate(doc.data()));
  });
  dispatch(slice.actions.fetchRoles(role));
};

export const deleteRole = (id) => async (dispatch, getState) => {
  await rolesRef.doc(id).delete();

  dispatch(slice.actions.deleteRole(id));
};


export const createRoleAction = data => async (dispatch, getState) => {
  const { currentRole } = getState().user
  const { rolesCreateRef } = queries[currentRole]

  const role = data.role
  const id = role.role
  const newRoleRef = rolesCreateRef().doc(id)
  const timestamp = serverTime()

  const transformData = transformToFirebaseTimestamp(role)
  const role_data = {
    id,
    ...transformData,
    permissions: [],
    accessLevel: "SALES_REP",
    dateCreated: timestamp,
    dataModified: timestamp
  }
  await newRoleRef.set(role_data)
  const store_data = {
    ...role_data,
    dateCreated: Date.now(),
    dataModified: Date.now()
  }
  dispatch(slice.actions.createRoleAction(store_data))
  dispatch(slice.actions.insertLocalRole({localRoleQL:store_data, role:currentRole}))
}

export const updateRoleAction = data => async (dispatch, getState) => {
  const { currentRole } = getState().user
  const { roleRef } = queries[currentRole]
  const transformData = transformToFirebaseTimestamp(data.role)
  const role_data = {
    ...transformData,
    dataModified: serverTime()
  }
  await roleRef(data.role.id).update(role_data)
  const store_data = {
    ...role_data,
    dataModified: Date.now()
  }
  dispatch(slice.actions.updateRoleAction(store_data))
  dispatch(slice.actions.insertLocalRole({localRoleQL:store_data, role:currentRole}))
}

export const addRolePermissions = (role_id, added_permissions, removed_permissions, access_level) => async (dispatch) => {
  const batch = db().batch()

  if (added_permissions && added_permissions.length > 0) {
    batch.update(roleRef.doc(role_id), {permissions: db.FieldValue.arrayUnion(...added_permissions)})
  }
  if (removed_permissions && removed_permissions.length > 0) {
    batch.update(roleRef.doc(role_id), {permissions: db.FieldValue.arrayRemove(...removed_permissions)})
  }
  if (access_level) {
    batch.update(roleRef.doc(role_id), {accessLevel: access_level})
  }
  await batch.commit()

  dispatch(slice.actions.addRolePermissions({ id: role_id, permissions: added_permissions }))
  dispatch(slice.actions.removeRolePermissions({ id: role_id, permissions: removed_permissions }))
}

////// FOR GRAPH DB //////////
const fetchTotalRoleFromDB = async (employeeId, accessLevel, searchField) => {
  const user = store.getState().user.user;
  const company = store.getState().company.company;
  const client = apolloClient({
    authorization: `Bearer ${user.token}`,
    database: company.domain
  });
  const response = await client.query({
    fetchPolicy: 'network-only',
    query: gql`
        query fetchTotalRoleByRole($employeeId: String, $accessLevel: String, $searchField: String) {
            fetchTotalRoleByRole(
                employeeId: $employeeId, accessLevel: $accessLevel,  searchField: $searchField
            ){
                status,
                cnt
            }
        }
    `,
    variables: {
      employeeId: employeeId,
      accessLevel,
      searchField: searchField
    }
  });
  return response.data.fetchTotalRoleByRole;
}

export const fetchTotalRoleGraph = (
  employeeId, accessLevel, status, searchField, setLoading
) => async (dispatch, getState) => {
  setLoading(true);
  let data = await fetchTotalRoleFromDB(employeeId, accessLevel, searchField)

  let totalRoleByCurrentRole = -1
  if (status === null) {
    totalRoleByCurrentRole = data.reduce((prev, cur) => prev + cur.cnt, 0)
  } else {
    const totalRoleObjAr = data.filter(ele => ele.status === status)
    if (totalRoleObjAr.length > 0) totalRoleByCurrentRole = totalRoleObjAr[0].cnt
  }

  dispatch(slice.actions.updateTotalRole({ totalRole: totalRoleByCurrentRole, role: accessLevel }))
  setLoading(false)
  return data
};

const fetchRoleFromDB = async (
  employeeId, accessLevel, sort, status, searchField, limit, skip, getState
) => {
  let [field, order] = sort.split('|');
  const user = getState().user.user;
  const company = getState().company.company;
  const client = apolloClient({
    authorization: `Bearer ${user.token}`,
    database: company.domain
  });
  return await client.query({
    fetchPolicy: 'network-only',
    query: gql`
        query fetchRole(
            $employeeId: String, $accessLevel: String, $propName: String,
            $limit: Int!, $searchField: String, $skip: Int!, $status: String
        ){
            ${order === 'desc' ? 'fetchRolesByRoleDesc' : 'fetchRolesByRoleAsc'}
            (employeeId: $employeeId, accessLevel: $accessLevel, propName: $propName,
            status: $status, searchField: $searchField, limit: $limit, skip: $skip)
        {
            id
            displayName
            role
            status
            dateCreated
            description
            employees{id, name}
        }
        }
    `,
    variables: {
      employeeId: employeeId,
      accessLevel,
      skip: skip,
      limit: limit,
      propName: field,
      quoteStatus: status,
      searchField: searchField
    }
  });
};

export const fetchRoleGraph = (
  employeeId, accessLevel, sort, status, searchField,
  limit, skip, listField, setLoading, fromSort = false
) => async (dispatch, getState) => {
  setLoading(true);
  let [field, order] = sort.split('|');

  const role = getState().user.currentRole;

  const response = await fetchRoleFromDB(
    employeeId, accessLevel, sort, status, searchField, limit, skip, getState);

  let data = order === 'desc' ? response.data.fetchRolesByRoleDesc : response.data.fetchRolesByRoleAsc;

  if (fromSort) {
    const arr = data.slice().sort(getComparator(order, field, listField));
    await dispatch(slice.actions.updateLocalRole({ localRoleQL: arr, role: role }));
  } else {
    let arr = getState().roles.localRoleQL[role] ?? [];
    arr = arr.slice();

    console.log("<<<<<< OBJ COUNT")
    console.log(arr.length)

    data.forEach((ele) => {
      let have = arr.find((x) => x.id === ele.id);
      if (!have) {
        arr.push(ele);
      }
    });
    arr.sort(getComparator(order, field, listField));
    await dispatch(slice.actions.updateLocalRole({ localRoleQL: arr, role: role }));
  }

  setLoading(false);
};

export const insertLocalRole = (obj) => (dispatch, getState) => {
  const role = getState().user.currentRole;
  dispatch(slice.actions.insertLocalRole({localRoleQL: obj, role}))
}

export const updateRoleGraph = (
  employeeId, accessLevel, sort, status, searchField, limit, page, listField
) => async (dispatch, getState) => {
  const [field, order] = sort.split('|');
  const skip = page * limit;

  const role = getState().user.currentRole;
  const response = await fetchRoleFromDB(
    employeeId, accessLevel, sort, status, searchField, limit, skip, getState
  );

  let data = order === 'desc' ? response.data.fetchRolesByRoleDesc : response.data.fetchRolesByRoleAsc;
  let arr = getState().roles.localRoleQL[role] ?? [];
  arr = arr.slice();

  console.log("<<<<<< OBJ COUNT")
  console.log(arr.length)

  data.forEach((ele) => {
    let idx = arr.findIndex((x) => x.id === ele.id);
    if (idx > -1) {
      arr[idx] = ele;
    } else {
      arr.push(ele);
    }
  });

  arr.sort(getComparator(order, field, listField));
  dispatch(slice.actions.updateLocalRole({ localRoleQL: arr, role: role }));
};

export default slice
