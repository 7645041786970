import { useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Avatar, Box, ButtonBase, Hidden, Menu, MenuItem, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import useAuth from 'src/hooks/useAuth';

const useStyles = makeStyles((theme) => ({
  avatar: {
    height: 32,
    width: 32,
    marginRight: theme.spacing(1)
  },
  popover: {
    width: 200
  }
}));

const Account = () => {
  const classes = useStyles();
  const history = useHistory();
  const ref = useRef(null);
  const { user, logout } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    try {
      handleClose();
      await logout();
      history.push('/');
    } catch (err) {
      console.error(err);
      enqueueSnackbar('Unable to logout', {
        variant: 'error'
      });
    }
  };

  return <>
    <Box
      display="flex"
      alignItems="center"
      component={ButtonBase}
      onClick={handleOpen}
      ref={ref}
    >
      <Avatar alt="User" className={classes.avatar} src={user.avatar} />
      <Hidden lgDown>
        <Typography variant="h6" color="inherit">
          {user.name}
        </Typography>
      </Hidden>
    </Box>
    <Menu
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      keepMounted
      PaperProps={{ className: classes.popover }}
      getcontentanchorel={null}
      anchorEl={ref.current}
      open={isOpen}
    >
      {/* <MenuItem
        component={RouterLink}
        to="/social/profile"
      >
        Profile
      </MenuItem> */}
      <MenuItem component={RouterLink} to="/account">
        Tài khoản
      </MenuItem>
      <MenuItem onClick={handleLogout}>Đăng xuất</MenuItem>
    </Menu>
  </>;
};

export default Account;
