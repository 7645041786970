import { createSlice } from '@reduxjs/toolkit';
import {
  blogRef,
  webpagesRef,
} from '../../rolesbased-rules/rolesBasedQuery';
import history from '../../history';
import {
  fetchTotalBlogGraphQL,
  fetchBlogGraphQL,
  fetchTotalWebpageGraphQL,
  fetchWebpageGraphQL
} from 'src/rolesbased-rules/GQL/queries';
import firebase from 'src/lib/firebase';

const initialState = {
  webpages: {},
  totalWebpage: {
    CEO: -1,
    ADMIN: -1,
    WAREHOUSE_MANAGER: -1,
    SALES_REP: -1,
    SALES_MANAGER: -1,
    DOCTOR_MANAGER: -1,
    DOCTOR: -1
  },
  localWebpage:{},
  blogs: {},
  totalBlog: {
    CEO: -1,
    ADMIN: -1,
    WAREHOUSE_MANAGER: -1,
    SALES_REP: -1,
    SALES_MANAGER: -1,
    DOCTOR_MANAGER: -1,
    DOCTOR: -1
  },
  localBlog: {
  },
};

const slice = createSlice({
  name: 'cache',
  initialState,
  reducers: {
    setWebpages(state, action) {
      const { webpages } = action.payload;
      state.webpages = webpages;
    },
    addWebpage(state, action) {
      const webpage = action.payload;
      state.webpages = {
        ...state.webpages,
        [webpage.id]: webpage
      };
    },
    updateWebpageCacheById(state, action) {
      const webpage = action.payload;
      state.webpages = {
        ...state.webpages,
        [webpage.id]: webpage
      };
    },
    updateLocalWebpage(state, action) {
      const { localWebpage, role } = action.payload;
      state.localWebpage = {
        ...state.localWebpage,
        [role]: localWebpage
      };
    },
    updateTotalWebpage(state, action) {
      const { totalWebpage, role } = action.payload;
      state.totalWebpage = {
        ...state.totalWebpage,
        [role]: totalWebpage
      };
    },
    updateBlogCacheById(state, action) {
      const { blog } = action.payload;
      state.blogs = {
        ...state.blogs,
        [blog.id]: blog
      };
    },
    updateTotalBlog(state, action) {
      const { totalBlog, role } = action.payload;
      state.totalBlog = {
        ...state.totalBlog,
        [role]: totalBlog
      };
    },
    updateLocalBlog(state, action) {
      const { localBlog, role } = action.payload;
      state.localBlog = {
        ...state.localBlog,
        [role]: localBlog
      };
    }
  }
});

export const reducer = slice.reducer;

export const getAllWebpages = () => (dispatch, getState) => {
  const domain = getState().company.company.domain;
  webpagesRef(domain).get().then((querySnapshot) => {
    const webpages = {};
    querySnapshot.forEach((doc) => {
      webpages[doc.id] = {id: doc.id, ...doc.data()}
    });
    if (webpages.length === 0) {
      // alert("Trang web của bạn đang thiếu landing page, vui lòng liên hệ admin");
    }
    dispatch(slice.actions.setWebpages({ webpages: webpages }));
  })
}

export const addWebpage = (webpage) => (dispatch, getState) => {
  dispatch(slice.actions.addWebpage({id: webpage.hostName, ...webpage}))
}

export const updateWebpage = webpage => async (dispatch, getState) => {
  await dispatch(slice.actions.updateWebpageCacheById(webpage));
  const role = getState().user.currentRole;
  const webpageState = getState().blog

  if (!role) {
    await dispatch(slice.actions.updateTotalWebpage({ totalWebpage: webpageState.totalWebpage[role] + 1, role: role }));
    await dispatch(slice.actions.updateLocalWebpage({ localWebpage: [...webpageState.localWebpage[role]], role: role }));
  }
  else {
    await dispatch(slice.actions.updateTotalWebpage({ totalWebpage: 1, role: role }));
    await dispatch(slice.actions.updateLocalWebpage({ localWebpage: [webpage], role: role }));
  }
};

export const fetchTotalWebpageGraph = (employeeId, accessLevel, status, searchField, setLoading, employeeIdLst = null, customerIdLst = null, timeRange = { startTime: null, endTime: null }) => async (dispatch, getState) => {
  console.log('fetch total')
  setLoading(true);
  const token = getState().user.user.token;
  const domain = getState().company.company.domain;

  let data = await fetchTotalWebpageGraphQL(employeeId, accessLevel, token, domain);

  let totalPageByCurrentRole = -1;
  if (status === null) {
    totalPageByCurrentRole = data.reduce((prev, cur) => prev + cur.cnt, 0);
  } else {
    const totalPageObjAr = data.filter(ele => ele.status === status);
    if (totalPageObjAr.length > 0) totalPageByCurrentRole = totalPageObjAr[0].cnt;
  }

  dispatch(slice.actions.updateTotalWebpage({ totalWebpage: totalPageByCurrentRole, role: accessLevel }));
  setLoading(false);
  return data;
};

export const fetchWebpageGraph = (employeeId, accessLevel, sort, status, searchField, limit, skip, listField, setLoading, fromSort = false, employeeIdLst = null, customerIdLst = null, timeRange = { startTime: null, endTime: null }) => async (dispatch, getState) => {
  console.log('fetch')
  setLoading(true);
  let [field, order] = sort.split('|');

  const role = getState().user.currentRole;
  const token = getState().user.user.token;
  const domain = getState().company.company.domain;

  const data = await fetchWebpageGraphQL(employeeId, accessLevel, sort, status, searchField, limit, skip, token, domain);

  if (fromSort) {
    const arr = data.slice().map((page) => {
      return {
        ...page,
        introduction: JSON.parse(page.introduction),
        mainCarousel: JSON.parse(page.mainCarousel),
        dealOfDay: JSON.parse(page.dealOfDay)
      }
    }).sort(getComparator(order, field, listField));
    await dispatch(slice.actions.updateLocalWebpage({ localWebpage: arr, role: role }));
  } else {
    let arr = getState().blog.localWebpage[role] ?? []
    arr = arr.slice();
    data.forEach((ele) => {
      let have = arr.find((x) => x.id === ele.id);
      if (!have) {
        arr.push({...ele, 
          introduction: JSON.parse(ele.introduction),
          mainCarousel: JSON.parse(ele.mainCarousel),
          dealOfDay: JSON.parse(ele.dealOfDay)}
        );
      }
    });
    arr.sort(getComparator(order, field, listField));
    await dispatch(slice.actions.updateLocalWebpage({ localWebpage: arr, role: role }));
  }

  setLoading(false);
};

export const updateWebpageGraph = (employeeId, accessLevel, sort, status, searchField, limit, page, listField, employeeIdLst = null, customerIdLst = null, timeRange = { startTime: null, endTime: null }) => async (dispatch, getState) => {
  const [field, order] = sort.split('|');
  const skip = page * limit;

  const role = getState().user.currentRole;
  const token = getState().user.user.token;
  const domain = getState().company.company.domain;
  const data = await fetchWebpageGraphQL(employeeId, accessLevel, sort, status, searchField, limit, skip, token, domain);

  let arr = getState().blog.localWebpage[role] ?? []
  arr = arr.slice();

  data.forEach((ele) => {
    if (typeof ele.introduction === 'string' || typeof ele.dealOfDay === 'string' || typeof ele.mainCarousel === 'string'){
      ele = {...ele, 
        introduction: JSON.parse(ele.introduction),
        mainCarousel: JSON.parse(ele.mainCarousel),
        dealOfDay: JSON.parse(ele.dealOfDay)
      }
    }
    let idx = arr.findIndex((x) => x.id === ele.id);
    if (idx > -1) {
      arr[idx] = ele;
    } else {
      arr.push(ele);
    }
  });

  arr.sort(getComparator(order, field, listField));
  dispatch(slice.actions.updateLocalWebpage({ localWebpage: arr, role: role }));
};

const descendingComparator = (a, b, orderBy, listField) => {
  let field = orderBy.split('.')[0]; //1 biến chính là orderby
  let innerField = orderBy.split('.')[1];

  if (listField.includes(field)) {
    a = a[field];
    b = b[field];
    field = innerField;
  }

  //b O orderBy: doctor.name
  if (b[field] < a[field]) {
    return -1;
  }

  if (b[field] > a[field]) {
    return 1;
  }

  return 0;
};

const getComparator = (order, orderBy, listField) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy, listField)
    : (a, b) => -descendingComparator(a, b, orderBy, listField);
};

export const setBlogCacheById = (id) => async (dispatch, getState) => {
  const domain = getState().company.company.domain;

  let doc = await blogRef(domain, id).get();

  if (doc.data()) {
    const blog = doc.data();
    blog.dateCreated = blog.dateCreated.seconds * 1000 + blog.dateCreated.nanoseconds / 1000000;
    blog.dateModified = blog.dateModified.seconds * 1000 + blog.dateModified.nanoseconds / 1000000;

    if (blog.thumnail !== '') {
      blog.thumnailUrl = await firebase.storage().ref(blog.thumnail).getDownloadURL();
    } else {
      blog.thumnailUrl = '';
    }

    dispatch(slice.actions.updateBlogCacheById({ blog: blog }));
  } else {
    alert('Có lỗi khi lấy thông tin của bài viết ' + id.toString());
    history.push('/management/orders');
  }
};


export const updateBlogCacheById = (blog) => async (dispatch, getState) => {
  dispatch(
    slice.actions.updateBlogCacheById({
      blog: blog
    })
  );
};

export const fetchTotalBlogGraph = (employeeId, accessLevel, status, searchField, setLoading, employeeIdLst = null, customerIdLst = null, timeRange = { startTime: null, endTime: null }) => async (dispatch, getState) => {
  setLoading(true);
  const token = getState().user.user.token;
  const domain = getState().company.company.domain;

  let data = await fetchTotalBlogGraphQL(employeeId, accessLevel, token, domain);

  let totalBlogByCurrentRole = -1;
  if (status === null) {
    totalBlogByCurrentRole = data.reduce((prev, cur) => prev + cur.cnt, 0);
  } else {
    const totalBlogObjAr = data.filter(ele => ele.status === status);
    if (totalBlogObjAr.length > 0) totalBlogByCurrentRole = totalBlogObjAr[0].cnt;
  }

  dispatch(slice.actions.updateTotalBlog({ totalBlog: totalBlogByCurrentRole, role: accessLevel }));
  setLoading(false);
  return data;
};

export const fetchBlogGraph = (employeeId, accessLevel, sort, status, searchField, limit, skip, listField, setLoading, fromSort = false, employeeIdLst = null, customerIdLst = null, timeRange = { startTime: null, endTime: null }) => async (dispatch, getState) => {
  setLoading(true);
  let [field, order] = sort.split('|');

  const role = getState().user.currentRole;
  const token = getState().user.user.token;
  const domain = getState().company.company.domain;

  const data = await fetchBlogGraphQL(employeeId, accessLevel, sort, status, searchField, limit, skip, token, domain);

  if (fromSort) {
    const arr = data.slice().sort(getComparator(order, field, listField));
    await dispatch(slice.actions.updateLocalBlog({ localBlog: arr, role: role }));
  } else {
    let arr = getState().blog.localBlog[role] ?? []
    arr = arr.slice();
    data.forEach((ele) => {
      let have = arr.find((x) => x.id === ele.id);
      if (!have) {
        arr.push(ele);
      }
    });
    arr.sort(getComparator(order, field, listField));
    await dispatch(slice.actions.updateLocalBlog({ localBlog: arr, role: role }));
  }

  setLoading(false);
};

export const updateBlogGraph = (employeeId, accessLevel, sort, status, searchField, limit, page, listField, employeeIdLst = null, customerIdLst = null, timeRange = { startTime: null, endTime: null }) => async (dispatch, getState) => {
  const [field, order] = sort.split('|');
  const skip = page * limit;

  const role = getState().user.currentRole;
  const token = getState().user.user.token;
  const domain = getState().company.company.domain;
  const data = await fetchBlogGraphQL(employeeId, accessLevel, sort, status, searchField, limit, skip, token, domain);

  let arr = getState().blog.localBlog[role] ?? []
  arr = arr.slice();

  data.forEach((ele) => {
    let idx = arr.findIndex((x) => x.id === ele.id);
    if (idx > -1) {
      arr[idx] = ele;
    } else {
      arr.push(ele);
    }
  });

  arr.sort(getComparator(order, field, listField));
  dispatch(slice.actions.updateLocalBlog({ localBlog: arr, role: role }));
};


export default slice;
