import { createContext, useEffect, useReducer } from 'react';
import SplashScreen from 'src/components/SplashScreen';
import firebase, { analytics } from 'src/lib/firebase';
import { setRoles, setToken, setUser } from '../store/slices/user';
import { setCompany, fetchAllCompanyImages } from '../store/slices/company';
import {
  setCntUnread,
  setNotificationsInit
} from '../store/slices/notification';
import { useDispatch } from '../store';
import { companyRef } from '../rolesbased-rules/rolesBasedQuery';
import {
  setCustomersDropdownCache,
  setEmployeesDropdownCache,
  setProductsDropdownCache
} from '../store/slices/cache';
import { getDefaultText } from '../store/slices/default-text';
import { getAllWebpages } from 'src/store/slices/blog';
import { fetchRoles } from '../store/slices/role';

const initialAuthState = {
  isAuthenticated: false,
  isInitialised: false,
  user: null
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'AUTH_STATE_CHANGED': {
      const { isAuthenticated, user } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        user
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext({
  ...initialAuthState,
  method: 'FirebaseAuth',
  createUserWithEmailAndPassword: () => Promise.resolve(),
  signInWithEmailAndPassword: () => Promise.resolve(),
  signInWithGoogle: () => Promise.resolve(),
  logout: () => Promise.resolve()
});

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);
  const redux_dispatch = useDispatch();

  analytics.logEvent('app_start');

  const signInWithEmailAndPassword = (email, password) => {
    return firebase.auth().signInWithEmailAndPassword(email, password);
  };

  const signInWithGoogle = () => {
    const provider = new firebase.auth.GoogleAuthProvider();

    return firebase.auth().signInWithPopup(provider);
  };

  const createUserWithEmailAndPassword = async (email, password) => {
    return firebase.auth().createUserWithEmailAndPassword(email, password);
  };

  const logout = () => {
    return firebase.auth().signOut();
  };

  useEffect(() => {
    return firebase.auth().onAuthStateChanged(async user => {
      if (user) {
        // Here you should extract the complete user profile to make it available in your entire app.
        // The auth state only provides basic information.
        const doc = await companyRef
          .collection('employees')
          .doc(user.uid)
          .get();
        if (!doc.exists) {
          console.log('Permission error');
          await logout();
        } else {
          const company = await companyRef.get();
          const imageList = await companyRef
            .collection('images')
            .get()
            .then(snapShot => {
              const images = [];
              snapShot.forEach(doc => {
                images.push({
                  ...doc.data(),
                  dateCreated: doc
                    .data()
                    .dateCreated.toDate()
                    .getTime()
                });
              });
              return images.sort((a, b) => a.dateCreated - b.dateCreated);
            });

          const user_data = {
            id: user.uid,
            avatar: user.photoURL,
            email: user.email,
            name: user.displayName || user.email,
            token: await user.getIdToken(true),
            tier: 'Premium'
          };
          analytics.setUserId(user.uid);
          dispatch({
            type: 'AUTH_STATE_CHANGED',
            payload: {
              isAuthenticated: true,
              user: user_data
            }
          });
          const { roles } = doc.data();
          console.log('<<<<<<<<<<< USER ROLES');
          console.log(roles);
          redux_dispatch(setUser(user_data, roles));
          redux_dispatch(setToken(user));
          redux_dispatch(setRoles(roles));
          redux_dispatch(setCompany({ ...company.data(), images: imageList }));
          // redux_dispatch(fetchAllCompanyImages());
          // redux_dispatch(fetchRoles());
          // redux_dispatch(setCompany(company.data()));
          redux_dispatch(setNotificationsInit(user_data.id, 1));
          redux_dispatch(setCntUnread(user_data.id));
          redux_dispatch(setCustomersDropdownCache());
          redux_dispatch(setEmployeesDropdownCache());
          redux_dispatch(setProductsDropdownCache());
          redux_dispatch(getDefaultText());
          redux_dispatch(getAllWebpages());
        }
      } else {
        dispatch({
          type: 'AUTH_STATE_CHANGED',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    });
  }, [dispatch, redux_dispatch]);

  if (!state.isInitialised) {
    return <SplashScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'FirebaseAuth',
        createUserWithEmailAndPassword,
        signInWithEmailAndPassword,
        signInWithGoogle,
        logout
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
