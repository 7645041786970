import { createSlice } from '@reduxjs/toolkit';
import {
  transformDate,
  transformToFirebaseTimestamp
} from 'src/utils/formater';
import queries, {
  categoryRef,
  db, fromDate,
  lotsRef,
  productLocationsRef,
  productRef,
  serverTime,
  storageRef
} from 'src/rolesbased-rules/rolesBasedQuery';
import _ from 'lodash';
import store from '../index'
import { apolloClient } from '../../contexts/graphqlContext'
import { gql } from '@apollo/client'
import { slice as categorySlice } from './categories'
import { fetchAllCategories, fetchAllServices } from '../../rolesbased-rules/GQL/queries';

const initialState = {
  products: {},
  totalProduct: {
    CEO: -1,
    ADMIN: -1,
    WAREHOUSE_MANAGER: -1,
    SALES_REP: -1,
    SALES_MANAGER: -1,
    DOCTOR_MANAGER: -1,
    DOCTOR: -1
  },
  localProductQL: {
    CEO: [],
    ADMIN: [],
    WAREHOUSE_MANAGER: [],
    SALES_REP: [],
    SALES_MANAGER: [],
    DOCTOR_MANAGER: [],
    DOCTOR: []
  },
  lots: {},
  localPro: [],
  lastDoc: []
};

const slice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    fetchProduct(state, action) {
      state.products = {
        ...state.products,
        [action.payload.id]: action.payload
      };
    },
    fetchProducts(state, action) {
      state.products = {
        ...state.products,
        ..._.mapKeys(action.payload, 'id')
      };
    },
    fetchServices(state, action) {
      state.services = {
        ...state.services,
        ..._.mapKeys(action.payload, 'id')
      };
    },
    createProduct(state, action) {
      state.products = {
        ...state.products,
        [action.payload.id]: action.payload
      };
    },
    editProduct(state, action) {
      state.products = {
        ...state.products,
        [action.payload.id]: action.payload
      };
    },
    deleteProduct(state, action) {
      state.products = _.omit(state.products, action.payload);
    },
    deleteLocalProduct(state, action) {
      const { id, role } = action.payload;
      state.localProductQL[role] = state.localProductQL[role]
        .filter(product => product.id !== id)
    },
    fetchProductLots(state, action) {
      state.lots[action.payload.product_id] = {
        ...state.lots[action.payload.product_id],
        ..._.mapKeys(action.payload.lots, 'id')
      };
    },
    createProductLot(state, action) {
      state.lots[action.payload.product_id] = {
        ...state.lots[action.payload.product_id],
        ..._.mapKeys(action.payload.lots, 'id')
      };
    },
    deleteProductLot(state, action) {
      state.lots[action.payload.product_id] = _.omit(
        state.lots[action.payload.product_id],
        action.payload.lot_id
      );
    },
    fetchProductLocations(state, action) {
      state.lots = { ...state.lots, ..._.mapKeys(action.payload, 'id') };
    },
    refreshLastDocument(state, action) {
      //Xoá hết lastDoc
      state.lastDoc = [];
    },
    setLastDocument(state, action) {
      //Thêm vào cuối lastDoc
      const { doc } = action.payload;
      state.lastDoc = [...state.lastDoc, doc];
    },
    fetchFirstLimitPro(state, action) {
      //Xoá Ele local và lấy Ele lại
      const { localPro } = action.payload;
      state.localPro = localPro;
    },
    fetchProFrom(state, action) {
      //Lấy RN về
      const { localPro } = action.payload;
      state.localPro = localPro;
    },
    updateProFrom(state, action) {
      //Update RN
      const { localPro } = action.payload;
      state.localPro = localPro;
    },

    updateLocalProduct(state, action) {
      const { localProductQL, role } = action.payload;
      state.localProductQL = {
        ...state.localProductQL,
        [role]: localProductQL
      };
    },
    updateTotalProduct(state, action) {
      const { totalProduct, role } = action.payload;
      state.totalProduct = {
        ...state.totalProduct,
        [role]: totalProduct
      };
    }
  }
});

export const reducer = slice.reducer;

const descendingComparator = (a, b, orderBy, listField) => {
  let field = orderBy.split('.')[0]; //1 biến chính là orderby
  let innerField = orderBy.split('.')[1];

  if (listField.includes(field)) {
    a = a[field];
    b = b[field];
    field = innerField;
  }

  //b O orderBy: doctor.name
  if (b[field] < a[field]) {
    return -1;
  }

  if (b[field] > a[field]) {
    return 1;
  }

  return 0;
};

const getComparator = (order, orderBy, listField) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy, listField)
    : (a, b) => -descendingComparator(a, b, orderBy, listField);
};

export const fetchProduct = (id) => async (dispatch, getState) => {
  const { currentAccessLevel } = getState().user;
  if (currentAccessLevel) {
    const product = await productRef.doc(id).get();
    const response = await productRef.doc(id).collection('images').get();

    const data = product.data();
    data.images = [];

    response.docs.map(snapshot => {
      const imgDoc = snapshot.data();
      data.images.push({
        id: imgDoc.id,
        name: imgDoc.name,
        storageUrl: imgDoc.storageUrl,
        isNew: false
      })
    })

    dispatch(slice.actions.fetchProduct(transformDate(data)));
  }
};

export const fetchServices = () => async (dispatch, getState) => {
  const user = getState().user.user
  const company = getState().company.company

  const services = await fetchAllServices(user.token, company.domain)
  dispatch(
    slice.actions.fetchServices(services)
  )
}

export const fetchProductLots = (product_id) => async (dispatch, getState) => {
  const { currentAccessLevel } = getState().user;
  if (currentAccessLevel) {
    const snap = await lotsRef(product_id).get();
    const lots = [];
    snap.forEach((doc) => {
      lots.push(transformDate(doc.data()));
    });
    dispatch(
      slice.actions.fetchProductLots({ product_id, lots: transformDate(lots) })
    );
  }
};

export const refreshLastDocument = (doc) => async (dispatch, getState) => {
  await dispatch(slice.actions.refreshLastDocument());
  return true;
};

export const setLastDocument = (doc) => async (dispatch, getState) => {
  dispatch(slice.actions.setLastDocument({ doc: doc }));
};

export const fetchFirstLimitPro =
  (limit, order, field, listField) => async (dispatch, getState) => {
    const eleList = [];
    const query = productRef.orderBy(field, order).limit(limit);
    const docs = await query.get();

    //Set last doc
    await dispatch(
      slice.actions.setLastDocument({ doc: docs.docs[docs.docs.length - 1] })
    );

    docs.forEach((doc) => {
      eleList.push(transformDate(doc.data()));
    });

    eleList.sort(getComparator(order, field, listField));
    dispatch(slice.actions.fetchFirstLimitPro({ localPro: eleList }));
  };

export const fetchProFrom = (isFirstQuery, lastDoc, limit, order, field, listField) => async (dispatch, getState) => {
  let query = null;
  let CusList = [];

  if (isFirstQuery) {
    query = productRef.orderBy(field, order).limit(limit);
  } else {
    query = productRef.orderBy(field, order).startAfter(lastDoc).limit(limit);
  }

  let docs = await query.get();
  if (docs.docs.length === 0) {
    return;
  }
  //Set last doc
  await dispatch(
    slice.actions.setLastDocument({ doc: docs.docs[docs.docs.length - 1] })
  );
  let arr = getState().products.localPro.slice();

  docs.forEach((doc) => {
    let data = doc.data();
    let have = arr.find((x) => x.id === data.id);
    if (!have) {
      //Khác mới push vào
      CusList.push(transformDate(data));
    }
  });

  let newList = [...arr, ...CusList];
  newList.sort(getComparator(order, field, listField));

  dispatch(slice.actions.fetchProFrom({ localPro: newList }));
};

export const updateProFrom =
  (lastDocList, limit, order, field, page, listField) =>
    async (dispatch, getState) => {
      let query = null;
      let CusList = [];

      if (!page) {
        query = productRef.orderBy(field, order).limit(limit);
      } else {
        query = productRef
          .orderBy(field, order)
          .startAfter(lastDocList[page - 1])
          .limit(limit);
      }

      let docs = await query.get();

      let arr = getState().products.localPro.slice();

      docs.forEach((doc) => {
        CusList.push(transformDate(doc.data()));
      });

      CusList.forEach((ele) => {
        let idx = arr.findIndex((x) => x.id === ele.id);
        if (idx > -1) {
          arr[idx] = ele;
        } else {
          arr.push(ele);
        }
      });

      arr.sort(getComparator(order, field, listField));
      dispatch(slice.actions.updateProFrom({ localPro: arr }));
    };

export const fetchProductLocations =
  (product_id) => async (dispatch, getState) => {
    const { currentAccessLevel } = getState().user;
    if (currentAccessLevel) {
      const snap = await productLocationsRef(product_id).get();
      const lots = [];
      snap.forEach((doc) => {
        lots.push(transformDate(doc.data()));
      });
      dispatch(slice.actions.fetchProductLocations(transformDate(lots)));
    }
  };

export const fetchProducts = () => async (dispatch, getState) => {
  const { currentAccessLevel, uid } = getState().user;
  const myEmployees = [uid];
  const { customersRef } = queries[currentAccessLevel];

  const customers = await customersRef(myEmployees).get();
  dispatch(
    slice.actions.fetchProducts({
      customers: customers.data()
    })
  );
};

export const createProductLot =
  (product_id, data, callback) => async (dispatch, getState) => {
    const { currentAccessLevel } = getState().user;
    if (currentAccessLevel) {
      const newLotRef = lotsRef(product_id).doc();
      const id = newLotRef.id;
      const timestamp = serverTime();

      const newLot = {
        ...transformToFirebaseTimestamp(data),
        id,
        dateCreated: timestamp
      };

      await newLotRef.set(newLot);
      callback({ ...data, id });
      dispatch(
        slice.actions.createProductLot({
          product_id,
          lots: [transformDate(newLot)]
        })
      );
    }
  };

export const editProductLot =
  (product_id, data, id, callback) => async (dispatch, getState) => {
    const { currentAccessLevel } = getState().user;
    if (currentAccessLevel) {
      const lotRef = lotsRef(product_id).doc(id);
      const timestamp = serverTime();

      const lot = {
        ...transformToFirebaseTimestamp(data),
        id,
        dateModified: timestamp
      };

      await lotRef.update(lot);
      callback({ ...data, id });
      dispatch(
        slice.actions.createProductLot({
          product_id,
          lots: [transformDate(lot)]
        })
      );
    }
  };

export const deleteProductLot =
  (product_id, id, callback) => async (dispatch, getState) => {
    const { currentAccessLevel } = getState().user;
    if (currentAccessLevel) {
      const lotRef = lotsRef(product_id).doc(id);

      await lotRef.delete();
      callback();
      dispatch(slice.actions.deleteProductLot({ product_id, lot_id: id }));
    }
  };

export const createProduct = (data, callback) => async (dispatch, getState) => {
  const prodRef = productRef.doc();
  const id = prodRef.id;
  const timestamp = serverTime();
  const transformData = transformToFirebaseTimestamp(data);
  const product_data = {
    ...transformData,
    dateCreated: timestamp,
    dateModified: timestamp,
    stock: 0,
    id
  };
  const images = product_data.images;
  const promisesImgToDel = [];
  const promisesImgToAdd = [];

  const imageToSaveLst = [];
  for (const image of images) {
    const storageUrl = `public/company/${window.location.hostname}/products/${id}/${image.name}`
    const storageImgRef = storageRef(
      storageUrl
    );
    if (!image.isNew && image.isDeleted) {
      promisesImgToDel.push(storageImgRef.delete());
    }
    if (!image.isNew && !image.isDeleted)
      imageToSaveLst.push({
        image: image.image,
        storageUrl: storageUrl,
        name: image.name
      });
    if (image.isNew && !image.isDeleted) {
      promisesImgToAdd.push(storageImgRef.put(image.file));
    }
  }
  await Promise.all(promisesImgToDel);
  const responses = await Promise.all(promisesImgToAdd);

  const imageCollectionPromises = [];
  const currentDate = new Date();
  // let i = 0;
  responses.forEach((response) => {
    const imgDocRef = productRef.doc(id).collection('images').doc();
    // i = i + 5;
    currentDate.setSeconds(currentDate.getSeconds() + 5);
    console.log("<<<<<<<<<< Image Date")
    console.log(currentDate)
    imageCollectionPromises.push(
      imgDocRef.set({
        id: imgDocRef.id,
        dateCreated: fromDate(currentDate),
        productId: id,
        storageUrl: response.metadata.fullPath,
        name: response.metadata.fullPath.substr(
          response.metadata.fullPath.indexOf(`${id}/`) + 1 + id.length
        )
      })
    )
    imageToSaveLst.push({
      id: imgDocRef.id,
      storageUrl: response.metadata.fullPath,
      name: response.metadata.fullPath.substr(
        response.metadata.fullPath.indexOf(`${id}/`) + 1 + id.length
      )
    });
  });

  await Promise.all(imageCollectionPromises);

  transformData.images = imageToSaveLst;
  delete product_data.images;

  await addCategoryProducts(dispatch, id, product_data.categories, []);
  delete product_data.categories;

  await prodRef.set(product_data);
  dispatch(slice.actions.createProduct(transformDate(transformData)));
  callback(id);
};

export const addCategoryProducts = async (dispatch, product_id, cate_ids, remove_cate_ids) => {
  const batch = db().batch()

  if (cate_ids) {
    cate_ids.map(id => {
      batch.update(categoryRef.doc(id), { products: db.FieldValue.arrayUnion(product_id) })
      dispatch(categorySlice.actions.addCategoryProducts({ id, product_id }))
    })
  }

  if (remove_cate_ids) {
    remove_cate_ids.map(id => {
      batch.update(categoryRef.doc(id), { products: db.FieldValue.arrayRemove(product_id) })
      dispatch(categorySlice.actions.removeCategoryProducts({ id, product_ids: [product_id] }))
    })
  }
  await batch.commit()
}

export const editProduct = (data, id, callback) => async (dispatch, getState) => {
  const timestamp = serverTime();
  const transformData = transformToFirebaseTimestamp(data);
  const product_data = {
    ...transformData,
    dateModified: timestamp
  };
  const images = product_data.images;
  const promisesImgToDel = [];
  const promisesImgToAdd = [];
  const imageCollectionPromises = [];

  const imageToSaveLst = [];
  for (const image of images) {
    const storageUrl = `public/company/${window.location.hostname}/products/${id}/${image.name}`
    const storageImgRef = storageRef(
      storageUrl
    );
    if (!image.isNew && image.isDeleted) {
      promisesImgToDel.push(storageImgRef.delete());
      const imgDocRef = productRef.doc(id).collection('images').doc(image.id);
      imageCollectionPromises.push(imgDocRef.delete());
    }
    if (!image.isNew && !image.isDeleted) {
      imageToSaveLst.push({
        id: image.id,
        image: image.image,
        storageUrl: storageUrl,
        name: image.name
      });
    }
    if (image.isNew && !image.isDeleted) {
      promisesImgToAdd.push(storageImgRef.put(image.file));
    }
  }
  await Promise.all(promisesImgToDel);
  const responses = await Promise.all(promisesImgToAdd);
  const currentDate = new Date();
  // let i = 0;
  responses.forEach((response) => {
    const imgDocRef = productRef.doc(id).collection('images').doc();
    currentDate.setSeconds(currentDate.getSeconds() + 5);
    console.log("<<<<<<<<<< Image Date")
    console.log(currentDate)
    imageCollectionPromises.push(
      imgDocRef.set({
        id: imgDocRef.id,
        dateCreated: fromDate(currentDate),
        productId: id,
        storageUrl: response.metadata.fullPath,
        name: response.metadata.fullPath.substr(
          response.metadata.fullPath.indexOf(`${id}/`) + 1 + id.length
        )
      })
    );

    imageToSaveLst.push({
      id: imgDocRef.id,
      storageUrl: response.metadata.fullPath,
      name: response.metadata.fullPath.substr(
        response.metadata.fullPath.indexOf(`${id}/`) + 1 + id.length
      ),
      isNew: false
    });
  });

  delete product_data.images;
  transformData.images = imageToSaveLst;
  transformData.id = id;

  console.log("<<<<<<<<<<< PRODUCT DATA")
  console.log(product_data)

  await addCategoryProducts(dispatch, id, product_data.categories, product_data.removed_categories);
  delete product_data.categories;
  delete product_data.removed_categories;

  await productRef.doc(id).update(product_data);

  dispatch(slice.actions.editProduct(transformDate(transformData)));
  callback(id);
};

export const deleteProduct = (id) => async (dispatch, getState) => {
  const { currentAccessLevel } = getState().user
  await productRef.doc(id).delete();

  dispatch(slice.actions.deleteProduct(id));
  dispatch(slice.actions.deleteLocalProduct({ id, role: currentAccessLevel }));
};


////// FOR GRAPH DB //////////
const fetchTotalProductFromDB = async (employeeId, accessLevel, searchField, warehouse) => {
  const user = store.getState().user.user;
  const company = store.getState().company.company;
  const client = apolloClient({
    authorization: `Bearer ${user.token}`,
    database: company.domain
  });
  const response = await client.query({
    fetchPolicy: 'network-only',
    query: gql`
        query fetchTotalProductByRole($employeeId: String, 
          $accessLevel: String, $searchField: String,
          $warehouse: String
        ) {
            fetchTotalProductByRole(
              employeeId: $employeeId, accessLevel: $accessLevel,  
              searchField: $searchField, warehouse: $warehouse
            ){
                productType,
                cnt
            }
        }
    `,
    variables: {
      employeeId: employeeId,
      accessLevel,
      searchField: searchField,
      warehouse
    }
  });
  return response.data.fetchTotalProductByRole;
}

export const fetchTotalProductGraph = (
  employeeId, accessLevel, productType, searchField, setLoading
) => async (dispatch, getState) => {
  setLoading(true);
  let data = await fetchTotalProductFromDB(employeeId, accessLevel, searchField, null)

  let totalProductByCurrentRole = -1
  if (productType === null) {
    totalProductByCurrentRole = data.reduce((prev, cur) => prev + cur.cnt, 0)
  } else {
    const totalProductObjAr = data.filter(ele => ele.productType === productType)
    if (totalProductObjAr.length > 0) totalProductByCurrentRole = totalProductObjAr[0].cnt
  }

  dispatch(slice.actions.updateTotalProduct({ totalProduct: totalProductByCurrentRole, role: accessLevel }))
  setLoading(false)
  return data
};

const fetchProductFromDB = async (
  employeeId, accessLevel, sort, productType, searchField, limit, skip, getState, warehouse
) => {
  let [field, order] = sort.split('|');
  const user = getState().user.user;
  const company = getState().company.company;
  const client = apolloClient({
    authorization: `Bearer ${user.token}`,
    database: company.domain
  });
  return await client.query({
    fetchPolicy: 'network-only',
    query: gql`
        query fetchProduct(
            $employeeId: String, $accessLevel: String, $propName: String,
            $limit: Int!, $searchField: String, $skip: Int!, $productType: String, 
            $status: String, $warehouse: String
        ){
            ${order === 'desc' ? 'fetchProductByRoleDesc' : 'fetchProductByRoleAsc'}
            (employeeId: $employeeId, accessLevel: $accessLevel, propName: $propName,
            status: $status, searchField: $searchField, limit: $limit, 
            skip: $skip, warehouse: $warehouse, productType: $productType
            ){
            id
            name
            status
            productType
            dateCreated
            productCode
            price
            stock
        }
        }
    `,
    variables: {
      employeeId: employeeId,
      accessLevel,
      skip: skip,
      limit: limit,
      propName: field,
      productType,
      status: null,
      searchField: searchField,
      warehouse
    }
  });
};

export const fetchProductGraph = (
  employeeId, accessLevel, sort, productType, searchField,
  limit, skip, listField, setLoading, fromSort = false
) => async (dispatch, getState) => {
  setLoading(true);
  let [field, order] = sort.split('|');

  const role = getState().user.currentAccessLevel;

  const response = await fetchProductFromDB(
    employeeId, accessLevel, sort, productType, searchField, limit, skip, getState, null);

  let data = order === 'desc' ? response.data.fetchProductByRoleDesc : response.data.fetchProductByRoleAsc;

  if (fromSort) {
    const arr = data.slice().sort(getComparator(order, field, listField));
    await dispatch(slice.actions.updateLocalProduct({ localProductQL: arr, role: role }));
  } else {
    let arr = getState().products.localProductQL[role] ?? []
    arr = arr.slice();
    data.forEach((ele) => {
      let have = arr.find((x) => x.id === ele.id);
      if (!have) {
        arr.push(ele);
      }
    });
    arr.sort(getComparator(order, field, listField));
    await dispatch(slice.actions.updateLocalProduct({ localProductQL: arr, role: role }));
  }

  setLoading(false);
};

export const updateProductGraph = (
  employeeId, accessLevel, sort, productType, searchField, limit, page, listField
) => async (dispatch, getState) => {
  const [field, order] = sort.split('|');
  const skip = page * limit;

  const role = getState().user.currentRole;
  const response = await fetchProductFromDB(
    employeeId, accessLevel, sort, productType, searchField, limit, skip, getState, null
  );

  let data = order === 'desc' ? response.data.fetchProductByRoleDesc : response.data.fetchProductByRoleAsc;
  let arr = getState().products.localProductQL[role] ?? []
  arr = arr.slice();

  data.forEach((ele) => {
    let idx = arr.findIndex((x) => x.id === ele.id);
    if (idx > -1) {
      arr[idx] = ele;
    } else {
      arr.push(ele);
    }
  });

  arr.sort(getComparator(order, field, listField));
  dispatch(slice.actions.updateLocalProduct({ localProductQL: arr, role: role }));
};


export default slice;
