import { combineReducers } from '@reduxjs/toolkit';
import { reducer as branchReducer } from 'src/store/slices/branches';
import { reducer as userReducer } from 'src/store/slices/user';
import { reducer as employeeReducer } from 'src/store/slices/employees';
import { reducer as teamReducer } from 'src/store/slices/teams';
import { reducer as promotionReducer } from 'src/store/slices/promotion';
import { reducer as roleReducer } from 'src/store/slices/role';
import { reducer as adjustmentReducer } from 'src/store/slices/adjustments';
import { reducer as paymentMethodReducer } from 'src/store/slices/payment-method';
import { reducer as defaultTextReducer } from 'src/store/slices/default-text';
import { reducer as customerReducer } from 'src/store/slices/customers';
import { reducer as productReducer } from 'src/store/slices/products';
import { reducer as categoryReducer } from 'src/store/slices/categories';
import { reducer as warehouseReducer } from 'src/store/slices/warehouse';
import { reducer as supplierReducer } from 'src/store/slices/supplier';
import { reducer as companyReducer } from 'src/store/slices/company';
import { reducer as cacheReducer } from 'src/store/slices/cache';
import { reducer as analyticsReducer } from 'src/store/slices/analytics';
import { reducer as formReducer } from 'redux-form';
import { reducer as notificationReducer } from 'src/store/slices/notification';
import { reducer as saleorderReducer } from 'src/store/slices/saleorder';
import { reducer as quoteReducer } from 'src/store/slices/quote';
import { reducer as receiveNoteReducer } from 'src/store/slices/receive-note';
import { reducer as blogReducer } from 'src/store/slices/blog';
import { reducer as calendarReducer } from 'src/store/slices/calendar';

const rootReducer = combineReducers({
  branches: branchReducer,
  user: userReducer,
  employees: employeeReducer,
  teams: teamReducer,
  promotions: promotionReducer,
  roles: roleReducer,
  adjustments: adjustmentReducer,
  paymentMethods: paymentMethodReducer,
  defaultText: defaultTextReducer,
  customers: customerReducer,
  products: productReducer,
  categories: categoryReducer,
  warehouses: warehouseReducer,
  suppliers: supplierReducer,
  company: companyReducer,
  cache: cacheReducer,
  analytics: analyticsReducer,
  form: formReducer,
  notifications: notificationReducer,
  saleorder: saleorderReducer,
  quote: quoteReducer,
  receiveNote: receiveNoteReducer,
  blog: blogReducer,
  calendar: calendarReducer,
});

export default rootReducer;
