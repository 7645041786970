import { createSlice } from '@reduxjs/toolkit';
import {
  quoteCacheRef,
  quoteRef
} from '../../rolesbased-rules/rolesBasedQuery';
import history from '../../history';
import { transformDate } from '../../utils/formater';
import { apolloClient } from '../../contexts/graphqlContext';
import { gql } from '@apollo/client';
import store from '../index'

const initialState = {
  totalQuote: {
    CEO: -1,
    ADMIN: -1,
    WAREHOUSE_MANAGER: -1,
    SALES_REP: -1,
    SALES_MANAGER: -1,
    DOCTOR_MANAGER: -1,
    DOCTOR: -1
  },
  quotes: {},
  localQuote: [],
  lastDoc: [],
  localQuoteQL: {
  },
};

const slice = createSlice({
  name: 'quotes',
  initialState,
  reducers: {
    updateQuoteCacheById(state, action) {
      const { soCache } = action.payload;
      state.quotes = {
        ...state.quotes,
        [soCache.id]: soCache.detail
      };
    },
    setSaleOrderCacheById(state, action) {
      const { soCache } = action.payload;
      state.quotes = {
        ...state.quotes,
        [soCache.id]: soCache.detail
      };
    },
    refreshLastDocument(state, action) {
      //Xoá hết lastDoc
      state.lastDoc = [];
    },
    fetchFirstLimitQuote(state, action) {
      //Xoá Ele local và lấy Ele lại
      const { localQuote } = action.payload;
      state.localQuote = localQuote;
    },
    setLastDocument(state, action) {
      //Thêm vào cuối lastDoc
      const { doc } = action.payload;
      state.lastDoc = [...state.lastDoc, doc];
    },
    fetchQuoteFrom(state, action) {
      //Lấy RN về
      const { localQuote } = action.payload;
      state.localQuote = localQuote;
    },
    updateQuoteFrom(state, action) {
      //Update RN
      const { localQuote } = action.payload;
      state.localQuote = localQuote;
    },
    updateLocalQuote(state, action) {
      const { localQuoteQL, role } = action.payload;
      state.localQuoteQL = {
        ...state.localQuoteQL,
        [role]: localQuoteQL
      };
    },
    updateTotalQuote(state, action) {
      const { totalQuote, role } = action.payload;
      state.totalQuote = {
        ...state.totalQuote,
        [role]: totalQuote
      };
    }
  }
});

export const reducer = slice.reducer;

const descendingComparator = (a, b, orderBy, listField) => {
  let field = orderBy.split('.')[0]; //1 biến chính là orderby
  let innerField = orderBy.split('.')[1];

  if (listField.includes(field)) {
    a = a[field];
    b = b[field];
    field = innerField;
  }

  //b O orderBy: doctor.name
  if (b[field] < a[field]) {
    return -1;
  }

  if (b[field] > a[field]) {
    return 1;
  }

  return 0;
};

const getComparator = (order, orderBy, listField) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy, listField)
    : (a, b) => -descendingComparator(a, b, orderBy, listField);
};

export const fetchFirstLimitQuote =
  (limit, order, field, listField) => async (dispatch, getState) => {
    const eleList = [];
    const query = quoteRef.orderBy(field, order).limit(limit);
    const docs = await query.get();

    //Set last doc
    await dispatch(
      slice.actions.setLastDocument({ doc: docs.docs[docs.docs.length - 1] })
    );

    docs.forEach((doc) => {
      eleList.push(transformDate(doc.data()));
    });

    eleList.sort(getComparator(order, field, listField));
    dispatch(slice.actions.fetchFirstLimitQuote({ localQuote: eleList }));
  };

export const setLastDocument = (doc) => async (dispatch, getState) => {
  dispatch(slice.actions.setLastDocument({ doc: doc }));
};

export const fetchQuoteFrom = (isFirstQuery, lastDoc, limit, order, field, listField) => async (dispatch, getState) => {
  let query = null;
  let RNList = [];

  if (isFirstQuery) {
    query = quoteRef.orderBy(field, order).limit(limit);
  } else {
    query = quoteRef.orderBy(field, order).startAfter(lastDoc).limit(limit);
  }

  let docs = await query.get();
  if (docs.docs.length === 0) {
    return;
  }
  //Set last doc
  await dispatch(
    slice.actions.setLastDocument({ doc: docs.docs[docs.docs.length - 1] })
  );
  let arr = getState().quote.localQuote.slice();

  docs.forEach((doc) => {
    let data = doc.data();
    let have = arr.find((x) => x.id === data.id);
    if (!have) {
      //Khác mới push vào
      RNList.push(transformDate(data));
    }
  });

  let newList = [...arr, ...RNList];
  newList.sort(getComparator(order, field, listField));

  dispatch(slice.actions.fetchQuoteFrom({ localQuote: newList }));
};

export const refreshLastDocument = (doc) => async (dispatch, getState) => {
  await dispatch(slice.actions.refreshLastDocument());
  return true;
};

export const updateQuoteFrom =
  (lastDocList, limit, order, field, page, listField) =>
    async (dispatch, getState) => {
      let query = null;
      let RNList = [];

      if (!page) {
        query = quoteRef.orderBy(field, order).limit(limit);
      } else {
        query = quoteRef
          .orderBy(field, order)
          .startAfter(lastDocList[page - 1])
          .limit(limit);
      }

      let docs = await query.get();

      let arr = getState().quote.localQuote.slice();

      docs.forEach((doc) => {
        RNList.push(transformDate(doc.data()));
      });

      RNList.forEach((ele) => {
        let idx = arr.findIndex((x) => x.id === ele.id);
        if (idx > -1) {
          arr[idx] = ele;
        } else {
          arr.push(ele);
        }
      });

      arr.sort(getComparator(order, field, listField));
      dispatch(slice.actions.updateQuoteFrom({ localQuote: arr }));
    };

export const updateQuoteCacheById =
  (sodetail) => async (dispatch, getState) => {
    dispatch(
      slice.actions.updateQuoteCacheById({
        soCache: {
          id: sodetail.id,
          detail: sodetail
        }
      })
    );
  };

export const setQuoteCacheById = (id) => async (dispatch, getState) => {
  let doc = await quoteCacheRef(id).get();
  if (doc.data()) {
    const data = doc.data();
    data.dateCreated =
      data.dateCreated.seconds + data.dateCreated.nanoseconds / 1000000000;
    data.dateModified =
      data.dateModified.seconds + data.dateModified.nanoseconds / 1000000000;
    dispatch(
      slice.actions.updateQuoteCacheById({
        soCache: {
          id: id,
          detail: data
        }
      })
    );
  } else {
    alert('Có lỗi khi lấy thông tin của đơn hàng ' + id.toString());
    history.push('/management/quotes');
  }
};

////// FOR GRAPH DB //////////
const fetchTotalQuoteFromDB = async (employeeId, accessLevel, searchField, employeeIdLst, customerIdLst, timeRange) => {
  const user = store.getState().user.user;
  const company = store.getState().company.company;
  const client = apolloClient({
    authorization: `Bearer ${user.token}`,
    database: company.domain
  });
  const response = await client.query({
    fetchPolicy: 'network-only',
    query: gql`
        query fetchTotalQuoteByRole($employeeId: String, $accessLevel: String, $searchField: String, $employeeIdLst: [String], $customerIdLst: [String], $startTimeRange: Float, $endTimeRange: Float) {
          fetchTotalQuoteByRole(
            employeeId: $employeeId,
            accessLevel: $accessLevel, 
            searchField: $searchField,
            employeeIdLst: $employeeIdLst, 
            customerIdLst: $customerIdLst, 
            startTimeRange: $startTimeRange, 
            endTimeRange: $endTimeRange
          ){
            quoStatus,
            cnt
          }
        }
      `,
    variables: {
      employeeId: employeeId,
      accessLevel,
      searchField: searchField,
      customerIdLst: customerIdLst,
      employeeIdLst: employeeIdLst,
      startTimeRange: timeRange.startTime,
      endTimeRange: timeRange.endTime
    }
  });
  return response.data.fetchTotalQuoteByRole;
}

export const fetchTotalQuoteGraph = (employeeId, accessLevel, quoteStatus, searchField, setLoading, employeeIdLst = null, customerIdLst = null, timeRange = { startTime: null, endTime: null }) => async (dispatch, getState) => {
  setLoading(true);
  let data = await fetchTotalQuoteFromDB(employeeId, accessLevel, searchField, employeeIdLst, customerIdLst, timeRange)

  let totalQuoteByCurrentRole = -1
  if (quoteStatus === null) {
    totalQuoteByCurrentRole = data.reduce((prev, cur) => prev + cur.cnt, 0)
  } else {
    const totalQuoteObjAr = data.filter(ele => ele.quoStatus === quoteStatus)
    if (totalQuoteObjAr.length > 0) totalQuoteByCurrentRole = totalQuoteObjAr[0].cnt
  }

  dispatch(slice.actions.updateTotalQuote({ totalQuote: totalQuoteByCurrentRole, role: accessLevel }))
  setLoading(false)
  return data
};

const fetchQuoteFromDB = async (employeeId, accessLevel, sort, quoteStatus, searchField, limit, skip, getState, employeeIdLst, customerIdLst, timeRange) => {
  let [field, order] = sort.split('|');
  const user = getState().user.user;
  const company = getState().company.company;
  const client = apolloClient({
    authorization: `Bearer ${user.token}`,
    database: company.domain
  });
  const response = await client.query({
    fetchPolicy: 'network-only',
    query: gql`
      query fetchQuote($employeeId: String, $accessLevel: String, $propName: String, $limit: Int!, $searchField: String, $skip: Int!, $quoteStatus: String, $employeeIdLst: [String], $customerIdLst: [String], $startTimeRange: Float, $endTimeRange: Float){
      ${order === 'desc' ? 'fetchQuotesByRoleDesc' : 'fetchQuotesByRoleAsc'}
        (
          employeeId: $employeeId,
          accessLevel: $accessLevel, 
          propName: $propName, 
          quoteStatus: $quoteStatus, 
          searchField: $searchField, 
          limit: $limit, 
          skip: $skip,
          employeeIdLst: $employeeIdLst, 
          customerIdLst: $customerIdLst,
          startTimeRange: $startTimeRange, 
          endTimeRange: $endTimeRange
        ){
          id
          formNumber
          status
          dateCreated
          createdBy{name}
          customer{id,name}
          employees{id,name}
          total
          totalPromo
        }
      }
      `,
    variables: {
      employeeId: employeeId,
      accessLevel: accessLevel,
      skip: skip,
      limit: limit,
      propName: field,
      quoteStatus: quoteStatus,
      searchField: searchField,
      employeeIdLst: employeeIdLst,
      customerIdLst: customerIdLst,
      startTimeRange: timeRange.startTime,
      endTimeRange: timeRange.endTime
    }
  });
  return response;
};
export const fetchQuoteGraph = (employeeId, accessLevel, sort, quoteStatus, searchField, limit, skip, listField, setLoading, fromSort = false, employeeIdLst = null, customerIdLst = null, timeRange = { startTime: null, endTime: null }) => async (dispatch, getState) => {
  setLoading(true);
  let [field, order] = sort.split('|');

  const accessLevel = getState().user.currentAccessLevel;

  const response = await fetchQuoteFromDB(employeeId, accessLevel, sort, quoteStatus, searchField, limit, skip, getState, employeeIdLst, customerIdLst, timeRange);
  let data = order === 'desc' ? response.data.fetchQuotesByRoleDesc : response.data.fetchQuotesByRoleAsc;

  if (fromSort) {
    const arr = data.slice().sort(getComparator(order, field, listField));
    await dispatch(slice.actions.updateLocalQuote({ localQuoteQL: arr, role: accessLevel }));
  } else {
    let arr = getState().quote.localQuoteQL[accessLevel] ?? [];
    arr = arr.slice();
    data.forEach((ele) => {
      let have = arr.find((x) => x.id === ele.id);
      if (!have) {
        arr.push(ele);
      }
    });
    arr.sort(getComparator(order, field, listField));
    await dispatch(slice.actions.updateLocalQuote({ localQuoteQL: arr, role: accessLevel }));
  }

  setLoading(false);
};

export const updateQuoteGraph = (employeeId, accessLevel, sort, quoteStatus, searchField, limit, page, listField, employeeIdLst = null, customerIdLst = null, timeRange = { startTime: null, endTime: null }) => async (dispatch, getState) => {
  const [field, order] = sort.split('|');
  const skip = page * limit;

  const role = getState().user.currentAccessLevel;
  const response = await fetchQuoteFromDB(employeeId, accessLevel, sort, quoteStatus, searchField, limit, skip, getState, employeeIdLst, customerIdLst, timeRange);

  let data = order === 'desc' ? response.data.fetchQuotesByRoleDesc : response.data.fetchQuotesByRoleAsc;

  let arr = getState().quote.localQuoteQL[role] ?? [];
  arr = arr.slice();

  data.forEach((ele) => {
    let idx = arr.findIndex((x) => x.id === ele.id);
    if (idx > -1) {
      arr[idx] = ele;
    } else {
      arr.push(ele);
    }
  });

  arr.sort(getComparator(order, field, listField));
  dispatch(slice.actions.updateLocalQuote({ localQuoteQL: arr, role: role }));
};

export default slice;
