import { createContext, useState, useEffect } from 'react';

const ResponsiveContext = createContext({
  isMobileScreen: false,
  isDesktopScreen: true
});

export const ResponsiveContextProvider = ({ children }) => {
  const [isMobileScreen, setIsMobileScreen] = useState(false);
  const [isDesktopScreen, setIsDesktopScreen] = useState(true);

  const handleWindowResize = () => {
    setIsMobileScreen(window.matchMedia('(max-width: 600px)').matches); // 'sm' breakpoint in material UI
    setIsDesktopScreen(window.matchMedia('(min-width: 1000px)').matches);
  };

  useEffect(() => {
    setIsMobileScreen(window.matchMedia('(max-width: 600px)').matches); // 'sm' breakpoint in material UI
    setIsDesktopScreen(window.matchMedia('(min-width: 1000px)').matches);

    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);
  return (
    <ResponsiveContext.Provider
      value={{
        isMobileScreen: isMobileScreen,
        isDesktopScreen: isDesktopScreen
      }}
    >
      {children}
    </ResponsiveContext.Provider>
  );
};
export default ResponsiveContext;
