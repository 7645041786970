import {StyledEngineProvider, ThemeProvider} from '@mui/material';
import jssPreset from '@mui/styles/jssPreset';
import StylesProvider from '@mui/styles/StylesProvider';
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {create} from 'jss';
import rtl from 'jss-rtl';
import {SnackbarProvider} from 'notistack';
import {Router} from 'react-router-dom';
import GlobalStyles from 'src/components/GlobalStyles';
import ScrollReset from 'src/components/ScrollReset';
import SettingsNotification from 'src/components/SettingsNotification';
import {AuthProvider} from 'src/contexts/FirebaseAuthContext';
import {ResponsiveContextProvider} from 'src/contexts/ResponsiveContext';
import useSettings from 'src/hooks/useSettings';
import routes, {renderRoutes} from 'src/routes';
import {createAppTheme} from 'src/theme';
import {GraphQLAuthProvider} from './contexts/graphqlContext';
import history from './history';

const jss = create({plugins: [...jssPreset().plugins, rtl()]});
const devEnv = 'development'
const prodEnv = 'production'

const App = () => {
  if (process.env.NODE_ENV === prodEnv) {
    const disableFunc = () => 'console has been disabled in production mode';

    console.log = disableFunc
    console.error = disableFunc
    console.warn = disableFunc

    Object.freeze(console);
  }

  const {settings} = useSettings();

  const theme = createAppTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    theme: settings.theme
  });
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <StylesProvider jss={jss}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <SnackbarProvider dense maxSnack={3}>
              <Router history={history}>
                <AuthProvider>
                  <GraphQLAuthProvider>
                    <ResponsiveContextProvider>
                      <GlobalStyles/>
                      <ScrollReset/>
                      <SettingsNotification/>
                      {renderRoutes(routes)}
                    </ResponsiveContextProvider>
                  </GraphQLAuthProvider>
                </AuthProvider>
              </Router>
            </SnackbarProvider>
          </LocalizationProvider>
        </StylesProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
